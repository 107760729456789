import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { navbar } from "../../config";
import { useUsers } from "../../services/usersService";
import { userRoles } from "../../constants";
import { ASNavbar } from "@stanford-tds/as-components";

export const NavbarWrapper = (props) => {
  const { currentUser } = useUsers();
  const history = useHistory();

  const [navbarConfig, setNavbarConfig] = useState({
    route: history.location.pathname + history.location.search,
    navbarItems: navbar,
  });

  useEffect(() => {
    if (currentUser) {
      let route = history.location.pathname + history.location.search;
      let navbarItems = {};
      navbarItems.width = navbar.width;
      navbarItems.items = [];

      if (currentUser.uid) {
        // **** Show Menu if user has respective permission ****

        const userPermissions = currentUser.permissions;

        // **** Menu Permission ****
        const hasMenuPermission =
          userPermissions.LIST_OWNED_SUBMISSIONS ||
          userPermissions.LIST_ALL_SUBMISSIONS ||
          userPermissions.VIEW_ALL_SUBMISSIONS ||
          userPermissions.VIEW_OWNED_SUBMISSIONS;

        // **** Admin/Super Admin Permission ****
        const hasAdminManageSubmissionPermission =
          userPermissions.VIEW_ALL_SUBMISSIONS ||
          userPermissions.VIEW_OWNED_SUBMISSIONS;

        // **** Check if User is having the Role of System Admin/Super Admin
        const hasAdminRole =
          currentUser.roles.includes(userRoles.superAdmin) ||
          currentUser.roles.includes(userRoles.systemAdmin);

        /******************* Route User to a Page **************
          /*
          - IF pathName is: 
              = "/" => Check if user is  
                        = Admin/Super Admin => Re-direct to => /admin/submissions
                        = Non-Admin => Re-direct to => /faculty

              = "/anyValidPath" => Check if user has permission for that path
                                   = If yes, redirect the user to page
              = Otherwise => redirect to Page not Found
          */
        route = currentUser.roles.length
          ? history.location.pathname === "/"
            ? hasAdminRole
              ? "/admin/submissions"
              : "/faculty"
            : history.location.pathname + history.location.search
          : "/accessdenied";

        /********************** Display Menu, corresponding to Permission of User **************************************/

        // **** Faculty Page ****
        if (
          hasMenuPermission &&
          (userPermissions.VIEW_OWNED_SUBMISSIONS_FACULTY_DEMOGRAPHICS ||
            userPermissions.VIEW_ALL_SUBMISSIONS_FACULTY_DEMOGRAPHICS)
        ) {
          navbarItems.items.push(navbar.items[0]);
        }

        // **** Grants Page ****
        if (
          hasMenuPermission &&
          (userPermissions.VIEW_OWNED_SUBMISSIONS_GRANT_DETAILS ||
            userPermissions.VIEW_ALL_SUBMISSIONS_GRANT_DETAILS)
        ) {
          navbarItems.items.push(navbar.items[1]);
        }

        // **** Trainee Page ****
        if (
          hasMenuPermission &&
          (userPermissions.VIEW_OWNED_SUBMISSIONS_TRAINEE_DETAILS ||
            userPermissions.VIEW_ALL_SUBMISSIONS_TRAINEE_DETAILS)
        ) {
          navbarItems.items.push(navbar.items[2]);
        }

        // **** Applicant Page ****
        if (
          hasMenuPermission &&
          (userPermissions.VIEW_OWNED_SUBMISSIONS_APPLICANT ||
            userPermissions.VIEW_ALL_SUBMISSIONS_APPLICANT)
        ) {
          navbarItems.items.push(navbar.items[3]);
        }

        // **** Outcome Page ****
        if (
          hasMenuPermission &&
          (userPermissions.VIEW_OWNED_SUBMISSIONS_OUTCOME ||
            userPermissions.VIEW_ALL_SUBMISSIONS_OUTCOME)
        ) {
          navbarItems.items.push(navbar.items[4]);
        }

        // **** Output Page ****
        if (
          hasMenuPermission &&
          (userPermissions.VIEW_OWNED_SUBMISSIONS_OUTPUT ||
            userPermissions.VIEW_ALL_SUBMISSIONS_OUTPUT)
        ) {
          navbarItems.items.push(navbar.items[5]);
        }

        // **** Admin/Manage Submissions Page ****
        if (hasAdminManageSubmissionPermission) {
          navbarItems.items.push(navbar.items[6]);
        }

        setNavbarConfig({
          route,
          navbarItems,
        });
      }
    }
  }, [currentUser, history.location.pathname, history.location.search]);

  return (
    <>
      {(navbarConfig.route &&
        navbarConfig.navbarItems &&
        navbarConfig.navbarItems.items.length && (
          <ASNavbar
            menuItems={navbarConfig.navbarItems.items}
            width={navbarConfig.navbarItems.width}
            navbarConfig={navbarConfig}
            history={history}
          />
        )) ||
        ""}
    </>
  );
};
