import { useTranslation } from "react-i18next";
import {
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";
/** Services */
import {
  useTraineesSelectedContext,
  SelectAllStates,
} from "../../../services/traineesSelectedContext";
// **** Styles ****
import { useStyles } from "./TraineeDetails.styles";

const TraineeDetailsTableHead = ({
  hasAddTraineesAccess,
  onRequestSort,
  order,
  orderBy,
}) => {
  const { t } = useTranslation();
  const traineeDetailsTableColumns = "trainee.mainView.list.tableColumns";
  const classes = useStyles();

  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);
  let { ALL_SELECTED, SOME_SELECTED } = SelectAllStates;
  const { traineesSelected, handleSelectAllChange } =
    useTraineesSelectedContext();
  return (
    <MuiTableHead>
      <MuiTableRow>
        {
          <MuiTableCell
            align={"left"}
            style={{ width: "50px" }}
            classes={{ head: classes.tableCellHead }}
          >
            <MuiCheckbox
              classes={{ root: classes.selectBoxHead }}
              checked={traineesSelected.selectAllState === ALL_SELECTED}
              indeterminate={traineesSelected.selectAllState === SOME_SELECTED}
              onChange={handleSelectAllChange}
            />
          </MuiTableCell>
        }
        <MuiTableCell
          align="left"
          key="trainee"
          sortDirection={orderBy === "trainee" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "trainee" ? order : "asc"}
            onClick={createSortHandler("trainee")}
            active={orderBy === "trainee"}
          >
            {t(`${traineeDetailsTableColumns}.trainee`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="commonsId"
          sortDirection={orderBy === "commonsId" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order === "commonsId" ? order : "asc"}
            onClick={createSortHandler("commonsId")}
            active={orderBy === "commonsId"}
          >
            {t(`${traineeDetailsTableColumns}.commonsId`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="pd">
          {t(`${traineeDetailsTableColumns}.faculty`)}
        </MuiTableCell>
        <MuiTableCell align="left" key="departments">
          {t(`${traineeDetailsTableColumns}.departments`)}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="trainingDate"
          sortDirection={orderBy === "trainingDate" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("appointmentStartDate")}
            active={orderBy === "appointmentStartDate"}
          >
            {t(`${traineeDetailsTableColumns}.trainingDate`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="traineeType"
          sortDirection={orderBy === "traineeType" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("traineeType")}
            active={orderBy === "traineeType"}
          >
            {t(`${traineeDetailsTableColumns}.type`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="currentTrainee"
          sortDirection={orderBy === "currentTrainee" ? order : "asc"}
          classes={{
            root: classes.tableCellHeadBoolean,
          }}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("currentTrainee")}
            active={orderBy === "currentTrainee"}
          >
            {t(`${traineeDetailsTableColumns}.currentTrainee`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="grantEligible"
          sortDirection={orderBy === "grantEligible" ? order : "asc"}
          classes={{
            root: classes.tableCellHeadBoolean,
          }}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("grantEligible")}
            active={orderBy === "grantEligible"}
          >
            {t(`${traineeDetailsTableColumns}.grantEligible`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="hhsSupport"
          sortDirection={orderBy === "hhsSupport" ? order : "asc"}
          classes={{
            root: classes.tableCellHeadBoolean,
          }}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("hhsSupport")}
            active={orderBy === "hhsSupport"}
          >
            {t(`${traineeDetailsTableColumns}.hhsSupport`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          key="tgeSupport"
          sortDirection={orderBy === "tgeSupport" ? order : "asc"}
          classes={{
            root: classes.tableCellHeadBoolean,
          }}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("tgeSupport")}
            active={orderBy === "tgeSupport"}
          >
            {t(`${traineeDetailsTableColumns}.tgeSupport`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          key="r90Support"
          sortDirection={orderBy === "r90Support" ? order : "asc"}
          classes={{
            root: classes.tableCellHeadBoolean,
          }}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("r90Support")}
            active={orderBy === "r90Support"}
          >
            {t(`${traineeDetailsTableColumns}.r90Support`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell key="actions">
          {t(`${traineeDetailsTableColumns}.actions`)}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default TraineeDetailsTableHead;
