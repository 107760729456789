import { createContext, useState, useContext } from "react";

const ApplicantsSelectedContext = createContext();

export const SelectAllStates = {
  NONE_SELECTED: 0,
  SOME_SELECTED: 1,
  ALL_SELECTED: 2,
};

export const ApplicantsSelectedProvider = ({ children }) => {
  const [applicantsSelected, setApplicantsSelected] = useState({
    individualSelections: {},
    selectAllState: SelectAllStates.NONE_SELECTED,
  });

  const { ALL_SELECTED, SOME_SELECTED, NONE_SELECTED } = SelectAllStates;
  const handleSelectionChange = (event, row) => {
    const value = !!event.target.checked;
    const rowId = row._links.self.href;

    switch (true) {
      case applicantsSelected.selectAllState === ALL_SELECTED && !value:
      case applicantsSelected.selectAllState === SOME_SELECTED && !value:
        setApplicantsSelected({
          ...applicantsSelected,
          individualSelections: {
            ...applicantsSelected.individualSelections,
            [rowId]: false,
          },
          selectAllState: SOME_SELECTED,
        });
        break;

      case applicantsSelected.selectAllState === NONE_SELECTED && value:
        setApplicantsSelected({
          ...applicantsSelected,
          individualSelections: {
            ...applicantsSelected.individualSelections,
            [rowId]: true,
          },
        });
        break;

      case applicantsSelected.selectAllState === SOME_SELECTED && value:
      case applicantsSelected.selectAllState === NONE_SELECTED && !value:
        const { [rowId]: dummy, ...individualSelections } =
          applicantsSelected.individualSelections;

        setApplicantsSelected({
          ...applicantsSelected,
          individualSelections: { ...individualSelections },
        });
        break;

      case applicantsSelected.selectAllState === ALL_SELECTED && value:
        // empty: should not happen
        break;

      default:
        break;
    }
  };

  const handleSelectAllChange = (event) => {
    setApplicantsSelected({
      ...applicantsSelected,
      individualSelections: {},
      selectAllState: !!event.target.checked ? ALL_SELECTED : NONE_SELECTED,
    });
  };
  const resetAllSelections = () => {
    setApplicantsSelected({
      ...applicantsSelected,
      individualSelections: {},
      selectAllState: NONE_SELECTED,
    });
  };

  const ignoreSelection = (row) => {
    const rowId = row._links.self.href;
    const { [rowId]: dummy, ...individualSelections } =
      applicantsSelected.individualSelections;

    setApplicantsSelected({
      ...applicantsSelected,
      individualSelections: { ...individualSelections },
    });
  };

  return (
    <ApplicantsSelectedContext.Provider
      value={{
        applicantsSelected,
        setApplicantsSelected,
        handleSelectionChange,
        handleSelectAllChange,
        resetAllSelections,
        ignoreSelection,
      }}
    >
      {children}
    </ApplicantsSelectedContext.Provider>
  );
};

export const useApplicantsSelectedContext = () =>
  useContext(ApplicantsSelectedContext);
