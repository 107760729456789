import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  appBarStyle: {
    backgroundColor: "#F4F4F4",
    "& .MuiTab-wrapper": {
      letterSpacing: 2,
    },
    "& .MuiTab-root": {
      fontWeight: theme.typography.fontWeightBold,
      color: "rgba(0, 0, 0,0.54)",
      fontSize: 14,
    },
    "& .Mui-selected": {
      color: "#007C92",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#007C92",
    },
  },
  container: {
    "&.MuiTableContainer-root": {
      width: "100%",
      margin: 0,
      overflow: "visible",
    },
    "& .MuiTableCell-root": {
      padding: "6px 20px",
    },
    "& *": {
      fontSize: 14,
    },
  },
  noDataTableCell: {
    verticalAlign: "middle",
    textAlign: "center",
    padding: "0",
    color: theme.palette.grey[500],
  },
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    "& tbody tr:last-child td": {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.grey[300],
    },
  },
  fixHeader: {
    overflowX: "inherit",
  },
  actionButton: {
    "& button": {
      height: "40px",
    },
  },
  unorderedList: {
    paddingInlineStart: "unset",
    marginBlockStart: "unset",
    marginBlockEnd: "unset",
  },
  noBold: {
    marginTop: 15,
    backgroundColor: theme.palette.background.paper,
    "& .table1": {
      minWidth: 650,
      "& thead tr th:first-child": {
        minWidth: 305,
      },
    },
    "& .MuiTypography-root": {
      whiteSpace: "pre-line",
    },
  },
}));
