import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ASConfirmation } from "../UI/ASConfirmation/ASConfirmation";
import { Typography as MuiTypography } from "@material-ui/core";
/**  Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { useOutcomesContext } from "../../services/outcomesContext";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "../../services/outcomesSelectedContext";
import { deleteSelectedOutcomes } from "../../services/outcomesService";
/* Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";

export const DeleteOutcomes = (props) => {
  const {
    openDeleteOutcomesDialog,
    setOpenDeleteOutcomesDialog,
    toggleDeleteOutcomes,
    totalCount,
  } = props;

  const { setAlert, clearAlert } = useAlertContext();

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const submissionId = getSelectedSubmission();

  const { outcomesPreferences, setOutcomesPreferences } = useOutcomesContext();
  const { resetAllSelections, outcomesSelected } = useOutcomesSelectedContext();

  const { t } = useTranslation();

  const handleCancel = () => {
    clearAlert();
    setOpenDeleteOutcomesDialog(false);
  };

  const [countToDelete, setCountToDelete] = useState(0);

  useEffect(() => {
    if (outcomesSelected.selectAllState === SelectAllStates.NONE_SELECTED) {
      setCountToDelete(
        Object.keys(outcomesSelected.individualSelections).filter(
          (key) => outcomesSelected.individualSelections[key] === true
        ).length
      );
    } else {
      setCountToDelete(
        totalCount -
          Object.keys(outcomesSelected.individualSelections).filter(
            (key) => outcomesSelected.individualSelections[key] === false
          ).length
      );
    }
  }, [totalCount, outcomesSelected, setCountToDelete]);

  return (
    <ASConfirmation
      open={openDeleteOutcomesDialog}
      message={
        <>
          <MuiTypography variant="body1" style={{ fontWeight: 700 }}>
            {t("outcomes.mainView.delete.confirmationBox.body", {
              count: countToDelete,
              plural: countToDelete !== 1 ? "s" : "",
            })}
          </MuiTypography>
          <MuiTypography variant="body1">
            {t("globals.list.messages.wantToContinue")}
          </MuiTypography>
        </>
      }
      title={t("outcomes.mainView.delete.confirmationBox.title")}
      onClose={(choice) => {
        if (choice) {
          deleteSelectedOutcomes(
            submissionId,
            setOpenDeleteOutcomesDialog,
            setAlert,
            clearAlert,
            resetAllSelections,
            toggleDeleteOutcomes,
            setOutcomesPreferences,
            outcomesPreferences,
            outcomesSelected
          );
        }
        handleCancel();
      }}
      okLabel={t("outcomes.mainView.delete.confirmationBox.okLabel")}
      cancelLabel={t("outcomes.mainView.delete.confirmationBox.cancelLabel")}
    />
  );
};
