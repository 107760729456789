import { useState, useEffect, useCallback } from "react";
import { Link as RouteLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
/** Material UI Components */
import { NavigateNext as MuiNavigateNext } from "@material-ui/icons";
import {
  Grid as MuiGrid,
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  Tooltip as MuiTooltip,
  Chip as MuiChip,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider as MuiMuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import { format, isValid as isDateValid, parseISO } from "date-fns";
import * as Yup from "yup";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useBackButtonCallback } from "../../Utils/useBackButtonCallback";
/** Static Dropdown Values */
import { traineeTypeDropDown, EthnicityValues } from "../../constants";
/** Custom Component */
import { ReactComponent as OrcidIdLogo } from "../../assets/icons/orcid-id.svg";
import { AccessDenied } from "../AccessDenied";
import { FormButton } from "../UI/Button/FormButton";
/** Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import {
  getEditTraineeCitizenshipValues,
  putTraineeDetailsByIdUpdate,
  getTraineeDetailsById,
} from "../../services/traineeService";
import { loadSubmissionFacultyList } from "../../services/facultyService";
import { getAutocompleteForEditTrainee } from "../../services/autoCompleteEditTraineeService";
/* Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./EditTrainee.styles";

let timeoutID;

// Delay after user pauses typing before calling API (in milliseconds)
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500;

// Minimum number of characters entered by user before calling API
export const AUTOCOMPLETE_MIN_INPUT = 2;

/**
 * Edit Trainee Component
 * @param {*} props
 * @returns
 */
export const EditTrainee = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { history } = props;

  // *********************** State Values ***********************

  // Initial Form State
  const [traineeFormValues, setTraineeFormValues] = useState({
    orcidId: "",
    commonsId: "",
    stanfordEmail: "",
    appointmentStartDate: null,
    appointmentEndDate: null,
    traineeType: "",
    submissionTraineeFaculty: [],
    submissionTraineeMentors: [],
    trainee: {},
    citizenship: {},
    ethnicity: "",
    grantEligible: false,
    hhsSupport: false,
    tgeSupport: false,
    r90Support: false,
  });

  const [loading, setLoading] = useState(false);
  const [citizenshipList, setCitizenshipList] = useState([]);
  const [citizenshipInputValue, setCitizenshipInputValue] = useState("");
  const [emailFieldError, setEmailFieldError] = useState({
    value: null,
    isError: false,
  });
  const [commonsIdFieldError, setCommonsIdFieldError] = useState({
    value: null,
    isError: false,
  });

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;

  // **** Faculty Autocomplete Dropdown ****
  const [facultyList, setFacultyList] = useState([]);

  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const { alert, setAlert, clearAlert } = useAlertContext();
  const [defaultCitizenshipOptions, setDefaultCitizenshipOptions] = useState(
    []
  );

  useEffect(() => {
    getEditTraineeCitizenshipValues("a", setDefaultCitizenshipOptions);
  }, [setDefaultCitizenshipOptions]);

  // ************ Set Form Values, with Trainee Details from API *********

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departmentInput, setDepartmentInputValue] = useState("DE");

  const handleDepartmentChange = (inputValue) => {
    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(async () => {
      if (inputValue.trim()?.length >= AUTOCOMPLETE_MIN_INPUT) {
        getAutocompleteForEditTrainee(
          {
            parameter: "departments",
            size: 10,
            targetValue: inputValue,
            includeOrgsWithAcademicPlanCodeOnly:
              traineeFormValues.traineeType === "PRE_DOC",
          },
          () => clearAlert(),
          (error) => setAlert("error", error.message),
          (data) => setDepartmentOptions(data.values)
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };
  useEffect(() => {
    handleDepartmentChange(departmentInput);
    // eslint-disable-next-line
  }, [departmentInput]);

  // **** Get Trainee Id ****
  const traineeId = useParams().trainee;

  const selectedSubmission = getSelectedSubmission();

  useEffect(() => {
    getTraineeDetailsById(
      setTraineeFormValues,
      setLoading,
      traineeId,
      selectedSubmission,
      setAlert,
      clearAlert
    );
  }, [traineeId, selectedSubmission, setAlert, clearAlert]);

  // ********* Fetch values for Faculty Autocomplete*********
  const handleCitizenshipAutoCompleteChange = (inputValue) => {
    setCitizenshipInputValue(inputValue);
    if (inputValue)
      getEditTraineeCitizenshipValues(inputValue, setCitizenshipList);
  };

  const handleFacultyAutocompleteChange = (event) => {
    let facultyVal = event.target.value.trim();

    loadSubmissionFacultyList(
      selectedSubmission,
      facultyVal,
      setFacultyList,
      setAlert,
      clearAlert,
      setNoOptionsText,
      t
    );
  };

  // **** Check if the Manual Typed Dates are valid ****
  const checkIfValidDates = (appointmentStartDate, appointmentEndDate) => {
    return appointmentStartDate && appointmentEndDate
      ? parseISO(appointmentEndDate).getTime() <
          parseISO(appointmentStartDate).getTime()
      : false;
  };

  // ** Redirect on cancel button click **
  const onCancelHandler = useCallback(() => {
    history.push("/trainees");
  }, [history]);

  // Handle back button
  useBackButtonCallback(onCancelHandler);

  // **** Form Validation Schema ****
  const EditTraineeFormValidation = Yup.object().shape({
    orcidId: Yup.string().when("hasSourceOrcid", {
      is: (val) => val === false,
      then: Yup.string().test((val) => {
        if (val) {
          if (val.length === 19) return true;
          if (val.length < 19 && val.length > 0) return false;
        } else {
          return true;
        }
      }),
    }),
    stanfordEmail: Yup.string().when("currentTrainee", {
      is: (val) => val === true,
      then: Yup.string()
        .email(t("trainee.notification.email"))
        .required("Required"),
      otherwise: Yup.string().email(t("trainee.notification.email")),
    }),
    appointmentStartDate: Yup.date().nullable(),
    appointmentEndDate: Yup.date().nullable(),
    department: Yup.object().shape({
      code: Yup.string().required(),
    }),
    submissionTraineeFaculty: Yup.array().min(1).required("Required"),
    traineeType: Yup.string().required("Required"),
  });

  // Handle logic for citizenship and grant eligible checkbox
  const handleCitizenshipChange = (props) => {
    return props ? ["USA"].includes(props.code) : false;
  };

  return (
    !loading &&
    (traineeFormValues._links?.editSubmissionTraineeDetails ? (
      <MuiGrid
        container
        wrap="nowrap"
        justify="flex-start"
        direction="column"
        spacing={2}
      >
        <MuiGrid item></MuiGrid>
        {alert.exists && (
          <MuiGrid item>
            <ASAlert />
          </MuiGrid>
        )}
        <MuiGrid item className={classes.routeLink}>
          <MuiBreadcrumbs
            separator={<MuiNavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouteLink to="/trainees" className={classes.routeLink}>
              {t("trainee.title")}
            </RouteLink>
            <MuiTypography color="textPrimary">
              {t("trainee.edit.title")}
            </MuiTypography>
          </MuiBreadcrumbs>
        </MuiGrid>

        <MuiGrid item>
          <MuiTypography variant="h5">
            {traineeFormValues.trainee
              ? `${traineeFormValues.trainee.fullName}`
              : ""}
          </MuiTypography>
          <MuiTypography variant="h5">
            {traineeFormValues.traineeType
              ? `${t(traineeTypeDropDown[traineeFormValues.traineeType])}`
              : ""}
          </MuiTypography>
        </MuiGrid>

        <MuiGrid item>
          <Formik
            initialValues={traineeFormValues}
            enableReinitialize={true}
            validationSchema={EditTraineeFormValidation}
            validateOnMount={true}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              putTraineeDetailsByIdUpdate(
                setEmailFieldError,
                setCommonsIdFieldError,
                selectedSubmission,
                traineeId,
                values,
                props,
                setAlert,
                clearAlert
              );
              setSubmitting(false);
              setEmailFieldError(false);
            }}
          >
            {(formik) => {
              const {
                values,
                isSubmitting,
                errors,
                touched,
                handleBlur,
                handleSubmit,
                isValid,
                dirty,
              } = formik;

              const handleChangeOrcID = (event) => {
                let { value } = event.target;

                // remove any non-alphanumeric characters except hyphens
                value = value.replace(/[^\d]/g, "");

                // insert hyphens every 4 characters
                let formattedValue = "";
                for (let i = 0; i < value.length; i++) {
                  if (i > 0 && i % 4 === 0) {
                    formattedValue += "-";
                  }
                  formattedValue += value[i];
                }
                // update the state with formattedValue
                formik.setFieldValue("orcidId", formattedValue);
              };

              return (
                <form onSubmit={handleSubmit}>
                  <MuiGrid
                    container
                    item
                    xs={12}
                    sm={8}
                    md={6}
                    lg={5}
                    xl={4}
                    direction="column"
                    justify="space-between"
                    spacing={2}
                    className={classes.traineeForm}
                  >
                    <MuiGrid item xs={12}>
                      <MuiGrid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <MuiGrid item xs={11}>
                          <MuiTextField
                            id="orcidId"
                            name="orcidId"
                            label={`${t("trainee.edit.traineeForm.orcidId")}`}
                            value={values.orcidId || ""}
                            onChange={handleChangeOrcID}
                            onBlur={handleBlur}
                            helperText={touched.orcidId ? errors.orcidId : ""}
                            error={touched.orcidId && Boolean(errors.orcidId)}
                            variant="outlined"
                            fullWidth
                            disabled={values.hasSourceOrcid}
                            inputProps={{ maxLength: 19 }}
                            autoComplete="off"
                          />
                        </MuiGrid>
                        <MuiGrid item xs={1} className={classes.orcidId}>
                          <MuiTooltip
                            interactive
                            placement="right"
                            classes={{ tooltip: classes.toolTip }}
                            title={
                              <div>
                                The ORCID iD field is the individual’s
                                persistent digital identifier{" "}
                                <MuiLink
                                  href="https://orcid.org/"
                                  classes={{ root: classes.tooltipLink }}
                                  target="_blank"
                                >
                                  (<u>https://orcid.org/</u>)
                                </MuiLink>{" "}
                                that can be used to retrieve their professional
                                information — affiliations, grants, and
                                publications. <br />
                                <br />
                                The source for this information is Stanford’s
                                ORCID Integration application{" "}
                                <MuiLink
                                  href="https://authorize.stanford.edu/orcid/OrcidApp"
                                  target="_blank"
                                  classes={{ root: classes.tooltipLink }}
                                >
                                  (
                                  <u>
                                    https://authorize.stanford.edu/orcid/OrcidApp
                                  </u>
                                  )
                                </MuiLink>
                                . <br />
                                <br />
                                If the ORCID iD is missing for an individual, we
                                would encourage that it be updated in the source
                                system, which requires each individual to
                                verify/create their own ORCID iD directly in the
                                application.
                              </div>
                            }
                          >
                            <div style={{ height: "20px", width: "20px" }}>
                              <OrcidIdLogo />
                            </div>
                          </MuiTooltip>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="commonsId"
                        name="commonsId"
                        label={`${t("trainee.edit.traineeForm.commonsID")}`}
                        variant="outlined"
                        error={commonsIdFieldError.isError}
                        helperText={
                          commonsIdFieldError.isError &&
                          `${t("trainee.edit.traineeForm.commonsIdError")}`
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const commonIdVal = e.target.value;
                          setCommonsIdFieldError({
                            ...commonsIdFieldError,
                            isError:
                              commonsIdFieldError.isError &&
                              commonsIdFieldError.value === commonIdVal,
                          });

                          formik.setFieldValue("commonsId", commonIdVal);
                        }}
                        value={values.commonsId}
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 20 }}
                        onKeyPress={(e) => {
                          if (e.charCode === 32) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        name="submissionTraineeFaculty"
                        multiple
                        value={values.submissionTraineeFaculty}
                        options={[
                          ...facultyList,
                          ...(values.submissionTraineeFaculty || []),
                        ]}
                        filterSelectedOptions={true}
                        filterOptions={(option, state) => option}
                        getOptionLabel={(option) =>
                          `${option.displayText || option.fullName}${
                            option.value?.sunetId || option.sunetId
                              ? ` (${option.value?.sunetId || option.sunetId})`
                              : ""
                          }`
                        }
                        getOptionSelected={(option, value) =>
                          (parseInt(option.univId) ||
                            parseInt(option.value?.universityId)) ===
                          (parseInt(value.univId) ||
                            parseInt(value.value?.universityId))
                        }
                        noOptionsText={noOptionsText}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            "submissionTraineeFaculty",
                            value
                          );
                          setFacultyList([]);
                          setNoOptionsText(
                            t("globals.autocomplete.helperText")
                          );
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t("trainee.edit.traineeForm.faculty")}*`}
                            onChange={(event, value) => {
                              handleFacultyAutocompleteChange(event);
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        renderOption={(option, { inputValue }) => (
                          <AutocompleteOption
                            option={option}
                            inputValue={inputValue}
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        name="submissionTraineeMentors"
                        multiple
                        value={values.submissionTraineeMentors}
                        options={values.submissionTraineeMentors}
                        getOptionLabel={(option) =>
                          `${option.fullName}${
                            option.sunetId ? ` (${option.sunetId})` : ""
                          }`
                        }
                        filterSelectedOptions={true}
                        filterOptions={(option, state) => option}
                        getOptionSelected={(option, value) =>
                          (parseInt(option.univId) ||
                            parseInt(option.value?.universityId)) ===
                          (parseInt(value.univId) ||
                            parseInt(value.value?.universityId))
                        }
                        noOptionsText={noOptionsText}
                        disabled={true}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <MuiChip
                              className={classes.mentorOptionTagStyle}
                              label={`${option.fullName}${
                                option.sunetId ? ` (${option.sunetId})` : ""
                              }`}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t("trainee.edit.traineeForm.mentor")}`}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        id="department"
                        name="department"
                        value={values.department}
                        options={
                          departmentOptions?.length > 0
                            ? departmentOptions.map((deptObj) => {
                                return deptObj.value;
                              })
                            : []
                        }
                        getOptionLabel={(option) =>
                          Object.keys(option).length > 0
                            ? `${option.name} (${option.code})`
                            : ""
                        }
                        onChange={(e, value) => {
                          formik.setFieldValue("department", value);
                        }}
                        getOptionDisabled={(option) =>
                          values.departments &&
                          values.departments.some((el) => {
                            return el.code === option.code;
                          })
                        }
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            disabled={loading}
                            label={`${t(
                              "trainee.edit.traineeForm.departments"
                            )}*`}
                            variant="outlined"
                            noOptionsText={t("globals.autocomplete.helperText")}
                            onChange={(e) =>
                              setDepartmentInputValue(e.target.value)
                            }
                            fullWidth
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="stanfordEmail"
                        label={`${t("trainee.edit.traineeForm.stanfordEmail")}`}
                        value={values.stanfordEmail}
                        onBlur={handleBlur}
                        helperText={
                          errors.stanfordEmail || emailFieldError.isError
                            ? errors.stanfordEmail ||
                              t("trainee.notification.email")
                            : ""
                        }
                        error={errors.stanfordEmail || emailFieldError.isError}
                        onChange={(e) => {
                          const stanfordEmail = e.target.value;
                          setEmailFieldError({
                            ...emailFieldError,
                            isError:
                              emailFieldError.isError &&
                              emailFieldError.value === stanfordEmail,
                          });

                          formik.setFieldValue("stanfordEmail", stanfordEmail);
                        }}
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiMuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiKeyboardDatePicker
                          maxDate={
                            parseISO(values.appointmentEndDate) || undefined
                          }
                          inputVariant="outlined"
                          label={`${t(
                            "trainee.edit.traineeForm.trainingStartDate"
                          )}`}
                          name="appointmentStartDate"
                          inputProps={{ autoComplete: "off" }}
                          invalidDateMessage={`${t(
                            "trainee.edit.traineeForm.invalidDateFormat"
                          )}`}
                          maxDateMessage={`${t(
                            "trainee.edit.traineeForm.maxDateMessage"
                          )}`}
                          clearable
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline-start-from"
                          value={
                            values.appointmentStartDate &&
                            parseISO(values.appointmentStartDate)
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              "appointmentStartDate",
                              isDateValid(date)
                                ? format(new Date(date), "yyyy-MM-dd")
                                : date
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiMuiPickersUtilsProvider>
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiMuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiKeyboardDatePicker
                          inputVariant="outlined"
                          label={`${t(
                            "trainee.edit.traineeForm.trainingEndDate"
                          )}`}
                          name="appointmentEndDate"
                          inputProps={{ autoComplete: "off" }}
                          invalidDateMessage={`${t(
                            "trainee.edit.traineeForm.invalidDateFormat"
                          )}`}
                          clearable
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline-start-from"
                          minDateMessage={`${t(
                            "trainee.edit.traineeForm.minDateMessage"
                          )}`}
                          minDate={
                            parseISO(values.appointmentStartDate) || undefined
                          }
                          value={
                            values.appointmentEndDate &&
                            parseISO(values.appointmentEndDate)
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              "appointmentEndDate",
                              isDateValid(date)
                                ? format(new Date(date), "yyyy-MM-dd")
                                : date
                            );
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiMuiPickersUtilsProvider>
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        id="citizenship"
                        name="citizenship"
                        options={
                          citizenshipInputValue
                            ? citizenshipList
                            : defaultCitizenshipOptions
                        }
                        noOptionsText={t(
                          "trainee.edit.traineeForm.citizenshipHelperText"
                        )}
                        getOptionLabel={(option) => option.name ?? ""}
                        value={values.citizenship}
                        clearOnEscape={true}
                        closeIcon={false}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          formik.setFieldValue("citizenship", value ?? {});
                          formik.setFieldValue(
                            "grantEligible",
                            handleCitizenshipChange(value)
                          );
                          if (!handleCitizenshipChange(value)) {
                            formik.setFieldValue("tgeSupport", false);
                          }
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={t("trainee.edit.traineeForm.citizenship")}
                            variant="outlined"
                            onChange={(e) => {
                              handleCitizenshipAutoCompleteChange(
                                e.target.value
                              );
                            }}
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        options={Object.keys(EthnicityValues)}
                        getOptionLabel={(option) => t(EthnicityValues[option])}
                        id="ethnicity"
                        value={values.ethnicity}
                        clearOnEscape={true}
                        closeIcon={false}
                        onBlur={handleBlur}
                        onChange={(e, value) => {
                          formik.setFieldValue("ethnicity", value);
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={t("trainee.edit.traineeForm.ethnicity")}
                            variant="outlined"
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid
                      container
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "7px",
                        marginBottom: "10px",
                      }}
                    >
                      <MuiGrid item>
                        <MuiFormControlLabel
                          control={
                            <MuiCheckbox
                              name="grantEligible"
                              disableRipple={true}
                              value={values.grantEligible}
                              onChange={(e) => {
                                if (values.grantEligible) {
                                  formik.setFieldValue(
                                    "tgeSupport",
                                    e.target.checked
                                  );
                                }
                                formik.setFieldValue(
                                  "grantEligible",
                                  e.target.checked
                                );
                              }}
                              checked={values.grantEligible}
                              color="primary"
                            />
                          }
                          label={`${t(
                            "trainee.edit.traineeForm.grantEligible"
                          )}`}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiFormControlLabel
                        classes={{ root: classes.formControlLabelRoot }}
                        control={
                          <MuiCheckbox
                            name="hhsSupport"
                            classes={{ root: classes.checkBoxRoot }}
                            disableRipple={true}
                            value={values.hhsSupport}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "hhsSupport",
                                e.target.checked
                              );
                            }}
                            checked={values.hhsSupport}
                            color="primary"
                          />
                        }
                        label={`${t("trainee.edit.traineeForm.hhsSupport")}`}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiFormControlLabel
                        control={
                          <MuiCheckbox
                            name="tgeSupport"
                            disableRipple={true}
                            value={values.tgeSupport}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "tgeSupport",
                                e.target.checked
                              );
                            }}
                            checked={
                              !values.grantEligible ? false : values.tgeSupport
                            }
                            color="primary"
                          />
                        }
                        disabled={!values.grantEligible}
                        label={`${t("trainee.edit.traineeForm.tgeSupport")}`}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiFormControlLabel
                        classes={{ root: classes.formControlLabelRoot }}
                        control={
                          <MuiCheckbox
                            name="r90Support"
                            classes={{ root: classes.checkBoxRoot }}
                            disableRipple={true}
                            value={values.r90Support}
                            onChange={(e) => {
                              formik.setFieldValue(
                                "r90Support",
                                e.target.checked
                              );
                            }}
                            checked={values.r90Support}
                            color="primary"
                          />
                        }
                        label={`${t("trainee.edit.traineeForm.r90Support")}`}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiGrid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <MuiGrid item>
                          <FormButton
                            classes={{ root: classes.actionButton }}
                            onClick={onCancelHandler}
                            name={t("globals.form.actionButtons.cancel")}
                            variant="outlined"
                          />
                        </MuiGrid>
                        <MuiGrid item>
                          <FormButton
                            classes={{ root: classes.actionButton }}
                            name={t("globals.form.actionButtons.save")}
                            variant="contained"
                            type="submit"
                            disabled={
                              isSubmitting ||
                              !dirty ||
                              !isValid ||
                              emailFieldError.isError ||
                              commonsIdFieldError.isError ||
                              checkIfValidDates(
                                values.appointmentStartDate,
                                values.appointmentEndDate
                              )
                            }
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </form>
              );
            }}
          </Formik>
        </MuiGrid>
      </MuiGrid>
    ) : (
      <AccessDenied />
    ))
  );
};

/* Options to be shown in Faculty Autocomplete
 * Values in the list are to be shown in the format:
 *    - Faculty Name
 *    - Rank
 *    - Department
 * With parts and matches, the value typed in the Input Field,
 * is shown higlighted in the Options List for matching values
 */
const AutocompleteOption = ({ option, inputValue }) => {
  const matches = match(option.displayText, inputValue);
  const parts = parse(option.displayText, matches);

  return (
    <div>
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      <div>
        {[option.value.rank, option.value.departmentName]
          .filter(Boolean)
          .join(", ")}
      </div>
    </div>
  );
};
