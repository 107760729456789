import { useMemo } from "react";
import {
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
} from "@material-ui/core";

export const TableWithPaging = (props) => {
  const { values, children, context, ...inProps } = props;

  const { preferences, setPreferences } = context;

  const page = useMemo(
    () => preferences.values.pagination.page || 0,
    [preferences]
  );

  const pageSize = useMemo(
    () => preferences.values.pagination.pageSize || 10,
    [preferences]
  );

  const handleChangePage = (event, newPage) => {
    newPage = parseInt(newPage, 10);

    setPreferences({
      ...preferences,
      values: {
        ...preferences.values,
        pagination: {
          ...preferences.values.pagination,
          page: newPage,
        },
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    const newPage = Math.floor((page * pageSize) / newPageSize);

    setPreferences({
      ...preferences,
      values: {
        ...preferences.values,
        pagination: {
          ...preferences.values.pagination,
          page: newPage,
          pageSize: newPageSize,
        },
      },
    });
  };

  return (
    <MuiTableContainer {...inProps}>
      {children}
      {
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={values.totalCount || 0}
          rowsPerPage={pageSize}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    </MuiTableContainer>
  );
};
