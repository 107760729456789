import { useState, useEffect, useCallback } from "react";
import { Link as RouteLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// **** Material UI Components****
import {
  AddCircle as MuiAddCircleIcon,
  Delete as MuiRemoveIcon,
  Edit as MuiEditIcon,
  Done as MuiDoneIcon,
  Cancel as MuiCancelIcon,
  NavigateNext as MuiNavigateNext,
} from "@material-ui/icons";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
  MenuItem as MuiMenuItem,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Paper as MuiPaper,
  Grid as MuiGrid,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
/** Utilities */
import { nanoid } from "nanoid";
import { Formik, FieldArray } from "formik";
import { format, isValid as isDateValid, parseISO } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { AccessDenied } from "../AccessDenied";
// **** Custom Component ****
import { useBackButtonCallback } from "../../Utils/useBackButtonCallback";
import { getTrainingTypeNameByTrainingTypeCode } from "../../Utils/utils";
import { FormButton } from "../UI/Button/FormButton";
/** Static Dropdown Values */
import {
  outcomeTraineeTypeDropDown,
  OutcomeTerminalDegreeTypeEnums,
  submissionType,
  OutcomePreDocTrainingSupportTypeEnums,
  OutcomePostDocTrainingSupportTypeEnums,
  OutcomeTrainingSupportSourceEnums,
  TrainingSupportYearMaxCount,
  OutcomeResultingDegreeTypeEnums,
  OutcomePositionActivityTypeEnums,
  SubsequentGrantMaxCount,
  OutcomeSubsequentGrantRoleEnums,
  OutcomePositionWorkforceSectorTypeEnums,
} from "../../constants";
/**  Services*/
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import {
  putOutcomeDetailsByIdUpdate,
  getOutcomeDetailsById,
} from "../../services/outcomesService";
import { loadSubmissionFacultyList } from "../../services/facultyService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./CreateOutcome.styles";

// ************ EditOutcome Component ***********
export const EditOutcome = (props) => {
  const { alert, setAlert, clearAlert } = useAlertContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const { history } = props;

  // *********************** State Values ***********************

  const [isEditing, setIsEditing] = useState(false);
  const [outcome, setOutcome] = useState({});
  const [loading, setLoading] = useState(true);

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmission = getSelectedSubmission();

  // **** Faculty Autocomplete Dropdown ****
  const [facultyList, setFacultyList] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  // ************ Set Form Values, with Outcome Details from API *********

  // **** Get Outcome Id ****
  const outcomeId = useParams().outcome;

  useEffect(() => {
    getOutcomeDetailsById(
      setOutcome,
      setLoading,
      outcomeId,
      selectedSubmission,
      setAlert,
      clearAlert
    );
  }, [outcomeId, selectedSubmission, setAlert, clearAlert]);

  const initialValues = {
    traineeLastName: outcome.traineeLastName || "",
    traineeFirstName: outcome.traineeFirstName || "",
    traineeMiddleName: outcome.traineeMiddleName || "",
    traineeEmail: outcome.traineeEmail || "",
    traineeType: outcome.traineeType || "",
    trainingStartDate: outcome.trainingStartDate || null,
    trainingEndDate: outcome.trainingEndDate || null,
    terminalDegrees: outcome.terminalDegrees || [],
    submissionOutcomeFaculty: outcome.submissionOutcomeFaculty || [],
    trainingSupportYears: outcome.trainingSupportYears || [],
    resultingDegrees: outcome.resultingDegrees || [],
    researchTopic: outcome.researchTopic || "",
    initialPositionSummary: outcome.initialPositionSummary || {
      position: "",
      department: "",
      institution: "",
      workforceSector: null,
      activity: null,
    },
    currentPositionSummary: outcome.currentPositionSummary || {
      position: "",
      department: "",
      institution: "",
      workforceSector: null,
      activity: null,
    },
    subsequentGrants: outcome.subsequentGrants || [],
  };

  // **** Form Validation Schema ****
  const PositionSummaryValidation = Yup.object().shape({
    department: Yup.string().notRequired(),
    institution: Yup.string().notRequired(),
    activity: Yup.string().nullable().notRequired(),
    workforceSector: Yup.string().nullable().notRequired(),
    position: Yup.string().when(
      ["department", "institution", "activity", "workforceSector"],
      {
        is: (department, institution, activity, workforceSector) =>
          department || institution || activity || workforceSector
            ? true
            : false,
        then: Yup.string().required("Required"),
      }
    ),
  });

  const OutcomeFormValidation = Yup.object().shape({
    traineeLastName: Yup.string().required("Required"),
    traineeFirstName: Yup.string().required("Required"),
    traineeEmail: Yup.string().email(t("outcomes.notification.email")),
    trainingStartDate: Yup.date().nullable(),
    trainingEndDate: Yup.date().nullable(),
    initialPositionSummary: PositionSummaryValidation,
    currentPositionSummary: PositionSummaryValidation,
  });
  const submissionId = selectedSubmission;

  // ********* Fetch values for Faculty Autocomplete*********
  const handleFacultyAutocompleteChange = (event) => {
    let facultyVal = event.target.value.trim();

    loadSubmissionFacultyList(
      submissionId,
      facultyVal,
      setFacultyList,
      setAlert,
      clearAlert,
      setNoOptionsText,
      t
    );
  };

  const populateYearDropDown = () => {
    const currentYear = new Date().getFullYear();
    return Array.from(new Array(21), (val, index) => currentYear - index);
  };

  const yearOptions = populateYearDropDown();

  // **** Check if the Manual Typed Dates are valid ****
  const checkIfValidDates = (trainingStartDate, trainingEndDate) => {
    return trainingStartDate && trainingEndDate
      ? parseISO(trainingEndDate).getTime() <
          parseISO(trainingStartDate).getTime()
      : false;
  };

  /** 
  redirect on cancel button click
  */
  const onCancelHandler = useCallback(() => {
    history.push("/outcomes");
  }, [history]);

  // Handle back button
  useBackButtonCallback(onCancelHandler);

  const isFullTerminalDegree = (degree) => ["MD", "PHD"].includes(degree);

  const getTerminalDegreeDropdownOptions = (currentTerminalDegrees) => {
    if (currentTerminalDegrees.length === 0) {
      return Object.keys(OutcomeTerminalDegreeTypeEnums);
    }

    if (
      currentTerminalDegrees.length === 1 &&
      isFullTerminalDegree(currentTerminalDegrees[0].degreeCode)
    ) {
      return currentTerminalDegrees[0].degreeCode === "MD" ? ["PHD"] : ["MD"];
    }
    return [];
  };

  const isFullResultingDegree = (degree) => {
    return degree && degree !== ""
      ? !["NONE", "IN_TRAINING"].includes(degree)
      : false;
  };

  const getResultingDegreeDropdownOptions = (currentResultingDegrees) => {
    const resultingDegreeOptions = Object.keys(
      OutcomeResultingDegreeTypeEnums
    ).filter((degreeCode) => degreeCode !== "OTHER_D");

    if (currentResultingDegrees.length === 0) {
      return resultingDegreeOptions;
    }

    if (
      currentResultingDegrees.length === 1 &&
      !isFullResultingDegree(currentResultingDegrees[0].degreeCode)
    ) {
      return [];
    }

    let currentDegreeCodes = currentResultingDegrees.map((degree) => {
      return degree.degreeCode;
    });
    // get the remaining degrees
    const remainingDegrees = resultingDegreeOptions.filter(
      (item) =>
        !currentDegreeCodes.includes(item) && isFullResultingDegree(item)
    );

    return remainingDegrees;
  };

  return (
    !loading &&
    (outcome._links?.editSubmissionOutcomeDetails ? (
      <div className={classes.topCreateOutcomeDivStyled}>
        {alert.exists && (
          <MuiGrid item>
            <ASAlert />
          </MuiGrid>
        )}
        <div className={classes.titleStyle}>
          <MuiBreadcrumbs
            separator={<MuiNavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouteLink to="/outcomes" className={classes.routeLink}>
              {t("outcomes.title")}
            </RouteLink>

            <MuiTypography color="textPrimary">
              {props.type === submissionType.create
                ? t("outcomes.create.title")
                : t("outcomes.edit.title")}
            </MuiTypography>
          </MuiBreadcrumbs>
        </div>
        <div className={classes.titleNameStyle}>
          <MuiTypography variant="h5">
            {props.type === submissionType.create
              ? t("outcomes.create.new")
              : t("outcomes.edit.title")}
          </MuiTypography>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={OutcomeFormValidation}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            putOutcomeDetailsByIdUpdate(
              selectedSubmission,
              outcomeId,
              values,
              props,
              setAlert,
              clearAlert
            );
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return (
              <form onSubmit={formik.handleSubmit}>
                <div className={classes.outcomeFormDivStyled}>
                  <MuiTextField
                    name="traineeLastName"
                    label={`${t(
                      "outcomes.create.outcomeForm.traineeLastName"
                    )}`}
                    value={formik.values.traineeLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    className={classes.singleFieldStyle}
                    autoComplete="off"
                    inputProps={{ maxLength: 200 }}
                    required
                  />
                  <MuiTextField
                    name="traineeFirstName"
                    label={`${t(
                      "outcomes.create.outcomeForm.traineeFirstName"
                    )}`}
                    value={formik.values.traineeFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    className={classes.singleFieldStyle}
                    autoComplete="off"
                    inputProps={{ maxLength: 200 }}
                    required
                  />
                  <MuiTextField
                    name="traineeMiddleName"
                    label={`${t(
                      "outcomes.create.outcomeForm.traineeMiddleName"
                    )}`}
                    value={formik.values.traineeMiddleName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    className={classes.singleFieldStyle}
                    inputProps={{ maxLength: 50 }}
                    autoComplete="off"
                  />
                  <MuiTextField
                    name="traineeEmail"
                    label={`${t("outcomes.create.outcomeForm.traineeEmail")}`}
                    value={formik.values.traineeEmail}
                    helperText={
                      !!formik.errors.traineeEmail
                        ? `${t("outcomes.notification.email")}`
                        : ""
                    }
                    error={formik.errors.traineeEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    className={classes.singleFieldStyle}
                    inputProps={{ maxLength: 150 }}
                    autoComplete="off"
                  />
                  <MuiAutocomplete
                    options={Object.keys(outcomeTraineeTypeDropDown) || []}
                    getOptionLabel={(option) =>
                      t(outcomeTraineeTypeDropDown[option])
                    }
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label={`${t(
                          "outcomes.create.outcomeForm.traineeType"
                        )}`}
                        variant="outlined"
                        required
                      />
                    )}
                    name="traineeType"
                    value={formik.values.traineeType}
                    className={classes.singleFieldStyle}
                    clearOnEscape={true}
                    closeIcon={false}
                    onBlur={formik.handleBlur}
                    onChange={(e, value) => {
                      formik.setFieldValue("traineeType", value);
                    }}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <label>{t("outcomes.create.outcomeForm.degrees")}</label>
                    <DegreesSection
                      name="terminalDegrees"
                      categoryName="terminal"
                      formik={formik}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      yearOptions={yearOptions}
                      getDegreeDropdownOptions={
                        getTerminalDegreeDropdownOptions
                      }
                      isFullDegree={isFullTerminalDegree}
                      degreeTypeEnums={OutcomeTerminalDegreeTypeEnums}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiAutocomplete
                    name="submissionOutcomeFaculty"
                    className={classes.singleFieldStyle}
                    multiple
                    value={formik.values.submissionOutcomeFaculty}
                    options={[
                      ...facultyList,
                      ...formik.values.submissionOutcomeFaculty,
                    ]}
                    filterSelectedOptions={true}
                    filterOptions={(option, state) => option}
                    getOptionLabel={(option) =>
                      `${option.displayText || option.fullName}${
                        option.value?.sunetId || option.sunetId
                          ? ` (${option.value?.sunetId || option.sunetId})`
                          : ""
                      }`
                    }
                    getOptionSelected={(option, value) =>
                      (parseInt(option.univId) ||
                        parseInt(option.value?.universityId)) ===
                      (parseInt(value.univId) ||
                        parseInt(value.value?.universityId))
                    }
                    noOptionsText={noOptionsText}
                    onChange={(event, value) => {
                      formik.setFieldValue("submissionOutcomeFaculty", value);
                      setFacultyList([]);
                      setNoOptionsText(t("globals.autocomplete.helperText"));
                    }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label={t("outcomes.create.outcomeForm.mentor")}
                        onChange={(event, value) => {
                          handleFacultyAutocompleteChange(event);
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(option, { inputValue }) => (
                      <AutocompleteOption
                        option={option}
                        inputValue={inputValue}
                      />
                    )}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiKeyboardDatePicker
                      maxDate={
                        parseISO(formik.values.trainingEndDate) || undefined
                      }
                      inputVariant="outlined"
                      label={`${t(
                        "outcomes.edit.outcomeForm.trainingStartDate"
                      )}`}
                      name="trainingStartDate"
                      inputProps={{ autoComplete: "off" }}
                      invalidDateMessage={`${t(
                        "outcomes.edit.outcomeForm.invalidDateFormat"
                      )}`}
                      maxDateMessage={`${t(
                        "outcomes.edit.outcomeForm.maxDateMessage"
                      )}`}
                      clearable
                      format="MM/dd/yyyy"
                      margin="normal"
                      className={`${classes.singleFieldStyle} ${classes.marginZero}`}
                      id="date-picker-inline-start-from"
                      value={
                        formik.values.trainingStartDate &&
                        parseISO(formik.values.trainingStartDate)
                      }
                      onChange={(date) => {
                        formik.setFieldValue(
                          "trainingStartDate",
                          isDateValid(date)
                            ? format(new Date(date), "yyyy-MM-dd")
                            : date
                        );
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiKeyboardDatePicker
                      inputVariant="outlined"
                      label={`${t(
                        "outcomes.edit.outcomeForm.trainingEndDate"
                      )}`}
                      name="trainingEndDate"
                      inputProps={{ autoComplete: "off" }}
                      invalidDateMessage={`${t(
                        "outcomes.edit.outcomeForm.invalidDateFormat"
                      )}`}
                      clearable
                      format="MM/dd/yyyy"
                      margin="normal"
                      className={`${classes.singleFieldStyle} ${classes.marginZero}`}
                      id="date-picker-inline-start-from"
                      minDateMessage={`${t(
                        "outcomes.edit.outcomeForm.minDateMessage"
                      )}`}
                      minDate={
                        parseISO(formik.values.trainingStartDate) || undefined
                      }
                      value={
                        formik.values.trainingEndDate &&
                        parseISO(formik.values.trainingEndDate)
                      }
                      onChange={(date) => {
                        formik.setFieldValue(
                          "trainingEndDate",
                          isDateValid(date)
                            ? format(new Date(date), "yyyy-MM-dd")
                            : date
                        );
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <label>
                    {t("outcomes.edit.outcomeForm.trainingSupportSummary")}
                  </label>
                  <TrainingSupportYearsSection
                    formik={formik}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <label>
                      {t("outcomes.create.outcomeForm.resultingDegree")}
                    </label>
                    <DegreesSection
                      name="resultingDegrees"
                      categoryName="resulting"
                      formik={formik}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      yearOptions={yearOptions}
                      getDegreeDropdownOptions={
                        getResultingDegreeDropdownOptions
                      }
                      isFullDegree={isFullResultingDegree}
                      degreeTypeEnums={OutcomeResultingDegreeTypeEnums}
                      isDegreeOptionFreeSolo={true}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiTextField
                    name="researchTopic"
                    label={t("outcomes.create.outcomeForm.researchTopic")}
                    value={formik.values.researchTopic}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    inputProps={{ maxLength: 150 }}
                    className={classes.singleFieldStyle}
                    autoComplete="off"
                  />
                  <MuiGrid
                    container
                    className={classes.summaryStyle}
                    spacing={2}
                  >
                    <PositionSummarySection
                      categoryName="initial"
                      formik={formik}
                    />
                    <PositionSummarySection
                      categoryName="current"
                      formik={formik}
                    />
                  </MuiGrid>
                  <label>{t("outcomes.create.outcomeForm.subsGrant")}</label>
                  <SubsequentGrantsSection
                    formik={formik}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    yearOptions={yearOptions}
                  />
                  <div className={classes.actionFlexStyled}>
                    <FormButton
                      onClick={onCancelHandler}
                      name={t("globals.form.actionButtons.cancel")}
                      variant="outlined"
                    />

                    <FormButton
                      name={t("globals.form.actionButtons.save")}
                      type="submit"
                      variant="contained"
                      disabled={
                        isEditing ||
                        formik.isSubmitting ||
                        !formik.dirty ||
                        !formik.isValid ||
                        checkIfValidDates(
                          formik.values.trainingStartDate,
                          formik.values.trainingEndDate
                        )
                      }
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    ) : (
      <AccessDenied />
    ))
  );
};

const DegreesSection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    name,
    categoryName,
    formik,
    isEditing,
    setIsEditing,
    yearOptions,
    getDegreeDropdownOptions,
    isFullDegree,
    degreeTypeEnums,
    isDegreeOptionFreeSolo,
  } = props;

  const [degreeEditingAtIndex, setDegreeEditingAtIndex] = useState(-1);

  const DegreeValidation = Yup.object().shape({
    degree: Yup.string().required("Required"),
    degreeYear: Yup.string().when("degree", {
      is: (val) => isFullDegree(val),
      then: Yup.string().required("Required"),
    }),
  });

  const isDegreeValid = (degree, degreeYear) => {
    try {
      DegreeValidation.validateSync({
        degree: degree,
        degreeYear: degreeYear,
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const canAddDegree = (currentDegrees) => {
    return getDegreeDropdownOptions(currentDegrees).length > 0;
  };

  const handleDegreeOptionSelect = (
    e,
    value,
    formField,
    formProp,
    degreeYearFieldName
  ) => {
    if (!isFullDegree(value)) {
      formProp.setFieldValue(degreeYearFieldName, "");
    }
    formProp.setFieldValue(formField, value);
  };

  const handleDegreeOptionInputChange = (e, formField, formProp) => {
    formProp.setFieldValue(formField, e.target.value);
  };

  const handleAddDegree = (
    formProp,
    arrayHelpers,
    degreeFieldName,
    degreeYearFieldName
  ) => {
    const degreeCode = formProp.values[degreeFieldName];

    if (degreeCode) {
      const newDegree = {
        degreeCode: degreeCode,
        degreeYear: formProp.values[degreeYearFieldName],
        key: nanoid(),
      };

      arrayHelpers.push(newDegree);

      formProp.setFieldValue(degreeFieldName, "");
      formProp.setFieldValue(degreeYearFieldName, "");
    }
  };

  const handleRemoveDegree = (formProp, arrayHelpers, arrayIndex) => {
    arrayHelpers.remove(arrayIndex);
  };

  const onEditDegree = (degreeCode, formProp, arrayHelpers, arrayIndex) => {
    formProp.setFieldValue(
      "degreeCodeInEdit",
      formProp.values[name][arrayIndex].degreeCode || ""
    );

    formProp.setFieldValue(
      "degreeYearInEdit",
      formProp.values[name][arrayIndex].degreeYear || ""
    );
    setDegreeEditingAtIndex(arrayIndex);
    setIsEditing(true);
  };

  const onSaveDegree = (degreeCode, formProp, arrayHelpers, arrayIndex) => {
    if (degreeEditingAtIndex === arrayIndex) {
      // confirming change
      const newDegreeYear = formProp.values.degreeYearInEdit;
      const degreeBefore = formProp.values[name][arrayIndex];

      const degreeToReplace = {
        ...degreeBefore,
        degreeCode: degreeCode,
        degreeYear: newDegreeYear,
      };

      arrayHelpers.replace(arrayIndex, degreeToReplace);

      setDegreeEditingAtIndex(-1);
      setIsEditing(false);
    }
  };

  const onCancelDegreeEdit = () => {
    setDegreeEditingAtIndex(-1);
    setIsEditing(false);
  };

  const buildDegreeYearLabel = (degree) => {
    const isRequired = isFullDegree(degree) ? true : false;

    const result =
      t("outcomes.create.outcomeForm.year") + (isRequired ? "*" : "");
    return result;
  };

  const initialValues = {
    [`${categoryName}Degree`]: "",
    [`${categoryName}DegreeYear`]: "",
  };

  return (
    <FieldArray name={name}>
      {(arrayHelpers) => (
        <>
          <Formik initialValues={initialValues}>
            {(subFormik) => {
              return (
                <MuiGrid
                  container
                  className={classes.singleFieldStyle}
                  spacing={1}
                >
                  <MuiGrid item md={7}>
                    <MuiAutocomplete
                      options={getDegreeDropdownOptions(formik.values[name])}
                      disabled={!canAddDegree(formik.values[name])}
                      getOptionLabel={(option) =>
                        t(degreeTypeEnums[option])
                          ? t(degreeTypeEnums[option])
                          : option
                      }
                      name={`${categoryName}Degree`}
                      value={subFormik.values[`${categoryName}Degree`]}
                      getOptionSelected={(option, value) =>
                        t(degreeTypeEnums[option]).toUpperCase() ===
                          value.toUpperCase() || option === value.toUpperCase()
                      }
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={`${t("outcomes.create.outcomeForm.degree")}*`}
                          variant="outlined"
                          onChange={(e) => {
                            isDegreeOptionFreeSolo
                              ? handleDegreeOptionInputChange(
                                  e,
                                  `${categoryName}Degree`,
                                  subFormik
                                )
                              : subFormik.handleChange(e);
                          }}
                        />
                      )}
                      freeSolo={isDegreeOptionFreeSolo || false}
                      onBlur={subFormik.handleBlur}
                      clearOnEscape={true}
                      closeIcon={false}
                      onChange={(e, value) => {
                        handleDegreeOptionSelect(
                          e,
                          value,
                          `${categoryName}Degree`,
                          subFormik,
                          `${categoryName}DegreeYear`
                        );
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item md={4}>
                    <MuiTextField
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      name={`${categoryName}DegreeYear`}
                      disabled={
                        !canAddDegree(formik.values[name]) ||
                        !isFullDegree(subFormik.values[`${categoryName}Degree`])
                      }
                      label={buildDegreeYearLabel(
                        subFormik.values[`${categoryName}Degree`]
                      )}
                      value={subFormik.values[`${categoryName}DegreeYear`]}
                      onChange={subFormik.handleChange}
                      variant="outlined"
                      fullWidth
                    >
                      {yearOptions.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </MuiGrid>

                  <MuiGrid item md={1}>
                    <MuiTooltip
                      title={t("globals.list.actionIcons.add.tooltip")}
                    >
                      <span>
                        <MuiIconButton
                          aria-label="add"
                          disabled={
                            !isDegreeValid(
                              subFormik.values[`${categoryName}Degree`],
                              subFormik.values[`${categoryName}DegreeYear`]
                            ) ||
                            isEditing ||
                            !canAddDegree(formik.values[name])
                          }
                          onClick={() => {
                            handleAddDegree(
                              subFormik,
                              arrayHelpers,
                              `${categoryName}Degree`,
                              `${categoryName}DegreeYear`
                            );
                          }}
                        >
                          <MuiAddCircleIcon />
                        </MuiIconButton>
                      </span>
                    </MuiTooltip>
                  </MuiGrid>
                </MuiGrid>
              );
            }}
          </Formik>

          <div className={classes.singleFieldStyle}>
            <MuiTableContainer
              className={`${classes.marginZero}`}
              component={MuiPaper}
            >
              <MuiTable aria-label="customized table">
                <MuiTableHead className={classes.thStyle}>
                  <MuiTableRow>
                    <MuiTableCell align="left" width="40%">
                      Degree
                    </MuiTableCell>
                    <MuiTableCell align="left" width="40%">
                      Year
                    </MuiTableCell>
                    <MuiTableCell align="left" width="20%">
                      Actions
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {formik.values[name] && formik.values[name].length > 0 ? (
                    formik.values[name].map((degree, index) => (
                      <MuiTableRow key={degree.key}>
                        <MuiTableCell
                          align="left"
                          className={classes.cellEditPadding}
                          width="40%"
                        >
                          {degreeEditingAtIndex !== index ? (
                            <div className={classes.cellContainerPadding}>
                              {t(degreeTypeEnums[degree.degreeCode]) ||
                                degree.degreeCode}
                            </div>
                          ) : (
                            <MuiAutocomplete
                              name="degreeCodeInEdit"
                              value={formik.values.degreeCodeInEdit}
                              options={getDegreeDropdownOptions(
                                formik.values[name]
                              )}
                              getOptionLabel={(option) =>
                                t(degreeTypeEnums[option])
                                  ? t(degreeTypeEnums[option])
                                  : option
                              }
                              renderInput={(params) => (
                                <MuiTextField
                                  {...params}
                                  label={`${t(
                                    "outcomes.create.outcomeForm.degree"
                                  )}*`}
                                  variant="outlined"
                                  onChange={(e) => {
                                    isDegreeOptionFreeSolo
                                      ? handleDegreeOptionInputChange(
                                          e,
                                          "degreeCodeInEdit",
                                          formik
                                        )
                                      : formik.handleChange(e);
                                  }}
                                />
                              )}
                              freeSolo={isDegreeOptionFreeSolo || false}
                              onBlur={formik.handleBlur}
                              clearOnEscape={true}
                              closeIcon={false}
                              onChange={(e, value) => {
                                handleDegreeOptionSelect(
                                  e,
                                  value,
                                  "degreeCodeInEdit",
                                  formik,
                                  "degreeYearInEdit"
                                );
                              }}
                            />
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          align="left"
                          className={classes.cellEditPadding}
                          width="40%"
                        >
                          {degreeEditingAtIndex !== index ? (
                            <div className={classes.cellContainerPadding}>
                              {degree.degreeYear}
                            </div>
                          ) : (
                            <MuiTextField
                              name={"degreeYearInEdit"}
                              label={buildDegreeYearLabel(
                                formik.values.degreeCodeInEdit
                              )}
                              select
                              SelectProps={{
                                displayEmpty: true,
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.degreeYearInEdit}
                              variant="outlined"
                              fullWidth
                            >
                              {yearOptions.map((option) => (
                                <MuiMenuItem key={option} value={option}>
                                  {option}
                                </MuiMenuItem>
                              ))}
                            </MuiTextField>
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          align="left"
                          className={classes.cellEditPadding}
                          width="20%"
                        >
                          {degreeEditingAtIndex !== index ? (
                            <span>
                              {isFullDegree(degree.degreeCode) && (
                                <MuiTooltip
                                  title={t(
                                    "globals.list.actionIcons.edit.tooltip"
                                  )}
                                >
                                  <span>
                                    <MuiIconButton
                                      area-label="edit"
                                      disabled={isEditing}
                                      onClick={() =>
                                        onEditDegree(
                                          degree.degreeCode,
                                          formik,
                                          arrayHelpers,
                                          index
                                        )
                                      }
                                    >
                                      <MuiEditIcon />
                                    </MuiIconButton>
                                  </span>
                                </MuiTooltip>
                              )}
                              {formik.values[name].length === index + 1 && (
                                <MuiTooltip
                                  title={t(
                                    "globals.list.actionIcons.remove.tooltip"
                                  )}
                                >
                                  <span>
                                    <MuiIconButton
                                      disabled={isEditing}
                                      onClick={() => {
                                        handleRemoveDegree(
                                          formik,
                                          arrayHelpers,
                                          index
                                        );
                                      }}
                                    >
                                      <MuiRemoveIcon />
                                    </MuiIconButton>
                                  </span>
                                </MuiTooltip>
                              )}
                            </span>
                          ) : (
                            <span>
                              <MuiTooltip
                                title={t(
                                  "globals.list.actionIcons.done.tooltip"
                                )}
                              >
                                <span>
                                  <MuiIconButton
                                    disabled={
                                      !isDegreeValid(
                                        formik.values.degreeCodeInEdit,
                                        formik.values.degreeYearInEdit
                                      )
                                    }
                                    onClick={() =>
                                      onSaveDegree(
                                        formik.values.degreeCodeInEdit,
                                        formik,
                                        arrayHelpers,
                                        index
                                      )
                                    }
                                  >
                                    <MuiDoneIcon />
                                  </MuiIconButton>
                                </span>
                              </MuiTooltip>
                              <MuiTooltip
                                title={t(
                                  "globals.list.actionIcons.cancel.tooltip"
                                )}
                              >
                                <MuiIconButton
                                  onClick={() => onCancelDegreeEdit()}
                                >
                                  <MuiCancelIcon />
                                </MuiIconButton>
                              </MuiTooltip>
                            </span>
                          )}
                        </MuiTableCell>
                      </MuiTableRow>
                    ))
                  ) : (
                    <MuiTableRow className="noData">
                      <MuiTableCell
                        colSpan="3"
                        align="center"
                        classes={{
                          body: classes.noDataTableCell,
                        }}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTableCell>
                    </MuiTableRow>
                  )}
                </MuiTableBody>
              </MuiTable>
            </MuiTableContainer>
          </div>
        </>
      )}
    </FieldArray>
  );
};

const TrainingSupportYearsSection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { formik, isEditing, setIsEditing } = props;

  const [
    trainingSupportYearEditingAtIndex,
    setTrainingSupportYearEditingAtIndex,
  ] = useState(-1);

  const TrainigSupportYearValidation = Yup.object().shape({
    trainingType: Yup.string().required("Required"),
    trainingSource: Yup.string().when("trainingType", {
      is: (val) => val !== "NIH_HHS",
      then: Yup.string().required("Required"),
    }),
    nihHhsComponentAndActivity: Yup.string().when("trainingType", {
      is: "NIH_HHS",
      then: Yup.string().required("Required").min(1),
    }),
  });

  const isTrainingSupportYearValid = (
    trainingType,
    trainingSource,
    nihHhsComponentAndActivity
  ) => {
    try {
      const trainingSupportYear = {
        trainingType: trainingType,
        trainingSource: trainingSource,
        nihHhsComponentAndActivity: nihHhsComponentAndActivity,
      };

      TrainigSupportYearValidation.validateSync(trainingSupportYear);
      return true;
    } catch (error) {
      return false;
    }
  };

  const isTrainingSupportYearLimitReached = (currentTrainingSupportYears) => {
    return currentTrainingSupportYears.length >= TrainingSupportYearMaxCount;
  };

  const onEditTrainingSupportYear = (formProp, arrayHelpers, arrayIndex) => {
    formProp.setFieldValue(
      "trainingTypeInEdit",
      formProp.values.trainingSupportYears[arrayIndex].trainingTypeCode
    );
    formProp.setFieldValue(
      "trainingSourceInEdit",
      formProp.values.trainingSupportYears[arrayIndex].trainingSourceCode || ""
    );
    formProp.setFieldValue(
      "nihHhsComponentAndActivityInEdit",
      formProp.values.trainingSupportYears[arrayIndex]
        .nihHhsComponentAndActivity || ""
    );
    setTrainingSupportYearEditingAtIndex(arrayIndex);
    setIsEditing(true);
  };

  const onSaveTrainingSupportYear = (formProp, arrayHelpers, arrayIndex) => {
    if (trainingSupportYearEditingAtIndex === arrayIndex) {
      // confirming change
      const trainingSupportYearBefore =
        formProp.values.trainingSupportYears[arrayIndex];

      const trainingTypeCode = formProp.values.trainingTypeInEdit;

      const trainingSupportYearToReplace = {
        ...trainingSupportYearBefore,
        trainingTypeCode: trainingTypeCode,
        trainingSourceCode:
          trainingTypeCode === "NIH_HHS"
            ? ""
            : formProp.values.trainingSourceInEdit,
        nihHhsComponentAndActivity:
          trainingTypeCode === "NIH_HHS"
            ? formProp.values.nihHhsComponentAndActivityInEdit
            : "",
      };

      arrayHelpers.replace(arrayIndex, trainingSupportYearToReplace);

      setTrainingSupportYearEditingAtIndex(-1);
      setIsEditing(false);
    }
  };

  const onCancelTrainingSupportYearEdit = () => {
    setTrainingSupportYearEditingAtIndex(-1);
    setIsEditing(false);
  };

  const handleTrainingTypeOptionSelect = (e, value, formField, formProp) => {
    formProp.setFieldValue(formField, value);
  };

  const handleTrainingSourceOptionSelect = (e, value, formField, formProp) => {
    formProp.setFieldValue(formField, value);
  };

  const handleNihHhsComponentAndActivityChange = (
    e,
    value,
    formField,
    formProp
  ) => {
    const oldValue = formProp.values[formField];

    if (oldValue && oldValue.trim() === value) {
      formProp.setFieldValue(formField, value);
    } else {
      const newValue = value ? value.toUpperCase() : value;
      formProp.setFieldValue(formField, newValue);
    }
  };

  const handleAddTrainingSupportYear = (formProp, arrayHelpers) => {
    const trainingTypeCode = formProp.values.trainingType;

    if (trainingTypeCode) {
      const newTrainingSupportYear = {
        trainingTypeCode: formProp.values.trainingType,
        trainingSourceCode:
          trainingTypeCode === "NIH_HHS" ? "" : formProp.values.trainingSource,
        nihHhsComponentAndActivity:
          trainingTypeCode === "NIH_HHS"
            ? formProp.values.nihHhsComponentAndActivity
            : "",
        key: nanoid(),
      };

      arrayHelpers.push(newTrainingSupportYear);

      formProp.setFieldValue("trainingType", "");
      formProp.setFieldValue("trainingSource", "");
      formProp.setFieldValue("nihHhsComponentAndActivity", "");
    }
  };

  const handleRemoveTrainingSupportYear = (
    formProp,
    arrayHelpers,
    arrayIndex
  ) => {
    arrayHelpers.remove(arrayIndex);
  };

  const buildTrainingYearName = (yearNumber) => {
    return t("outcomes.trainingYearDisplay", {
      trainingYearNumber: yearNumber,
    });
  };

  const buildTrainingTypeName = (traineeType, trainingTypeCode) => {
    if (traineeType === "PRE_DOC") {
      return Object.keys(OutcomePreDocTrainingSupportTypeEnums).includes(
        trainingTypeCode
      )
        ? t(
            `${OutcomePreDocTrainingSupportTypeEnums[trainingTypeCode]}.longName`
          )
        : "";
    } else {
      return Object.keys(OutcomePostDocTrainingSupportTypeEnums).includes(
        trainingTypeCode
      )
        ? t(
            `${OutcomePostDocTrainingSupportTypeEnums[trainingTypeCode]}.longName`
          )
        : "";
    }
  };

  const buildTrainingSourceName = (trainingSourceCode) => {
    return (
      (trainingSourceCode &&
        t(
          `${OutcomeTrainingSupportSourceEnums[trainingSourceCode]}.longName`
        )) ||
      ""
    );
  };

  const buildTrainingSourceLabel = (trainingType) => {
    const isRequired = !["", "NIH_HHS"].includes(trainingType);

    const result =
      t("outcomes.edit.outcomeForm.trainingSource") + (isRequired ? "*" : "");

    return result;
  };

  const buildNihHhsComponentAndActivityLabel = (trainingType) => {
    const isRequired = trainingType === "NIH_HHS" ? true : false;

    const result =
      t("outcomes.edit.outcomeForm.trainingYearNihHhsComponentAndActivity") +
      (isRequired ? "*" : "");

    return result;
  };

  const initialValues = {
    trainingYear: "",
    trainingType: "",
    trainingSource: "",
    nihHhsComponentAndActivity: "",
  };

  return (
    <FieldArray name="trainingSupportYears">
      {(arrayHelpers) => (
        <>
          <Formik initialValues={initialValues}>
            {(subFormik) => {
              return (
                <MuiGrid container spacing={1}>
                  <MuiGrid item md={2}>
                    <MuiTextField
                      name="trainingYear"
                      label={`${t("outcomes.edit.outcomeForm.trainingYear")}`}
                      value={buildTrainingYearName(
                        formik.values.trainingSupportYears.length +
                          (formik.values.trainingSupportYears.length <
                          TrainingSupportYearMaxCount
                            ? 1
                            : 0)
                      )}
                      disabled={true}
                      className={classes.disabled}
                      fullWidth
                    />
                  </MuiGrid>
                  <MuiGrid item md={3}>
                    <MuiAutocomplete
                      name="trainingType"
                      options={
                        formik.values.traineeType === "PRE_DOC"
                          ? Object.keys(OutcomePreDocTrainingSupportTypeEnums)
                          : Object.keys(OutcomePostDocTrainingSupportTypeEnums)
                      }
                      getOptionLabel={(option) =>
                        (option &&
                          buildTrainingTypeName(
                            formik.values.traineeType,
                            option
                          )) ||
                        ""
                      }
                      disabled={isTrainingSupportYearLimitReached(
                        formik.values.trainingSupportYears
                      )}
                      value={subFormik.values.trainingType}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={`${t(
                            "outcomes.edit.outcomeForm.trainingType"
                          )}*`}
                          variant="outlined"
                          autoComplete="false"
                        />
                      )}
                      clearOnEscape={true}
                      closeIcon={false}
                      clearOnBlur={true}
                      onChange={(e, value) => {
                        handleTrainingTypeOptionSelect(
                          e,
                          value,
                          "trainingType",
                          subFormik
                        );
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item md={3}>
                    <MuiAutocomplete
                      name="trainingSource"
                      options={
                        Object.keys(OutcomeTrainingSupportSourceEnums) || []
                      }
                      disabled={
                        ["", "NIH_HHS"].includes(
                          subFormik.values.trainingType
                        ) ||
                        isTrainingSupportYearLimitReached(
                          formik.values.trainingSupportYears
                        )
                      }
                      getOptionLabel={(option) =>
                        buildTrainingSourceName(option)
                      }
                      value={subFormik.values.trainingSource}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={buildTrainingSourceLabel(
                            subFormik.values.trainingType
                          )}
                          variant="outlined"
                        />
                      )}
                      clearOnEscape={true}
                      closeIcon={false}
                      clearOnBlur={true}
                      onChange={(e, value) => {
                        handleTrainingSourceOptionSelect(
                          e,
                          value,
                          "trainingSource",
                          subFormik
                        );
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item md={3}>
                    <MuiTextField
                      name="nihHhsComponentAndActivity"
                      label={buildNihHhsComponentAndActivityLabel(
                        subFormik.values.trainingType
                      )}
                      disabled={
                        subFormik.values.trainingType !== "NIH_HHS" ||
                        isTrainingSupportYearLimitReached(
                          formik.values.trainingSupportYears
                        )
                      }
                      value={subFormik.values.nihHhsComponentAndActivity}
                      inputProps={{ maxLength: 30 }}
                      helperText={`${t(
                        "outcomes.edit.outcomeForm.helperText.nihHhsComponentAndActivity"
                      )}`}
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        handleNihHhsComponentAndActivityChange(
                          e,
                          e.target.value,
                          "nihHhsComponentAndActivity",
                          subFormik
                        );
                      }}
                    />
                  </MuiGrid>

                  <MuiGrid item md={1}>
                    <MuiTooltip
                      title={t("globals.list.actionIcons.add.tooltip")}
                    >
                      <span>
                        <MuiIconButton
                          aria-label="add"
                          disabled={
                            (subFormik.values.trainingType || "") === "" ||
                            !isTrainingSupportYearValid(
                              subFormik.values.trainingType,
                              subFormik.values.trainingSource,
                              subFormik.values.nihHhsComponentAndActivity
                            ) ||
                            isEditing ||
                            isTrainingSupportYearLimitReached(
                              formik.values.trainingSupportYears
                            )
                          }
                          onClick={() => {
                            handleAddTrainingSupportYear(
                              subFormik,
                              arrayHelpers
                            );
                          }}
                        >
                          <MuiAddCircleIcon />
                        </MuiIconButton>
                      </span>
                    </MuiTooltip>
                  </MuiGrid>
                </MuiGrid>
              );
            }}
          </Formik>

          <div className={classes.summaryFieldStyle}>
            <MuiTableContainer
              className={`${classes.marginZero}`}
              component={MuiPaper}
            >
              <MuiTable aria-label="customized table">
                <MuiTableHead className={classes.thStyle}>
                  <MuiTableRow>
                    <MuiTableCell align="left" width="10%">
                      Training Year
                    </MuiTableCell>
                    <MuiTableCell align="left" width="30%">
                      Training Type
                    </MuiTableCell>
                    <MuiTableCell align="left" width="30%">
                      Training Source
                    </MuiTableCell>
                    <MuiTableCell align="left" width="20%">
                      NIH/HHS Component & Activity
                    </MuiTableCell>
                    <MuiTableCell align="left" width="10%">
                      Actions
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {formik.values.trainingSupportYears.length > 0 ? (
                    formik.values.trainingSupportYears.map(
                      (trainingYearEntry, index) => (
                        <MuiTableRow key={trainingYearEntry.key}>
                          <MuiTableCell
                            align="left"
                            className={classes.cellEditPadding}
                            width="10%"
                          >
                            <div className={classes.cellContainerPadding}>
                              {buildTrainingYearName(index + 1)}
                            </div>
                          </MuiTableCell>
                          {trainingSupportYearEditingAtIndex !== index ? (
                            <>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="30%"
                              >
                                <div className={classes.cellContainerPadding}>
                                  {getTrainingTypeNameByTrainingTypeCode(
                                    trainingYearEntry.trainingTypeCode,
                                    "longName"
                                  )}
                                </div>
                              </MuiTableCell>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="30%"
                              >
                                <div className={classes.cellContainerPadding}>
                                  {buildTrainingSourceName(
                                    trainingYearEntry.trainingSourceCode
                                  )}
                                </div>
                              </MuiTableCell>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="20%"
                              >
                                <div className={classes.cellContainerPadding}>
                                  {trainingYearEntry.nihHhsComponentAndActivity ||
                                    ""}
                                </div>
                              </MuiTableCell>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="10%"
                              >
                                <span>
                                  <MuiTooltip
                                    title={t(
                                      "globals.list.actionIcons.edit.tooltip"
                                    )}
                                  >
                                    <span>
                                      <MuiIconButton
                                        area-label="edit"
                                        disabled={isEditing}
                                        onClick={() =>
                                          onEditTrainingSupportYear(
                                            formik,
                                            arrayHelpers,
                                            index
                                          )
                                        }
                                      >
                                        <MuiEditIcon />
                                      </MuiIconButton>
                                    </span>
                                  </MuiTooltip>
                                  {formik.values.trainingSupportYears.length ===
                                    index + 1 && (
                                    <MuiTooltip
                                      title={t(
                                        "globals.list.actionIcons.remove.tooltip"
                                      )}
                                    >
                                      <MuiIconButton
                                        onClick={() => {
                                          handleRemoveTrainingSupportYear(
                                            formik,
                                            arrayHelpers,
                                            index
                                          );
                                        }}
                                      >
                                        <MuiRemoveIcon />
                                      </MuiIconButton>
                                    </MuiTooltip>
                                  )}
                                </span>
                              </MuiTableCell>
                            </>
                          ) : (
                            <>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="30%"
                              >
                                <MuiAutocomplete
                                  name="trainingTypeInEdit"
                                  options={
                                    formik.values.traineeType === "PRE_DOC"
                                      ? Object.keys(
                                          OutcomePreDocTrainingSupportTypeEnums
                                        )
                                      : Object.keys(
                                          OutcomePostDocTrainingSupportTypeEnums
                                        )
                                  }
                                  getOptionLabel={(option) =>
                                    (option &&
                                      buildTrainingTypeName(
                                        formik.values.traineeType,
                                        option
                                      )) ||
                                    ""
                                  }
                                  value={formik.values.trainingTypeInEdit}
                                  renderInput={(params) => (
                                    <MuiTextField
                                      {...params}
                                      label={`${t(
                                        "outcomes.edit.outcomeForm.trainingType"
                                      )}*`}
                                      variant="outlined"
                                      autoComplete="false"
                                    />
                                  )}
                                  clearOnEscape={true}
                                  closeIcon={false}
                                  clearOnBlur={true}
                                  onChange={(e, value) => {
                                    handleTrainingTypeOptionSelect(
                                      e,
                                      value,
                                      "trainingTypeInEdit",
                                      formik
                                    );
                                  }}
                                />
                              </MuiTableCell>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="30%"
                              >
                                <MuiAutocomplete
                                  name="trainingSourceInEdit"
                                  options={
                                    Object.keys(
                                      OutcomeTrainingSupportSourceEnums
                                    ) || []
                                  }
                                  disabled={["", "NIH_HHS"].includes(
                                    formik.values.trainingTypeInEdit
                                  )}
                                  getOptionLabel={(option) =>
                                    buildTrainingSourceName(option)
                                  }
                                  value={formik.values.trainingSourceInEdit}
                                  renderInput={(params) => (
                                    <MuiTextField
                                      {...params}
                                      label={buildTrainingSourceLabel(
                                        formik.values.trainingTypeInEdit
                                      )}
                                      variant="outlined"
                                    />
                                  )}
                                  clearOnEscape={true}
                                  closeIcon={false}
                                  clearOnBlur={true}
                                  onChange={(e, value) => {
                                    handleTrainingSourceOptionSelect(
                                      e,
                                      value,
                                      "trainingSourceInEdit",
                                      formik
                                    );
                                  }}
                                />
                              </MuiTableCell>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="20%"
                              >
                                <MuiTextField
                                  name="nihHhsComponentAndActivityInEdit"
                                  label={buildNihHhsComponentAndActivityLabel(
                                    formik.values.trainingTypeInEdit
                                  )}
                                  disabled={
                                    formik.values.trainingTypeInEdit !==
                                    "NIH_HHS"
                                  }
                                  value={
                                    formik.values
                                      .nihHhsComponentAndActivityInEdit
                                  }
                                  inputProps={{ maxLength: 30 }}
                                  variant="outlined"
                                  fullWidth
                                  autoComplete="off"
                                  onChange={(e) => {
                                    handleNihHhsComponentAndActivityChange(
                                      e,
                                      e.target.value,
                                      "nihHhsComponentAndActivityInEdit",
                                      formik
                                    );
                                  }}
                                />
                              </MuiTableCell>
                              <MuiTableCell
                                align="left"
                                className={classes.cellEditPadding}
                                width="10%"
                              >
                                <span>
                                  <MuiTooltip
                                    title={t(
                                      "globals.list.actionIcons.done.tooltip"
                                    )}
                                  >
                                    <span>
                                      <MuiIconButton
                                        disabled={
                                          (formik.values.trainingTypeInEdit ||
                                            "") === "" ||
                                          !isTrainingSupportYearValid(
                                            formik.values.trainingTypeInEdit,
                                            formik.values.trainingSourceInEdit,
                                            formik.values
                                              .nihHhsComponentAndActivityInEdit
                                          )
                                        }
                                        onClick={() =>
                                          onSaveTrainingSupportYear(
                                            formik,
                                            arrayHelpers,
                                            index
                                          )
                                        }
                                      >
                                        <MuiDoneIcon />
                                      </MuiIconButton>
                                    </span>
                                  </MuiTooltip>
                                  <MuiTooltip
                                    title={t(
                                      "globals.list.actionIcons.cancel.tooltip"
                                    )}
                                  >
                                    <MuiIconButton
                                      onClick={() =>
                                        onCancelTrainingSupportYearEdit()
                                      }
                                    >
                                      <MuiCancelIcon />
                                    </MuiIconButton>
                                  </MuiTooltip>
                                </span>
                              </MuiTableCell>
                            </>
                          )}
                        </MuiTableRow>
                      )
                    )
                  ) : (
                    <MuiTableRow className="noData">
                      <MuiTableCell
                        colSpan="5"
                        align="center"
                        classes={{
                          body: classes.noDataTableCell,
                        }}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTableCell>
                    </MuiTableRow>
                  )}
                </MuiTableBody>
              </MuiTable>
            </MuiTableContainer>
          </div>
        </>
      )}
    </FieldArray>
  );
};

const PositionSummarySection = (props) => {
  const { t } = useTranslation();

  const { categoryName, formik } = props;

  const getPositionSummaryLabel = () => {
    return t(`outcomes.create.outcomeForm.${categoryName}Summary`);
  };

  const isPositionRequired = (formProp) => {
    const result =
      formProp.values[`${categoryName}PositionSummary`].department ||
      formProp.values[`${categoryName}PositionSummary`].institution ||
      formProp.values[`${categoryName}PositionSummary`].activity ||
      formProp.values[`${categoryName}PositionSummary`].workforceSector
        ? true
        : false;
    return result;
  };

  return (
    <MuiGrid item md={6}>
      <label>{getPositionSummaryLabel()}</label>
      <MuiTextField
        name={`${categoryName}PositionSummary.position`}
        label={t("outcomes.create.outcomeForm.position")}
        value={formik.values[`${categoryName}PositionSummary`].position || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        variant="outlined"
        inputProps={{ maxLength: 100 }}
        fullWidth
        autoComplete="off"
        required={isPositionRequired(formik)}
      />
      <MuiTextField
        name={`${categoryName}PositionSummary.department`}
        label={t("outcomes.create.outcomeForm.department")}
        value={formik.values[`${categoryName}PositionSummary`].department || ""}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        variant="outlined"
        inputProps={{ maxLength: 100 }}
        fullWidth
        autoComplete="off"
      />
      <MuiTextField
        name={`${categoryName}PositionSummary.institution`}
        label={t("outcomes.create.outcomeForm.institution")}
        value={
          formik.values[`${categoryName}PositionSummary`].institution || ""
        }
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        variant="outlined"
        inputProps={{ maxLength: 100 }}
        fullWidth
        autoComplete="off"
      />
      <MuiAutocomplete
        name={`${categoryName}PositionSummary.workforceSector`}
        options={Object.keys(OutcomePositionWorkforceSectorTypeEnums) || []}
        getOptionLabel={(option) =>
          t(OutcomePositionWorkforceSectorTypeEnums[option])
        }
        value={
          formik.values[`${categoryName}PositionSummary`].workforceSector ||
          null
        }
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label={t("outcomes.create.outcomeForm.workforceSector")}
            variant="outlined"
          />
        )}
        clearOnEscape={true}
        closeIcon={false}
        onBlur={formik.handleBlur}
        onChange={(e, value) => {
          formik.setFieldValue(
            `${categoryName}PositionSummary.workforceSector`,
            value
          );
        }}
      />
      <MuiAutocomplete
        name={`${categoryName}PositionSummary.activity`}
        options={Object.keys(OutcomePositionActivityTypeEnums) || []}
        getOptionLabel={(option) => t(OutcomePositionActivityTypeEnums[option])}
        value={formik.values[`${categoryName}PositionSummary`].activity || null}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label={t("outcomes.create.outcomeForm.activity")}
            variant="outlined"
          />
        )}
        clearOnEscape={true}
        closeIcon={false}
        onBlur={formik.handleBlur}
        onChange={(e, value) => {
          formik.setFieldValue(
            `${categoryName}PositionSummary.activity`,
            value
          );
        }}
      />
    </MuiGrid>
  );
};

const SubsequentGrantsSection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { formik, isEditing, setIsEditing, yearOptions } = props;

  const [grantEditingAtIndex, setGrantEditingAtIndex] = useState(-1);

  const SubsequentGrantValidation = Yup.object().shape({
    grantName: Yup.string().required("Required").min(1),
    grantRole: Yup.string().required("Required"),
    grantYear: Yup.string().required("Required"),
  });

  const isSubsequentGrantsLimitReached = (currentSubsequentGrants) => {
    return currentSubsequentGrants.length >= SubsequentGrantMaxCount;
  };

  const grantRoleOptions = Object.keys(OutcomeSubsequentGrantRoleEnums).filter(
    (roleCode) => roleCode !== "OTHER_D"
  );

  const isGrantValid = (grantName, grantRole, grantYear) => {
    try {
      const subsequentGrant = {
        grantName: grantName,
        grantRole: grantRole,
        grantYear: grantYear,
      };

      SubsequentGrantValidation.validateSync(subsequentGrant);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleAddGrant = (formProp, arrayHelpers) => {
    const newGrant = {
      grantName: formProp.values.grantName,
      roleCode: formProp.values.grantRole,
      grantYear: formProp.values.grantYear,
      key: nanoid(),
    };

    arrayHelpers.push(newGrant);

    formProp.setFieldValue("grantName", "");
    formProp.setFieldValue("grantRole", "");
    formProp.setFieldValue("grantYear", "");
  };

  const onEditGrant = (formProp, arrayHelpers, arrayIndex) => {
    formProp.setFieldValue(
      "grantNameInEdit",
      formProp.values.subsequentGrants[arrayIndex].grantName || ""
    );
    formProp.setFieldValue(
      "grantRoleInEdit",
      formProp.values.subsequentGrants[arrayIndex].roleCode || ""
    );
    formProp.setFieldValue(
      "grantYearInEdit",
      formProp.values.subsequentGrants[arrayIndex].grantYear || ""
    );

    setGrantEditingAtIndex(arrayIndex);
    setIsEditing(true);
  };

  const handleRemoveGrant = (formProp, arrayHelpers, arrayIndex) => {
    arrayHelpers.remove(arrayIndex);
  };

  const onSaveGrant = (formProp, arrayHelpers, arrayIndex) => {
    if (grantEditingAtIndex === arrayIndex) {
      // confirming change
      const newGrantName = formProp.values.grantNameInEdit;
      const newGrantRole = formProp.values.grantRoleInEdit;
      const newGrantYear = formProp.values.grantYearInEdit;
      const grantBefore = formProp.values.subsequentGrants[arrayIndex];

      const grantToReplace = {
        ...grantBefore,
        grantName: newGrantName,
        roleCode: newGrantRole,
        grantYear: newGrantYear,
      };

      arrayHelpers.replace(arrayIndex, grantToReplace);

      setGrantEditingAtIndex(-1);
      setIsEditing(false);
    }
  };

  const onCancelGrantEdit = () => {
    setGrantEditingAtIndex(-1);
    setIsEditing(false);
  };

  const handleGrantRoleOptionInputChange = (e, formField, formProp) => {
    formProp.setFieldValue(formField, e.target.value);
  };

  const initialValues = {
    grantName: "",
    grantRole: "",
    grantYear: "",
  };

  const handleGrantNameChange = (e, value, formField, formProp) => {
    const oldValue = formProp.values[formField];
    if (oldValue && oldValue.trim() === value) {
      formProp.setFieldValue(formField, value);
    } else {
      const newValue = value ? value.toUpperCase() : value;
      formProp.setFieldValue(formField, newValue);
    }
  };

  return (
    <FieldArray name="subsequentGrants">
      {(arrayHelpers) => (
        <>
          <Formik initialValues={initialValues}>
            {(subFormik) => {
              return (
                <MuiGrid container spacing={1}>
                  <MuiGrid item md={5}>
                    <MuiTextField
                      name="grantName"
                      label={`${t(
                        "outcomes.edit.outcomeForm.trainingYearNihHhsComponentAndActivity"
                      )}*`}
                      value={subFormik.values.grantName}
                      onChange={(e) => {
                        handleGrantNameChange(
                          e,
                          e.target.value,
                          "grantName",
                          subFormik
                        );
                      }}
                      onBlur={subFormik.handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 30 }}
                      fullWidth
                      autoComplete="off"
                      helperText={`${t(
                        "outcomes.edit.outcomeForm.helperText.nihHhsComponentAndActivity"
                      )}`}
                      disabled={isSubsequentGrantsLimitReached(
                        formik.values.subsequentGrants
                      )}
                    />
                  </MuiGrid>
                  <MuiGrid item md={4}>
                    <MuiAutocomplete
                      name="grantRole"
                      options={grantRoleOptions}
                      getOptionLabel={(option) =>
                        t(OutcomeSubsequentGrantRoleEnums[option])
                          ? t(OutcomeSubsequentGrantRoleEnums[option])
                          : option
                      }
                      disabled={isSubsequentGrantsLimitReached(
                        formik.values.subsequentGrants
                      )}
                      value={subFormik.values.grantRole}
                      getOptionSelected={(option, value) =>
                        t(
                          OutcomeSubsequentGrantRoleEnums[option]
                        ).toUpperCase() === value.toUpperCase() ||
                        option === value.toUpperCase()
                      }
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label={`${t("outcomes.create.outcomeForm.role")}*`}
                          variant="outlined"
                          onChange={(e) => {
                            handleGrantRoleOptionInputChange(
                              e,
                              "grantRole",
                              subFormik
                            );
                          }}
                        />
                      )}
                      freeSolo
                      onBlur={subFormik.handleBlur}
                      clearOnEscape={true}
                      closeIcon={false}
                      onChange={(e, value) => {
                        subFormik.setFieldValue("grantRole", value);
                      }}
                    />
                  </MuiGrid>
                  <MuiGrid item md={2}>
                    <MuiTextField
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      name="grantYear"
                      label={`${t("outcomes.create.outcomeForm.year")}*`}
                      value={subFormik.values.grantYear}
                      onChange={subFormik.handleChange}
                      variant="outlined"
                      fullWidth
                      disabled={isSubsequentGrantsLimitReached(
                        formik.values.subsequentGrants
                      )}
                    >
                      {yearOptions.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </MuiGrid>
                  <MuiGrid item md={1}>
                    <MuiTooltip
                      title={t("globals.list.actionIcons.add.tooltip")}
                    >
                      <span>
                        <MuiIconButton
                          aria-label="add"
                          disabled={
                            !isGrantValid(
                              subFormik.values.grantName,
                              subFormik.values.grantRole,
                              subFormik.values.grantYear
                            ) ||
                            isEditing ||
                            isSubsequentGrantsLimitReached(
                              formik.values.subsequentGrants
                            )
                          }
                          onClick={() => {
                            handleAddGrant(subFormik, arrayHelpers);
                          }}
                        >
                          <MuiAddCircleIcon />
                        </MuiIconButton>
                      </span>
                    </MuiTooltip>
                  </MuiGrid>
                </MuiGrid>
              );
            }}
          </Formik>

          <div className={classes.summaryFieldStyle}>
            <MuiTableContainer
              className={`${classes.marginZero}`}
              component={MuiPaper}
            >
              <MuiTable aria-label="customized table">
                <MuiTableHead className={classes.thStyle}>
                  <MuiTableRow>
                    <MuiTableCell align="left" width="20%">
                      Grant
                    </MuiTableCell>
                    <MuiTableCell align="left" width="40%">
                      Role
                    </MuiTableCell>
                    <MuiTableCell align="left" width="20%">
                      Year
                    </MuiTableCell>
                    <MuiTableCell align="left" width="20%">
                      Actions
                    </MuiTableCell>
                  </MuiTableRow>
                </MuiTableHead>
                <MuiTableBody>
                  {formik.values.subsequentGrants.length > 0 ? (
                    formik.values.subsequentGrants.map((grant, index) => (
                      <MuiTableRow key={grant.key}>
                        <MuiTableCell
                          align="left"
                          width="20%"
                          className={classes.cellEditPadding}
                        >
                          {grantEditingAtIndex !== index ? (
                            <div className={classes.cellContainerPadding}>
                              {grant.grantName}
                            </div>
                          ) : (
                            <MuiTextField
                              name="grantNameInEdit"
                              onChange={formik.handleChange}
                              value={formik.values.grantNameInEdit}
                              variant="outlined"
                              fullWidth
                              required
                              inputProps={{ maxLength: 30 }}
                            />
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          align="left"
                          width="40%"
                          className={classes.cellEditPadding}
                        >
                          {grantEditingAtIndex !== index ? (
                            <div className={classes.cellContainerPadding}>
                              {t(
                                OutcomeSubsequentGrantRoleEnums[grant.roleCode]
                              ) || grant.roleCode}
                            </div>
                          ) : (
                            <MuiAutocomplete
                              name="grantRoleInEdit"
                              options={grantRoleOptions}
                              getOptionLabel={(option) =>
                                t(OutcomeSubsequentGrantRoleEnums[option])
                                  ? t(OutcomeSubsequentGrantRoleEnums[option])
                                  : option
                              }
                              value={formik.values.grantRoleInEdit}
                              getOptionSelected={(option, value) =>
                                t(
                                  OutcomeSubsequentGrantRoleEnums[option]
                                ).toUpperCase() === value.toUpperCase() ||
                                option === value.toUpperCase()
                              }
                              renderInput={(params) => (
                                <MuiTextField
                                  {...params}
                                  label={`${t(
                                    "outcomes.create.outcomeForm.role"
                                  )}*`}
                                  variant="outlined"
                                  onChange={(e) => {
                                    handleGrantRoleOptionInputChange(
                                      e,
                                      "grantRoleInEdit",
                                      formik
                                    );
                                  }}
                                />
                              )}
                              freeSolo
                              onBlur={formik.handleBlur}
                              clearOnEscape={true}
                              closeIcon={false}
                              onChange={(e, value) => {
                                formik.setFieldValue("grantRoleInEdit", value);
                              }}
                            />
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          align="left"
                          width="20%"
                          className={classes.cellEditPadding}
                        >
                          {grantEditingAtIndex !== index ? (
                            <div className={classes.cellContainerPadding}>
                              {grant.grantYear}
                            </div>
                          ) : (
                            <MuiTextField
                              name="grantYearInEdit"
                              select
                              SelectProps={{
                                displayEmpty: true,
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.grantYearInEdit}
                              variant="outlined"
                              fullWidth
                              required
                            >
                              {yearOptions.map((option) => (
                                <MuiMenuItem key={option} value={option}>
                                  {option}
                                </MuiMenuItem>
                              ))}
                            </MuiTextField>
                          )}
                        </MuiTableCell>
                        <MuiTableCell
                          align="left"
                          width="20%"
                          className={classes.cellEditPadding}
                        >
                          {grantEditingAtIndex !== index ? (
                            <span>
                              <MuiTooltip
                                title={t(
                                  "globals.list.actionIcons.edit.tooltip"
                                )}
                              >
                                <span>
                                  <MuiIconButton
                                    area-label="edit"
                                    disabled={isEditing}
                                    onClick={() =>
                                      onEditGrant(formik, arrayHelpers, index)
                                    }
                                  >
                                    <MuiEditIcon />
                                  </MuiIconButton>
                                </span>
                              </MuiTooltip>

                              {formik.values.subsequentGrants.length ===
                                index + 1 && (
                                <MuiTooltip
                                  title={t(
                                    "globals.list.actionIcons.remove.tooltip"
                                  )}
                                >
                                  <span>
                                    <MuiIconButton
                                      disabled={isEditing}
                                      onClick={() => {
                                        handleRemoveGrant(
                                          formik,
                                          arrayHelpers,
                                          index
                                        );
                                      }}
                                    >
                                      <MuiRemoveIcon />
                                    </MuiIconButton>
                                  </span>
                                </MuiTooltip>
                              )}
                            </span>
                          ) : (
                            <span>
                              <MuiTooltip
                                title={t(
                                  "globals.list.actionIcons.done.tooltip"
                                )}
                              >
                                <span>
                                  <MuiIconButton
                                    disabled={
                                      !isGrantValid(
                                        formik.values.grantNameInEdit,
                                        formik.values.grantRoleInEdit,
                                        formik.values.grantYearInEdit
                                      )
                                    }
                                    onClick={() =>
                                      onSaveGrant(formik, arrayHelpers, index)
                                    }
                                  >
                                    <MuiDoneIcon />
                                  </MuiIconButton>
                                </span>
                              </MuiTooltip>
                              <MuiTooltip
                                title={t(
                                  "globals.list.actionIcons.cancel.tooltip"
                                )}
                              >
                                <MuiIconButton
                                  onClick={() => onCancelGrantEdit()}
                                >
                                  <MuiCancelIcon />
                                </MuiIconButton>
                              </MuiTooltip>
                            </span>
                          )}
                        </MuiTableCell>
                      </MuiTableRow>
                    ))
                  ) : (
                    <MuiTableRow className="noData">
                      <MuiTableCell
                        colSpan="4"
                        align="center"
                        classes={{
                          body: classes.noDataTableCell,
                        }}
                      >
                        {t("globals.list.messages.noData")}
                      </MuiTableCell>
                    </MuiTableRow>
                  )}
                </MuiTableBody>
              </MuiTable>
            </MuiTableContainer>
          </div>
        </>
      )}
    </FieldArray>
  );
};

/* Options to be shown in Faculty Autocomplete
 * Values in the list are to be shown in the format:
 *    - Faculty Name
 *    - Rank
 *    - Department
 * With parts and matches, the value typed in the Input Field,
 * is shown higlighted in the Options List for matching values
 */
const AutocompleteOption = ({ option, inputValue }) => {
  const matches = match(option.displayText, inputValue);
  const parts = parse(option.displayText, matches);

  return (
    <div>
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      <div>
        {[option.value.rank, option.value.departmentName]
          .filter(Boolean)
          .join(", ")}
      </div>
    </div>
  );
};
