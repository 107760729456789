import { makeStyles } from "@material-ui/core/styles";

export const useOutcomesStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  button: {
    borderRadius: "5px",
    backgroundColor: "#009abb",
    fontSize: "16px",
    color: "#ffffff",
    marginLeft: "8px",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  inputPanelWrapper: {
    width: "100%",
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    color: theme.palette.grey[800],
  },
}));
