import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink, useHistory, useParams } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid as MuiGrid,
  MenuItem as MuiMenuItem,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { NavigateNext as MuiNavigateNextIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
/** Custom Components */
import { FormButton } from "../../../UI/Button/FormButton";
import { Spinner } from "../../../UI/Loading/Spinner";
import { submissionType, monthOptions } from "../../../../constants";
import { buildYearRange } from "../../../../Utils/utils.js";
/** Services */
import { usePeopleAPI } from "../../../../services/usePeopleAPI";
import { useSubmissionsAPI } from "../../../../services/useSubmissionsAPI";
/** Context and Component to show the error on UI */
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./CreateEditSubmissionForm.styles";

// TODO: Rename to SubmissionForm
export const CreateSubmission = (props) => {
  const { alert: alertState } = useAlertContext();
  const history = useHistory();
  const submissionId = useParams().submissionId;
  const { loadSubmissionValues, saveSubmissionValues, isLoading, isSaving } =
    useSubmissionsAPI();
  const classes = useStyles();
  const { t } = useTranslation();
  const yearOptions = buildYearRange(5, 5);

  const [submissionValues, setSubmissionValues] = useState({
    name: "",
    year: "",
    month: "",
    admins: [],
    observers: [],
  });

  useEffect(() => {
    if (submissionId) {
      loadSubmissionValues(submissionId, setSubmissionValues);
    }
  }, [submissionId, loadSubmissionValues]);

  const formik = useFormik({
    initialValues: submissionValues,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        .trim()
        .required(
          t("submission.create.form.fields.submissionName.validation.isEmpty")
        ),
      year: yup
        .string()
        .required(
          t("submission.create.form.fields.submissionYear.validation.isEmpty")
        ),
      month: yup
        .string()
        .required(
          t("submission.create.form.fields.submissionMonth.validation.isEmpty")
        ),
    }),
    onSubmit: (values, { setFieldError }) => {
      saveSubmissionValues(props.type, submissionId, values, setFieldError);
    },
  });

  const handleCancel = () => {
    history.push("/admin/submissions");
  };

  return (
    <MuiGrid
      container
      wrap="nowrap"
      justify="flex-start"
      direction="column"
      spacing={2}
    >
      <Spinner visible={isLoading || isSaving} />
      <MuiGrid item></MuiGrid>
      <MuiGrid item>
        <MuiBreadcrumbs
          separator={<MuiNavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <RouteLink to="/admin/submissions" className={classes.routeLink}>
            {t("submission.manage.title")}
          </RouteLink>
          <MuiTypography color="textPrimary">
            {props.type === submissionType.create
              ? t("submission.create.title")
              : t("submission.edit.title")}
          </MuiTypography>
        </MuiBreadcrumbs>
      </MuiGrid>
      <MuiGrid item>
        <MuiTypography variant="h5">
          {props.type === submissionType.create
            ? t("submission.create.title")
            : t("submission.edit.title")}
        </MuiTypography>
      </MuiGrid>
      {alertState.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <MuiGrid item>
        <form onSubmit={formik.handleSubmit}>
          <MuiGrid
            container
            direction="column"
            justify="space-between"
            spacing={2}
          >
            <MuiGrid item xs={10} sm={8} md={6} lg={5} xl={4}>
              <MuiTextField
                name="name"
                label={t("submission.create.form.fields.submissionName.label")}
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{ maxLength: 200 }}
                autoComplete="off"
                variant="outlined"
                fullWidth
              />
            </MuiGrid>
            <MuiGrid
              container
              spacing={1}
              xs={12}
              sm={12}
              md={12}
              lg={10}
              xl={12}
              style={{ padding: "6px" }}
            >
              <MuiGrid item xs={5} sm={4} md={3} xl={2}>
                <MuiTextField
                  name="year"
                  select
                  label={t(
                    "submission.create.form.fields.submissionYear.label"
                  )}
                  required
                  value={formik.values.year}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && Boolean(formik.errors.year)}
                  helperText={formik.touched.year && formik.errors.year}
                  variant="outlined"
                  disabled={props.type === submissionType.edit}
                  fullWidth
                >
                  {yearOptions.map((option) => (
                    <MuiMenuItem key={option} value={option}>
                      {option}
                    </MuiMenuItem>
                  ))}
                </MuiTextField>
              </MuiGrid>
              <MuiGrid item xs={5} sm={4} md={3} xl={2}>
                <MuiTextField
                  name="month"
                  select
                  label={t(
                    "submission.create.form.fields.submissionMonth.label"
                  )}
                  required
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && Boolean(formik.errors.month)}
                  helperText={formik.touched.month && formik.errors.month}
                  variant="outlined"
                  disabled={props.type === submissionType.edit}
                  fullWidth
                >
                  {monthOptions.map((option) => {
                    const submissionMonth =
                      option.toString().length < 2 ? `0${option}` : option;
                    return (
                      <MuiMenuItem key={option} value={option}>
                        {submissionMonth}
                      </MuiMenuItem>
                    );
                  })}
                </MuiTextField>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid item xs={10} sm={8} md={6} lg={5} xl={4}>
              <SubmissionAutocomplete formik={formik} fieldName="admins" />
            </MuiGrid>
            <MuiGrid item xs={10} sm={8} md={6} lg={5} xl={4}>
              <SubmissionAutocomplete formik={formik} fieldName="observers" />
            </MuiGrid>
            <MuiGrid item xs={10} sm={8} md={6} lg={5} xl={4}>
              <MuiGrid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                spacing={2}
              >
                <MuiGrid item>
                  <FormButton
                    classes={{ root: classes.actionButton }}
                    onClick={handleCancel}
                    name={t("globals.form.actionButtons.cancel")}
                    variant="outlined"
                  />
                </MuiGrid>
                <MuiGrid item>
                  <FormButton
                    name={t("globals.form.actionButtons.save")}
                    type="submit"
                    classes={{ root: classes.actionButton }}
                    variant="contained"
                    disabled={isSaving || !formik.isValid || !formik.dirty}
                  />
                </MuiGrid>
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </form>
      </MuiGrid>
    </MuiGrid>
  );
};

const SubmissionAutocomplete = ({ formik, fieldName }) => {
  const { loadPeopleOptions, loadPersonDetails } = usePeopleAPI();
  const { t } = useTranslation();

  const [adminOptions, setAdminOptions] = useState([]);
  const [observerOptions, setObserverOptions] = useState([]);
  const [noOptionsText, setNoOptionsText] = useState(
    t("globals.autocomplete.helperText")
  );

  const options = {
    admins: adminOptions,
    observers: observerOptions,
  };

  const setOptions = {
    admins: setAdminOptions,
    observers: setObserverOptions,
  };

  const handleChangeInput = (event) => {
    const searchQuery = event.target.value.trim();

    loadPeopleOptions(searchQuery, setOptions[fieldName], setNoOptionsText);
  };

  const handleChangeSelection = (value) => {
    if (value.length > formik.values[fieldName].length) {
      // Load and add a new person to the Autocomplete
      loadPersonDetails(value[value.length - 1].profileId, formik, fieldName);
    } else {
      formik.setFieldValue(fieldName, value);
    }

    setOptions[fieldName]([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
  };

  if (!["admins", "observers"].includes(fieldName)) {
    return null;
  }

  return (
    <MuiAutocomplete
      name={fieldName}
      multiple
      value={formik.values[fieldName]}
      options={[...options[fieldName], ...formik.values[fieldName]]}
      filterSelectedOptions={true}
      filterOptions={(option, state) => option}
      getOptionLabel={(option) =>
        `${option.name}${option.uid ? ` (${option.uid})` : ""}`
      }
      getOptionSelected={(option, value) =>
        parseInt(option.profileId) === parseInt(value.profileId)
      }
      noOptionsText={noOptionsText}
      onChange={(event, value) => handleChangeSelection(value)}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t(`submission.create.form.fields.${fieldName}.label`)}
          onChange={(event) => handleChangeInput(event)}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(option, { inputValue }) => (
        <AutocompleteHighlight option={option} inputValue={inputValue} />
      )}
    />
  );
};

const AutocompleteHighlight = ({ option, inputValue }) => {
  const matches = match(option.name, inputValue);
  const parts = parse(option.name, matches);

  return (
    <div>
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      <div>{option.title}</div>
    </div>
  );
};
