import { useState, useEffect } from "react";
import { useParams, Link as RouteLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid as MuiGrid,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Tooltip as MuiTooltip,
  Link as MuiLink,
} from "@material-ui/core";
import { NavigateNext as MuiNavigateNextIcon } from "@material-ui/icons";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import { ReactComponent as OrcidIdLogo } from "../../assets/icons/orcid-id.svg";
/** Custom Components */
import { FormButton } from "../UI/Button/FormButton";
import { AccessDenied } from "../AccessDenied";
import { FacultyTrainingRoles } from "../../constants";
/** Services */
import {
  getEditFacultyDetailsById,
  getEditFacultyAutocompleteOptions,
  getFacultyPrimaryDepartmentById,
  putFacultyDetailsByIdUpdate,
} from "../../services/facultyService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./EditFacultyForm.styles";

// ************ Edit Faculty Component ***********
const editFacultyInitialValues = {
  degrees: [],
  rank: {},
  departments: [],
};
const facultyPrimaryDepartmentValues = {
  values: [],
};
export const EditFaculty = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // **** Get Faculty Id ****
  const facultyId = useParams().facultyId;

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const selectedSubmission = getSelectedSubmission();

  const [commonsIdFieldError, setCommonsIdFieldError] = useState({
    value: null,
    isError: false,
  });

  // **** Initial Form State Values ****
  const [facultyEditFormValues, setFacultyEditFormValues] = useState(
    editFacultyInitialValues
  );
  const [facultyPrimaryDepartment, setFacultyPrimaryDepartmentValues] =
    useState(facultyPrimaryDepartmentValues);
  // *** Fetch Faculty Details ***
  const [loading, setLoading] = useState(true);
  const [facultyDetailsFetchError, setFacultyDetailsFetchError] =
    useState(false);

  useEffect(() => {
    getEditFacultyDetailsById(
      setFacultyEditFormValues,
      setFacultyDetailsFetchError,
      setLoading,
      facultyId,
      selectedSubmission,
      editFacultyInitialValues,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [facultyId, selectedSubmission]);

  useEffect(() => {
    getFacultyPrimaryDepartmentById(
      setFacultyPrimaryDepartmentValues,
      facultyId,
      selectedSubmission,
      facultyPrimaryDepartmentValues
    );
    // eslint-disable-next-line
  }, [facultyId, selectedSubmission]);

  // **** Field Options ****
  const [rankOptionsList, setRankOptionsList] = useState([]);
  const [degreeOptionsList, setDegreeOptionsList] = useState([]);
  // **** Field Values ****
  const [rankInputValue, setRankInputValue] = useState("");
  const [degreeInputValue, setDegreeInputValue] = useState("");
  const { alert, setAlert, clearAlert } = useAlertContext();

  // **** Fetch Options for Autocomplete List ****
  const handleAutoCompleteInputChange = async (
    inputName,
    size,
    inputValue,
    setFieldInputValue,
    setFieldOptions
  ) => {
    setFieldInputValue(inputValue);
    if (inputValue) {
      getEditFacultyAutocompleteOptions(
        inputName,
        size,
        inputValue,
        setFieldOptions,
        setAlert,
        clearAlert
      );
    }
  };
  // **** Faculty Form Validation ****
  const facultyValidation = Yup.object().shape({
    orcidId: Yup.string().when("hasSourceOrcid", {
      is: (val) => val === false,
      then: Yup.string().test((val) => {
        if (val) {
          if (val.length === 19) return true;
          if (val.length < 19 && val.length > 0) return false;
        } else {
          return true;
        }
      }),
    }),
    degrees: Yup.array().min(1).required("Required"),
    rank: Yup.object().required("Required"),
    department: Yup.object().required("Required"),
  });

  /** 
  redirect on cancel button click
  */
  const onCancelHandler = () => {
    props.history.push("/faculty");
  };

  return (
    !loading &&
    (!facultyDetailsFetchError &&
    facultyEditFormValues._links &&
    facultyEditFormValues._links.editDetails ? (
      <MuiGrid
        container
        wrap="nowrap"
        justify="flex-start"
        direction="column"
        spacing={2}
      >
        <MuiGrid item></MuiGrid>
        <MuiGrid item>
          <MuiBreadcrumbs
            separator={<MuiNavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouteLink to="/faculty" className={classes.routeLink}>
              {t("faculty.title")}
            </RouteLink>
            <MuiTypography color="textPrimary">
              {t("faculty.edit.title")}
            </MuiTypography>
          </MuiBreadcrumbs>
        </MuiGrid>

        <MuiGrid item>
          <Formik
            initialValues={facultyEditFormValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              setSubmitting(false);

              putFacultyDetailsByIdUpdate(
                setCommonsIdFieldError,
                selectedSubmission,
                values,
                props,
                setAlert,
                clearAlert
              );
            }}
            validationSchema={facultyValidation}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                dirty,
              } = props;

              const handleChangeOrcID = (event) => {
                let { value } = event.target;

                // remove any non-alphanumeric characters except hyphens
                value = value.replace(/[^\d]/g, "");

                // insert hyphens after every 4 characters
                let formattedValue = "";
                for (let i = 0; i < value.length; i++) {
                  if (i > 0 && i % 4 === 0) {
                    formattedValue += "-";
                  }
                  formattedValue += value[i];
                }
                // update the state with formattedValue
                props.setFieldValue("orcidId", formattedValue);
              };

              return (
                <form onSubmit={handleSubmit}>
                  {alert.exists && (
                    <MuiGrid item>
                      <ASAlert />
                    </MuiGrid>
                  )}
                  <MuiGrid
                    container
                    item
                    xs={10}
                    sm={8}
                    md={6}
                    lg={5}
                    xl={4}
                    direction="column"
                    justify="space-between"
                    spacing={2}
                  >
                    <MuiGrid item xs={11}>
                      <MuiTypography color="textPrimary" variant="h5">
                        {values.facultyFullName}
                      </MuiTypography>
                    </MuiGrid>
                    <MuiGrid item xs={12}>
                      <MuiGrid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <MuiGrid item xs={11}>
                          <MuiTextField
                            id="orcidId"
                            name="orcidId"
                            label={`${t("faculty.edit.facultyList.orcidId")}`}
                            value={values.orcidId || ""}
                            onChange={handleChangeOrcID}
                            onBlur={handleBlur}
                            helperText={touched.orcidId ? errors.orcidId : ""}
                            error={touched.orcidId && Boolean(errors.orcidId)}
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            disabled={values.hasSourceOrcid}
                            inputProps={{ maxLength: 19 }}
                          />
                        </MuiGrid>
                        <MuiGrid item xs={1} className={classes.orcidId}>
                          <MuiTooltip
                            interactive
                            placement="right"
                            classes={{ tooltip: classes.toolTip }}
                            title={
                              <div>
                                The ORCID iD field is the individual’s
                                persistent digital identifier{" "}
                                <MuiLink
                                  href="https://orcid.org/"
                                  classes={{ root: classes.tooltipLink }}
                                  target="_blank"
                                >
                                  (<u>https://orcid.org/</u>)
                                </MuiLink>{" "}
                                that can be used to retrieve their professional
                                information — affiliations, grants, and
                                publications. <br />
                                <br />
                                The source for this information is Stanford’s
                                ORCID Integration application{" "}
                                <MuiLink
                                  href="https://authorize.stanford.edu/orcid/OrcidApp"
                                  target="_blank"
                                  classes={{ root: classes.tooltipLink }}
                                >
                                  (
                                  <u>
                                    https://authorize.stanford.edu/orcid/OrcidApp
                                  </u>
                                  )
                                </MuiLink>
                                . <br />
                                <br />
                                If the ORCID iD is missing for an individual, we
                                would encourage that it be updated in the source
                                system, which requires each individual to
                                verify/create their own ORCID iD directly in the
                                application.
                              </div>
                            }
                          >
                            <div style={{ height: "20px", width: "20px" }}>
                              <OrcidIdLogo />
                            </div>
                          </MuiTooltip>
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="commonsId"
                        name="commonsId"
                        label={`${t("faculty.edit.facultyList.commonsID")}`}
                        value={values.commonsId || ""}
                        onChange={(e) => {
                          const commonIdVal = e.target.value;
                          setCommonsIdFieldError({
                            ...commonsIdFieldError,
                            isError:
                              commonsIdFieldError.value &&
                              commonsIdFieldError.value === commonIdVal,
                          });

                          props.setFieldValue("commonsId", commonIdVal);
                        }}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={commonsIdFieldError.isError}
                        helperText={
                          commonsIdFieldError.isError &&
                          `${t("faculty.edit.commonsIdError")}`
                        }
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 20 }}
                        onKeyPress={(e) => {
                          if (e.charCode === 32) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="email"
                        label={`${t("faculty.edit.facultyList.email")}`}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.email ? errors.email : ""}
                        error={touched.email && Boolean(errors.email)}
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        multiple
                        id="degrees"
                        name="degrees"
                        value={values.degrees}
                        options={degreeInputValue ? degreeOptionsList : []}
                        getOptionLabel={(option) => option.shortName ?? ""}
                        onChange={(e, value) => {
                          if (value) {
                            props.setFieldValue("degrees", value);
                          }
                        }}
                        getOptionDisabled={(option) =>
                          values.degrees &&
                          values.degrees.some((el) => {
                            return el.code === option.code;
                          })
                        }
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t("faculty.edit.facultyList.degrees")}*`}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              handleAutoCompleteInputChange(
                                "degree",
                                10,
                                e.target.value || "",
                                setDegreeInputValue,
                                setDegreeOptionsList
                              );
                            }}
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        id="rank"
                        options={rankInputValue ? rankOptionsList : []}
                        getOptionLabel={(option) => option.name ?? ""}
                        value={values.rank}
                        clearOnEscape={true}
                        onChange={(e, value) => {
                          props.setFieldValue("rank", value || "");
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            name="rank"
                            label={`${t("faculty.edit.facultyList.rank")}*`}
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              handleAutoCompleteInputChange(
                                "rank",
                                10,
                                e.target.value || "",
                                setRankInputValue,
                                setRankOptionsList
                              );
                            }}
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        options={
                          facultyPrimaryDepartment
                            ? facultyPrimaryDepartment.values
                            : []
                        }
                        getOptionLabel={(option) =>
                          option.name ? `${option.name} (${option.code})` : ""
                        }
                        value={values.department || []}
                        onChange={(e, value) => {
                          if (value) {
                            props.setFieldValue("department", value);
                          }
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label={`${t(
                              "faculty.edit.facultyList.primaryDepProg"
                            )}*`}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="researchInterest"
                        label={`${t(
                          "faculty.edit.facultyList.researchInterest"
                        )}`}
                        value={values.researchInterest}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.researchInterest
                            ? errors.researchInterest
                            : ""
                        }
                        error={
                          touched.researchInterest &&
                          Boolean(errors.researchInterest)
                        }
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        inputProps={{ maxLength: 200 }}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiAutocomplete
                        multiple
                        name="trainingRoles"
                        value={values.trainingRoles || []}
                        options={Object.keys(FacultyTrainingRoles) || []}
                        getOptionLabel={(option) =>
                          t(FacultyTrainingRoles[option])
                        }
                        onChange={(e, value) => {
                          if (value && value.length < 4) {
                            props.setFieldValue("trainingRoles", value);
                          }
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            name="trainingRole"
                            label={`${t(
                              "faculty.edit.facultyList.trainingRole"
                            )}`}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiGrid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <MuiGrid item>
                          <FormButton
                            classes={{ root: classes.actionButton }}
                            onClick={onCancelHandler}
                            name={t("globals.form.actionButtons.cancel")}
                            variant="outlined"
                          />
                        </MuiGrid>
                        <MuiGrid item>
                          <FormButton
                            name={t("globals.form.actionButtons.save")}
                            classes={{ root: classes.actionButton }}
                            variant="contained"
                            type="submit"
                            disabled={
                              !dirty ||
                              !isValid ||
                              !values.submissionName.trim() ||
                              commonsIdFieldError.isError
                            }
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </form>
              );
            }}
          </Formik>
        </MuiGrid>
      </MuiGrid>
    ) : (
      <AccessDenied />
    ))
  );
};
