import { Switch, Route } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Page } from "../components/Page/Page";
import { ManageSubmission } from "../components/administration/submission/ManageSubmission/ManageSubmission";
import { SubmissionFilter } from "../components/administration/submission/ManageSubmission/Submission.filter";
import { FacultyFilter } from "../components/Faculty/FacultyFilter";
import { GrantFilter } from "../components/Grants/GrantFilter";
import { CreateSubmission } from "../components/administration/submission/CreateEditSubmissionForm/CreateEditSubmissionForm";
import { submissionType } from "../constants";
import { Faculty } from "../components/Faculty/Faculty";
import { EditFaculty } from "../components/Faculty/EditFacultyForm";
import { EditGrants } from "../components/Grants/EditGrants";
import { EditTrainee } from "../components/Trainees/EditTrainee";
import { CreateApplicant } from "../components/Applicants/CreateApplicant";
import { Grants } from "../components/Grants/Grants";
import { Table2 } from "../components/Output/OutputTable2";
import { Table1 } from "../components/Output/OutputTable1";
import { Table3 } from "../components/Output/OutputTable3";
import { OutputTable4 } from "../components/Output/OutputTable4";
import { Table6A } from "../components/Output/OutputTable6A";
import { Table6B } from "../components/Output/OutputTable6B";
import { Table8A } from "../components/Output/OutputTable8A";
import { Table8C } from "../components/Output/OutputTable8C";
import { Trainees } from "../components/Trainees/Trainees";
import { TraineesFilter } from "../components/Trainees/TraineesFilter";
import { Applicants } from "../components/Applicants/Applicants";
import { ApplicantsFilter } from "../components/Applicants/ApplicantsFilter/ApplicantsFilter";
import { Outcomes } from "../components/Outcomes/Outcomes";
import { OutcomesFilter } from "../components/Outcomes/OutcomesFilter";
import { CreateOutcome } from "../components/Outcomes/CreateOutcome";
import { EditOutcome } from "../components/Outcomes/EditOutcome";
import { AccessDenied } from "../components/AccessDenied";
/** Services */
import { ApplicantsSelectedProvider } from "../services/applicantsSelectedContext";
import { GrantsProvider } from "../services/grantsContext";
import { useUsers } from "../services/usersService";
import { TraineeProvider } from "../services/traineeContext";
import { TraineesSelectedProvider } from "../services/traineesSelectedContext";
import { ApplicantProvider } from "../services/applicantContext";
import { ManageSubmissionsProvider } from "../services/manageSubmissionsContext";
import { FacultyDemographicsProvider } from "../services/facultyDemographicsContext";
import { OutcomesProvider } from "../services/outcomesContext";
import { OutcomesSelectedProvider } from "../services/outcomesSelectedContext";

const routes = {
  submissions: [
    {
      path: "/admin/submissions",
      title: "submissions",
      exact: true,
      filter: <SubmissionFilter />,
      main: <ManageSubmission />,
      permission: ["VIEW_ALL_SUBMISSIONS", "VIEW_OWNED_SUBMISSIONS"],
    },
    {
      path: "/admin/submissions/new",
      title: null,
      exact: true,
      filter: null,
      main: <CreateSubmission type={submissionType.create} />,
      permission: ["CREATE_SUBMISSION"],
    },
    {
      path: "/admin/submissions/:submissionId/edit",
      title: null,
      exact: true,
      filter: null,
      main: <CreateSubmission type={submissionType.edit} />,
      permission: ["VIEW_ALL_SUBMISSIONS", "VIEW_OWNED_SUBMISSIONS"],
    },
  ],
  faculty: [
    {
      path: "/faculty",
      title: "faculty",
      metaDataDescription: "faculty.modalDescription",
      exact: true,
      filter: <FacultyFilter />,
      main: <Faculty />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_FACULTY_DEMOGRAPHICS",
        "VIEW_ALL_SUBMISSIONS_FACULTY_DEMOGRAPHICS",
      ],
    },
    {
      path: "/faculty/:facultyId/edit",
      title: "editFaculty",
      exact: true,
      filter: null,
      main: <EditFaculty />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_FACULTY_DEMOGRAPHICS",
        "VIEW_ALL_SUBMISSIONS_FACULTY_DEMOGRAPHICS",
      ],
    },
  ],
  grants: [
    {
      path: "/grants",
      title: "grants",
      metaDataDescription: "grants.modalDescription",
      exact: true,
      filter: <GrantFilter />,
      main: <Grants />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_GRANT_DETAILS",
        "VIEW_ALL_SUBMISSIONS_GRANT_DETAILS",
      ],
    },
    {
      path: "/grants/:grantId/edit",
      title: "editGrant",
      exact: true,
      filter: null,
      main: <EditGrants />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_GRANT_DETAILS",
        "VIEW_ALL_SUBMISSIONS_GRANT_DETAILS",
      ],
    },
  ],

  trainees: [
    {
      path: "/trainees",
      title: "trainees",
      metaDataDescription: "trainees.modalDescription",
      exact: true,
      filter: <TraineesFilter />,
      main: <Trainees />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_TRAINEE_DETAILS",
        "VIEW_ALL_SUBMISSIONS_TRAINEE_DETAILS",
      ],
    },
    {
      path: "/trainees/:trainee/edit",
      title: "editTrainee",
      exact: true,
      filter: null,
      main: <EditTrainee />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_TRAINEE_DETAILS",
        "VIEW_ALL_SUBMISSIONS_TRAINEE_DETAILS",
      ],
    },
  ],
  applicants: [
    {
      path: "/applicants",
      title: "applicants",
      metaDataDescription: "applicants.modalDescription",
      exact: true,
      filter: <ApplicantsFilter />,
      main: <Applicants />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_APPLICANT",
        "VIEW_ALL_SUBMISSIONS_APPLICANT",
      ],
    },
    {
      path: "/applicants/:submissionId/edit/:applicantId",
      title: null,
      exact: true,
      filter: null,
      main: <CreateApplicant type={submissionType.edit} />,
    },
    {
      path: "/applicants/:submissionId/new",
      title: null,
      exact: true,
      filter: null,
      main: <CreateApplicant type={submissionType.create} />,
    },
  ],
  output: [
    {
      path: "/output/table-1",
      title: "outputTable1",
      exact: true,
      filter: null,
      main: <Table1 />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-2",
      title: "outputTable2",
      exact: true,
      filter: null,
      main: <Table2 />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-3",
      title: "outputTable3",
      exact: true,
      filter: null,
      main: <Table3 />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-4",
      title: "outputTable4",
      exact: true,
      filter: null,
      main: <OutputTable4 />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-6A",
      title: "outputTable6A",
      exact: true,
      filter: null,
      main: <Table6A />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-6B",
      title: "outputTable6B",
      exact: true,
      filter: null,
      main: <Table6B />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-8A",
      title: "outputTable8A",
      exact: true,
      filter: null,
      main: <Table8A />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
    {
      path: "/output/table-8C",
      title: "outputTable8C",
      exact: true,
      filter: null,
      main: <Table8C />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTPUT",
        "VIEW_ALL_SUBMISSIONS_OUTPUT",
      ],
    },
  ],
  outcomes: [
    {
      path: "/outcomes",
      title: "outcomes",
      metaDataDescription: "outcomes.modalDescription",
      exact: true,
      filter: <OutcomesFilter />,
      main: <Outcomes />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTCOME",
        "VIEW_ALL_SUBMISSIONS_OUTCOME",
      ],
    },
    {
      path: "/outcomes/:outcome/edit",
      title: "editOutcome",
      exact: true,
      filter: null,
      main: <EditOutcome />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTCOME_DETAILS",
        "VIEW_ALL_SUBMISSIONS_OUTCOME_DETAILS",
      ],
    },
  ],
  others: [
    {
      path: "/outcomes/new",
      title: null,
      exact: true,
      filter: null,
      main: <CreateOutcome type={submissionType.create} />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_OUTCOME",
        "VIEW_ALL_SUBMISSIONS_OUTCOME",
      ],
    },
    {
      path: "/outcomes/:id/edit",
      title: "editOutcome",
      exact: true,
      filter: null,
      main: <CreateOutcome />,
      permission: [
        "VIEW_OWNED_SUBMISSIONS_TRAINEE_DETAILS",
        "VIEW_ALL_SUBMISSIONS_TRAINEE_DETAILS",
      ],
    },
    {
      // Do not add any route after this. Keep this at last, as a default Route
      path: "/accessdenied",
      title: "",
      exact: true,
      filter: null,
      main: <AccessDenied />,
    },
  ],
};

export const RoutesMapping = () => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();

  const checkRoutePermission = (permArray) => {
    return (
      currentUser.permissions &&
      Object.entries(currentUser.permissions).some(
        ([prop, value]) => !permArray || (permArray.includes(prop) && value)
      )
    );
  };

  const getContextRoute = (routeArray) => {
    return (
      <>
        {routeArray.map((route, index) => {
          /* If user has permission for the page, then
           * only create the route
           */

          return (
            <Route
              key={encodeURIComponent(index)}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Page
                  title={route.title && t(`globals.title.${route.title}`)}
                  metaDataDescription={
                    route.metaDataDescription && (
                      <Trans
                        i18nKey={`globals.metaDataInfo.${route.metaDataDescription}`}
                      />
                    )
                  }
                  filter={route.filter}
                  main={route.main}
                />
              )}
            ></Route>
          );
        })}
      </>
    );
  };

  return (
    <Switch>
      {checkRoutePermission(routes.submissions[0].permission) && (
        <Route path={["/admin/submissions"]}>
          <ManageSubmissionsProvider>
            <Switch>{getContextRoute(routes.submissions)}</Switch>
          </ManageSubmissionsProvider>
        </Route>
      )}
      {checkRoutePermission(routes.submissions[1].permission) && (
        <Route path={["/admin/submissions/new"]}>
          <ManageSubmissionsProvider>
            <Switch>{getContextRoute(routes.submissions)}</Switch>
          </ManageSubmissionsProvider>
        </Route>
      )}
      {checkRoutePermission(routes.submissions[2].permission) && (
        <Route path={["/admin/submissions/:submissionId/edit"]}>
          <ManageSubmissionsProvider>
            <Switch>{getContextRoute(routes.submissions)}</Switch>
          </ManageSubmissionsProvider>
        </Route>
      )}
      {checkRoutePermission(routes.faculty[0].permission) && (
        <Route path={["/faculty", "/faculty/:facultyId/edit"]}>
          <FacultyDemographicsProvider>
            <Switch>{getContextRoute(routes.faculty)}</Switch>
          </FacultyDemographicsProvider>
        </Route>
      )}
      {checkRoutePermission(routes.grants[0].permission) && (
        <Route path={["/grants", "/grants/:grantId/edit"]}>
          <GrantsProvider>
            <Switch>{getContextRoute(routes.grants)}</Switch>
          </GrantsProvider>
        </Route>
      )}
      {checkRoutePermission(routes.trainees[0].permission) && (
        <Route path={["/trainees", "/trainees/:trainee/edit"]}>
          <TraineeProvider>
            <TraineesSelectedProvider>
              <Switch>{getContextRoute(routes.trainees)}</Switch>
            </TraineesSelectedProvider>
          </TraineeProvider>
        </Route>
      )}
      {checkRoutePermission(routes.applicants[0].permission) && (
        <Route
          path={[
            "/applicants",
            "/applicants/:submissionId/edit/:applicantId",
            "/applicants/:submissionId/new",
          ]}
        >
          <ApplicantProvider>
            <ApplicantsSelectedProvider>
              <Switch>{getContextRoute(routes.applicants)}</Switch>
            </ApplicantsSelectedProvider>
          </ApplicantProvider>
        </Route>
      )}
      {checkRoutePermission(routes.output[0].permission) && (
        <Route
          path={[
            "/output/table-1",
            "/output/table-2",
            "/output/table-3",
            "/output/table-4",
            "/output/table-6A",
            "/output/table-6B",
            "/output/table-8A",
            "/output/table-8C",
          ]}
        >
          <Switch>{getContextRoute(routes.output)}</Switch>
        </Route>
      )}
      {checkRoutePermission(routes.outcomes[0].permission) && (
        <Route path={["/outcomes/new", "/outcomes/:id/edit", "/accessdenied"]}>
          <OutcomesProvider>
            <Switch>{getContextRoute(routes.outcomes)}</Switch>
          </OutcomesProvider>
        </Route>
      )}
      {checkRoutePermission() && (
        <Route path={["/outcomes"]}>
          <OutcomesProvider>
            <OutcomesSelectedProvider>
              <Switch>{getContextRoute(routes.outcomes)}</Switch>
            </OutcomesSelectedProvider>
          </OutcomesProvider>
        </Route>
      )}
      {currentUser.uid && (
        <Route
          path="*"
          render={(props) => (
            <Page title={""} filter={null} main={<AccessDenied />} />
          )}
        ></Route>
      )}
    </Switch>
  );
};
