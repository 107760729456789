import { useState, createContext, useContext } from "react";

const OutcomesSelectedContext = createContext();

export const SelectAllStates = {
  NONE_SELECTED: 0,
  SOME_SELECTED: 1,
  ALL_SELECTED: 2,
};

export const OutcomesSelectedProvider = ({ children }) => {
  const [outcomesSelected, setOutcomesSelected] = useState({
    individualSelections: {},
    selectAllState: SelectAllStates.NONE_SELECTED,
  });

  const { ALL_SELECTED, SOME_SELECTED, NONE_SELECTED } = SelectAllStates;

  const handleSelectionChange = (event, row) => {
    if (!(row.traineeEmail && row.traineeEmail.trim())) return;

    const value = !!event.target.checked;
    const rowId = row._links.self.href;

    switch (true) {
      case outcomesSelected.selectAllState === ALL_SELECTED && !value:
      case outcomesSelected.selectAllState === SOME_SELECTED && !value:
        setOutcomesSelected({
          ...outcomesSelected,
          individualSelections: {
            ...outcomesSelected.individualSelections,
            [rowId]: false,
          },
          selectAllState: SOME_SELECTED,
        });
        break;

      case outcomesSelected.selectAllState === NONE_SELECTED && value:
        setOutcomesSelected({
          ...outcomesSelected,
          individualSelections: {
            ...outcomesSelected.individualSelections,
            [rowId]: true,
          },
        });
        break;

      case outcomesSelected.selectAllState === SOME_SELECTED && value:
      case outcomesSelected.selectAllState === NONE_SELECTED && !value:
        const { [rowId]: dummy, ...individualSelections } =
          outcomesSelected.individualSelections;

        setOutcomesSelected({
          ...outcomesSelected,
          individualSelections: { ...individualSelections },
        });
        break;

      case outcomesSelected.selectAllState === ALL_SELECTED && value:
        // empty: should not happen
        break;

      default:
        break;
    }
  };

  const handleSelectAllChange = (event) => {
    setOutcomesSelected({
      ...outcomesSelected,
      individualSelections: {},
      selectAllState: !!event.target.checked ? ALL_SELECTED : NONE_SELECTED,
    });
  };

  const resetAllSelections = () => {
    setOutcomesSelected({
      ...outcomesSelected,
      individualSelections: {},
      selectAllState: NONE_SELECTED,
    });
  };

  const ignoreSelection = (row) => {
    const rowId = row._links.self.href;
    const { [rowId]: dummy, ...individualSelections } =
      outcomesSelected.individualSelections;

    setOutcomesSelected({
      ...outcomesSelected,
      individualSelections: { ...individualSelections },
    });
  };

  return (
    <OutcomesSelectedContext.Provider
      value={{
        outcomesSelected,
        setOutcomesSelected,
        handleSelectionChange,
        handleSelectAllChange,
        resetAllSelections,
        ignoreSelection,
      }}
    >
      {children}
    </OutcomesSelectedContext.Provider>
  );
};

export const useOutcomesSelectedContext = () =>
  useContext(OutcomesSelectedContext);
