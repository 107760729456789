import { useTranslation } from "react-i18next";
import {
  TableBody as MuiTableBody,
  Typography as MuiTypography,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Checkbox as MUiCheckbox,
} from "@material-ui/core";
import { format, parseISO } from "date-fns";
/** Static Dropdown Values */
import {
  traineeTypeDropDown,
  OutcomeTerminalDegreeTypeEnums,
  OutcomeTrainingSupportSourceEnums,
  OutcomeResultingDegreeTypeEnums,
  OutcomesInvitationStatusEnums,
} from "../../../constants";
import { getTrainingTypeNameByTrainingTypeCode } from "../../../Utils/utils";
import AddRemoveActionIcons from "../../Layout/AddRemoveActionIcons/AddRemoveActionIcons";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "../../../services/outcomesSelectedContext";
import { AuditInfo } from "../../UI/AuditInfo/AuditInfo";
/** Styles */
import { useStyles } from "./OutcomesDetails.styles";

const OutcomesDetailsTableBody = ({
  outcomesList,
  handleConfirmation,
  toggleActive,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const outcomesDetailsTableColumns = "outcomes.mainView.list.tableColumns";

  const { outcomesSelected, handleSelectionChange } =
    useOutcomesSelectedContext();

  // If row is inactive, put the disableRow class
  const getRowClass = (isRowActive) => (!isRowActive ? classes.disableRow : "");

  const getStatusClass = (row) => {
    if (row.invitationStatus in OutcomesInvitationStatusEnums) {
      return classes[
        OutcomesInvitationStatusEnums[row.invitationStatus].displayClass
      ];
    }
    return "";
  };

  const getTrainingSupportYear = (traineeType, training) => {
    if (training.trainingTypeCode === "NIH_HHS") {
      const result = t(
        "outcomes.mainView.list.trainingSupportYearNihHhsDisplay",
        {
          trainingYearNumber: training.trainingYearNumber,
          nihHhsComponentAndActivity: training.nihHhsComponentAndActivity,
        }
      );
      return result;
    } else {
      const trainingSource =
        (training.trainingSourceCode &&
          t(
            `${
              OutcomeTrainingSupportSourceEnums[training.trainingSourceCode]
            }.shortName`
          )) ||
        "";

      const trainingType = getTrainingTypeNameByTrainingTypeCode(
        training.trainingTypeCode,
        "shortName"
      );

      const result = t(
        "outcomes.mainView.list.trainingSupportYearNonNihHhsDisplay",
        {
          trainingYearNumber: training.trainingYearNumber,
          trainingSource: trainingSource,
          trainingType: trainingType,
        }
      );
      return result;
    }
  };

  const isSelected = (outcomesSelected, row) => {
    if (!(row.traineeEmail && row.traineeEmail.trim())) return false;
    if (!row.isActive) return false;

    const rowId = row._links.self.href;

    if (outcomesSelected.selectAllState === SelectAllStates.ALL_SELECTED) {
      return true;
    } else if (
      outcomesSelected.selectAllState === SelectAllStates.NONE_SELECTED
    ) {
      return !!outcomesSelected.individualSelections[rowId];
    } else {
      return outcomesSelected.individualSelections[rowId] !== false;
    }
  };

  const getResultingDegree = (resultingDegree) => {
    const degree =
      resultingDegree.degreeCode !== "OTHER_D"
        ? t(OutcomeResultingDegreeTypeEnums[resultingDegree.degreeCode])
        : resultingDegree.otherDegree;

    const degreeYear = resultingDegree.degreeYear || "";

    return `${degree} ${degreeYear}`;
  };

  return (
    <MuiTableBody>
      {outcomesList.values && outcomesList.values.length > 0 ? (
        outcomesList.values.map((row, i) => (
          <MuiTableRow
            key={row._links.self.href}
            className={getRowClass(row.isActive)}
          >
            {row._links.addOutcome && (
              <MuiTableCell
                align={"left"}
                classes={{ head: classes.tableCellHead }}
                title={row.traineeEmail ? "" : "No email on record"}
              >
                <MUiCheckbox
                  classes={{
                    disabled: classes.disableRow,
                    root: classes.selectBox,
                  }}
                  checked={isSelected(outcomesSelected, row)}
                  disabled={!row.traineeEmail || !row.isActive}
                  onChange={(event) => handleSelectionChange(event, row)}
                />
              </MuiTableCell>
            )}
            <MuiTableCell
              align={"left"}
              className={getRowClass(row.isActive)}
              title={row.traineeEmail ? "" : "No email on record"}
            >
              {row.traineeName ? row.traineeName : ""}
              {row.traineeName && row.traineeEmail ? <br /> : ""}
              {row.traineeEmail ? row.traineeEmail : ""}
              <AuditInfo
                isEdited={row.traineeNameMod || row.traineeEmailMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell
              align={"left"}
              className={`${classes.invitationStatusTableCell} ${getRowClass(
                row.isActive
              )}`}
            >
              {row.lastInvitedDate
                ? format(parseISO(row.lastInvitedDate), "MM/dd/yyyy")
                : ""}
              {row.lastInvitedDate && row.invitationStatus ? <br /> : ""}
              {
                <div className={getStatusClass(row)}>
                  {row.invitationStatus &&
                    t(
                      OutcomesInvitationStatusEnums[row.invitationStatus]
                        .translationKey
                    )}
                </div>
              }
            </MuiTableCell>
            <MuiTableCell align={"left"} className={getRowClass(row.isActive)}>
              {t(traineeTypeDropDown[row.traineeType])}
              <AuditInfo
                isEdited={row.traineeTypeMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align={"left"} className={getRowClass(row.isActive)}>
              <ul
                style={{ listStyleType: "none" }}
                className={classes.unorderedList}
              >
                {row.terminalDegrees &&
                  row.terminalDegrees.map((terminalDegree, i) => {
                    return (
                      <li key={terminalDegree.degreeCode}>
                        {t(
                          OutcomeTerminalDegreeTypeEnums[
                            terminalDegree.degreeCode
                          ]
                        )}{" "}
                        {terminalDegree.degreeYear}
                      </li>
                    );
                  })}
              </ul>
              <AuditInfo
                isEdited={row.traineeDegreesMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align={"left"} className={getRowClass(row.isActive)}>
              {row.submissionOutcomeFaculty &&
                row.submissionOutcomeFaculty
                  .map((traineeFaculty) => traineeFaculty["fullName"])
                  .join("; ")}{" "}
              <AuditInfo
                isEdited={row.submissionOutcomeFacultyMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell
              align={"left"}
              className={`${getRowClass(row.isActive)} trainingPeriod`}
            >
              <div>
                {row.trainingStartDate &&
                  format(parseISO(row.trainingStartDate), "MM/dd/yyyy")}

                {row.trainingStartDate || row.trainingEndDate
                  ? " - "
                  : t(`${outcomesDetailsTableColumns}.notAvailable`)}
                {row.trainingEndDate &&
                  format(parseISO(row.trainingEndDate), "MM/dd/yyyy")}
                <AuditInfo
                  isEdited={row.trainingStartEndDatesMod}
                  isActive={row.isActive}
                />
              </div>
            </MuiTableCell>
            <MuiTableCell align={"left"} className={getRowClass(row.isActive)}>
              <ul
                style={{ listStyleType: "none" }}
                className={classes.unorderedList}
              >
                {row.trainingSupportYears &&
                  row.trainingSupportYears.map((training, i) => {
                    return (
                      <li key={training.trainingYearNumber}>
                        {getTrainingSupportYear(row.traineeType, training)}
                      </li>
                    );
                  })}
              </ul>

              <AuditInfo
                isEdited={row.trainingSupportYearsMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align={"left"} className={getRowClass(row.isActive)}>
              <ul
                style={{ listStyleType: "none" }}
                className={classes.unorderedList}
              >
                {row.resultingDegrees &&
                  row.resultingDegrees.map((resultingDegree, i) => {
                    return (
                      <li key={i}>{getResultingDegree(resultingDegree)}</li>
                    );
                  })}
              </ul>
              <AuditInfo
                isEdited={row.resultingDegreesMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell align={"left"} className={getRowClass(row.isActive)}>
              {row.researchTopic}
              <AuditInfo
                isEdited={row.researchTopicMod}
                isActive={row.isActive}
              />
            </MuiTableCell>
            <MuiTableCell
              align={"left"}
              classes={{ body: classes.tableCellBody }}
              className={classes.actionButton}
            >
              {
                <AddRemoveActionIcons
                  row={row}
                  isRowActive={row.isActive}
                  rowId={row._links.self.href.split("/").pop()}
                  editPermission={row._links.editSubmissionOutcomeDetails}
                  removePermission={row._links.removeSubmissionOutcome}
                  addPermission={row._links.addOutcome}
                  toggleActive={toggleActive}
                  handleConfirmation={handleConfirmation}
                  editPageUrl={`/outcomes/${row._links.self.href
                    .split("/")
                    .pop()}/edit`}
                />
              }
            </MuiTableCell>
          </MuiTableRow>
        ))
      ) : (
        <MuiTableRow className="noData">
          <MuiTableCell colSpan="10">
            <MuiTypography
              variant="h6"
              color="inherit"
              align="center"
              paragraph={false}
              classes={{ h6: classes.noDataTableCell }}
            >
              {t("outcomes.mainView.list.noData")}
            </MuiTypography>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

export default OutcomesDetailsTableBody;
