import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginLeft: "5px",
  },
  checkBoxWrapper: {
    marginBottom: "15px",
  },
  formControlLabelRoot: {
    marginLeft: "0px",
    "&&": {
      "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
        color: theme.palette.grey[700],
      },
    },
  },
  checkBoxRoot: {
    border: theme.palette.grey[500],
    backgroundColor: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
      fill: "gray",
      backgroundColor: theme.palette.common.white,
      border: theme.palette.grey[500],
    },
  },
}));
