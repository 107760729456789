import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      width: 594,
      maxHeight: 460,
    },
    "& *": {
      fontFamily: "Source Sans Pro, sans-serif",
    },
    "&.applicantDialogBox .MuiDialog-paper": {
      maxHeight: "unset",
    },
  },
  dialogContent: {
    padding: 0,
    borderBottom: 0,
    "& p": {
      margin: 0,
    },
    "& .modalBox": {
      "& tbody tr td:nth-of-type(2)": {
        maxWidth: 250,
      },
      "& tbody tr td:first-child": {
        width: 50,
      },
    },
    "& .applicantDialogBoxTable": {
      "& tbody tr td:first-child": {
        width: "40%",
        backgroundColor: "#F0F4F5",
        color: "#666666",
      },
    },
  },
  dialogTitle: {
    minHeight: 50,
    padding: "10px 20px",
    "& .MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
    },
    "& p": {
      fontWeight: 500,
    },
  },
  dialogAction: {
    height: 40,
    "& .MuiButton-label": {
      color: "#009ABB",
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));
