import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tableContainerRoot: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },

  tableCellHeadBoolean: {
    //width: "5%",
  },
  noDataTableCell: {
    verticalAlign: "middle",
    textAlign: "center",
    color: theme.palette.grey[500],
  },
  noDataTableheading: {
    color: theme.palette.grey[500],
  },
  actionButton: {
    padding: "5px 0px 0px 0px",
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
      padding: "8px",
    },
  },
  disableRow: {
    color: theme.palette.grey[300],
  },
}));
