import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// **** Material UI Components ****
import { FormControl as MuiFormControl } from "@material-ui/core";

// **** Custom Components and Services ****
import { ResetFilter } from "../../../Layout/FilterPanel/ResetFilter";
import { STORAGE_TYPE, STORAGE_NAMES } from "../../../../constants";
import { Autocomplete } from "../../../UI/Autocomplete/Autocomplete";
import { AutocompleteOption } from "../../../../Utils/Autocomplete";
import { useCommonStyles } from "../../../shared/common.styles";
/** Services */
import { getSubmissionsFiltersOptionValues } from "../../../../services/submissionService";
import { useManageSubmissionsContext } from "../../../../services/manageSubmissionsContext";
import { useBrowserStorage } from "../../../../services/StorageService/StorageHelper";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";

// ************************* SubmissionFilter *************************

export const SubmissionFilter = () => {
  const { t } = useTranslation();
  const { saveItem } = useBrowserStorage(STORAGE_TYPE.LOCAL);
  const commonClasses = useCommonStyles();
  // **** Pre-fetch Filter Option Values ****
  const [submissionYearList, setSubmissionYearList] = useState([]);
  const [submissionAdminList, setSubmissionAdminList] = useState([]);
  const [submissionObserverList, setSubmissionObserverList] = useState([]);

  // **** Context Object ****
  const { submissionPreference, setSubmissionPreference } =
    useManageSubmissionsContext();

  const initialFilterValues = submissionPreference.manageSubmissions.filters;

  const defaultAdminFilterValue = { uid: "", name: "", profileId: "" };

  // **** Filter Fields Values ****
  const [name, setName] = useState(initialFilterValues.name);
  const [year, setYear] = useState(initialFilterValues.year);
  const [submissionAdmin, setSubmissionAdmin] = useState(
    initialFilterValues.submissionAdmin
  );
  const [observer, setObserver] = useState(initialFilterValues.observer);

  const { setAlert, clearAlert } = useAlertContext();

  // *** Helper Function to Update Context Object, on Field Value Update ***
  const setFilterPreference = (fieldUpdate) => {
    const updatedFilters = {
      ...submissionPreference,
      manageSubmissions: {
        ...submissionPreference.manageSubmissions,
        filters: {
          ...submissionPreference.manageSubmissions.filters,
          ...fieldUpdate,
        },
        pagination: {
          ...submissionPreference.manageSubmissions.pagination,
          page: 0,
        },
      },
    };
    setSubmissionPreference(updatedFilters);
  };

  // Reset Form Values
  const resetSubmissionFilters = () => {
    const initialFilterValues = {
      name: "",
      year: "",
      submissionAdmin: "",
      observer: "",
    };
    setName("");
    setYear("");
    setSubmissionAdmin("");
    setObserver("");
    setFilterPreference(initialFilterValues);

    saveItem(STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS, initialFilterValues);
  };

  // ** On Page Load or Delete Submission, reset Filter Values **
  useEffect(() => {
    getSubmissionsFiltersOptionValues(
      setSubmissionYearList,
      setSubmissionAdminList,
      setSubmissionObserverList,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [submissionPreference.manageSubmissions.delete]);

  // *** Set Object for Submission Admin and Observer from URL Param Value ***
  const filteredSubmissionAdminObject =
    submissionAdminList.filter(
      (value) => submissionAdmin.uid === value.uid
    )[0] || defaultAdminFilterValue;

  const filteredSubmissionObserverObject =
    submissionObserverList.filter((value) => observer.uid === value.uid)[0] ||
    defaultAdminFilterValue;

  return (
    <div>
      <MuiFormControl>
        <Autocomplete
          id="submissionNameAutoComplete"
          key="submission-names"
          getOptionLabel={(option) => option?.displayText ?? ""}
          getOptionSelected={(option, value) => option === value}
          value={name ?? {}}
          label={t("submission.filterPanelItems.options.submissionName.label")}
          onChange={(e, value) => {
            setName(value || "");
            setFilterPreference({
              name: value || "",
            });
          }}
          autoCompleteUrlPrefix={`/submissions/search?`}
          renderOption={(option, { inputValue }) => (
            <AutocompleteOption
              classes={commonClasses}
              option={option}
              inputValue={inputValue}
            />
          )}
        />
      </MuiFormControl>

      <MuiFormControl>
        <Autocomplete
          id="submissionYear"
          options={submissionYearList || []}
          getOptionLabel={(option) => option || ""}
          getOptionSelected={(option, value) => option === value}
          value={year}
          clientSide={true}
          label={t("submission.filterPanelItems.options.submissionYear.label")}
          onChange={(event, value) => {
            setYear(value || "");
            setFilterPreference({ year: `${value || ""}` });
          }}
        />
      </MuiFormControl>

      <MuiFormControl>
        <Autocomplete
          id="submissionAdmin"
          options={submissionAdminList || []}
          getOptionLabel={(option) =>
            option.name ? `${option.name} (${option.uid})` : ""
          }
          getOptionSelected={(option, value) => option.uid === value.uid}
          value={{ ...filteredSubmissionAdminObject }}
          clientSide={true}
          label={t("submission.filterPanelItems.options.submissionAdmin.label")}
          onChange={(event, value) => {
            setSubmissionAdmin(value || "");
            setFilterPreference({
              submissionAdmin: value || "",
            });
          }}
        />
      </MuiFormControl>
      <MuiFormControl>
        <Autocomplete
          id="observerId"
          options={submissionObserverList || []}
          getOptionLabel={(option) =>
            option.name ? `${option.name} (${option.uid})` : ""
          }
          getOptionSelected={(option, value) => option.uid === value.uid}
          value={{ ...filteredSubmissionObserverObject }}
          clientSide={true}
          label={t("submission.filterPanelItems.options.observer.label")}
          onChange={(event, value) => {
            setObserver(value || "");
            setFilterPreference({ observer: value || "" });
          }}
        />
      </MuiFormControl>

      <ResetFilter resetFn={resetSubmissionFilters} />
    </div>
  );
};
