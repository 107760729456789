import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  inputPanelWrapper: {
    width: "100%",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  button: {
    height: "40px",
    alignSelf: "flex-end",
    marginLeft: "24px",
  },
});
