import { useTranslation } from "react-i18next";
import {
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableSortLabel as MuiTableSortLabel,
} from "@material-ui/core";
/** Styles */
import { useStyles } from "./FacultyDetails.styles";

const FacultyDetailsTableHead = ({ order, orderBy, onRequestSort }) => {
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell style={{ width: "30px" }} />
        <MuiTableCell
          align="left"
          key="facultyFullName"
          style={{ width: "150px" }}
        >
          <MuiTableSortLabel
            direction={order}
            className={classes.sortLabel}
            onClick={createSortHandler("fullName")}
            active={orderBy === "fullName"}
          >
            {t("faculty.mainView.list.tableColumns.name")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="orcidId">
          <MuiTableSortLabel
            direction={order}
            className={classes.sortLabel}
            onClick={createSortHandler("orcidId")}
            active={orderBy === "orcidId"}
          >
            {t("faculty.mainView.list.tableColumns.orcidID")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="commonsID">
          <MuiTableSortLabel
            direction={order}
            className={classes.sortLabel}
            onClick={createSortHandler("commonsId")}
            active={orderBy === "commonsId"}
          >
            {t("faculty.mainView.list.tableColumns.commonsID")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="email">
          <MuiTableSortLabel
            direction={order}
            className={classes.sortLabel}
            onClick={createSortHandler("email")}
            active={orderBy === "email"}
          >
            {t("faculty.mainView.list.tableColumns.email")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="degrees">
          {t("faculty.mainView.list.tableColumns.degree")}
        </MuiTableCell>
        <MuiTableCell align="left" key="ranks">
          <MuiTableSortLabel
            direction={order}
            className={classes.sortLabel}
            onClick={createSortHandler("rank")}
            active={orderBy === "rank"}
          >
            {t("faculty.mainView.list.tableColumns.rank")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="departments">
          <MuiTableSortLabel
            direction={order}
            className={classes.sortLabel}
            onClick={createSortHandler("departments")}
            active={orderBy === "departments"}
          >
            {t("faculty.mainView.list.tableColumns.primaryDepartment")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="researchInterest">
          <MuiTableSortLabel
            className={classes.sortLabel}
            direction={order}
            onClick={createSortHandler("researchInterest")}
            active={orderBy === "researchInterest"}
          >
            {t("faculty.mainView.list.tableColumns.researchInterest")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="roles" className={classes.trainingRole}>
          {t("faculty.mainView.list.tableColumns.trainingRole")}
        </MuiTableCell>
        <MuiTableCell key="actions" style={{ width: "100px" }}>
          {t("faculty.mainView.list.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default FacultyDetailsTableHead;
