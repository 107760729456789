import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// **** Material UI Components****
import {
  AddCircle as MuiAddCircleIcon,
  NavigateNext as MuiNavigateNext,
} from "@material-ui/icons";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
  MenuItem as MuiMenuItem,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Grid as MuiGrid,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import { applicationTypeDropDown, submissionType } from "../../constants";
import EditableTable from "./EditableTable";
import { useBackButtonCallback } from "../../Utils/useBackButtonCallback";
/** Styles */
import { useStyles } from "./CreateOutcome.styles";
import { FormButton } from "../UI/Button/FormButton";

// ************ CreateApplicant Component ***********
export const CreateOutcome = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // *********************** State Values ***********************
  // Initial Form State
  const [outcomeFormValues] = useState({
    traineeName: null,
  });

  // **** Form Validation Schema ****
  const OutcomeFormValidation = Yup.object().shape({
    traineeName: Yup.string().required("Required"),
  });

  const yearOptions = [];
  /** 
  redirect on cancel button click
  */
  const onCancelHandler = useCallback(() => {
    props.history.push("/outcomes");
  }, [props.history]);

  // Handle back button
  useBackButtonCallback(onCancelHandler);

  return (
    <div className={classes.topCreateOutcomeDivStyled}>
      <div className={classes.titleStyle}>
        <MuiBreadcrumbs
          separator={<MuiNavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to="/outcomes">{t("outcomes.title")}</Link>
          <MuiTypography color="textPrimary">
            {props.type === submissionType.create
              ? t("outcomes.create.title")
              : t("outcomes.edit.title")}
          </MuiTypography>
        </MuiBreadcrumbs>
      </div>
      <div className={classes.titleNameStyle}>
        <MuiTypography variant="h5">
          {props.type === submissionType.create
            ? t("outcomes.create.new")
            : t("outcomes.edit.title")}
        </MuiTypography>
      </div>
      <Formik
        initialValues={outcomeFormValues}
        enableReinitialize={true}
        validationSchema={OutcomeFormValidation}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(false);
        }}
      >
        {(props) => {
          const {
            values,
            isSubmitting,
            // touched,
            // errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            dirty,
          } = props;

          const tableData = {
            head: ["Training Year", "Grant", "Actions"],
            rows: [
              ["TY1", "NHTST32"],
              ["TY2", "GRM01"],
            ],
          };
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.outcomeFormDivStyled}>
                <MuiTextField
                  id="traineeName"
                  label={`${t("outcomes.create.outcomeForm.trainee")}*`}
                  value={values.traineeName || ""}
                  onChange={handleChange("traineeName")}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={classes.singleFieldStyle}
                  autoComplete="off"
                />
                <MuiAutocomplete
                  options={
                    Object.entries(applicationTypeDropDown).map(
                      ([key, value]) => {
                        return {
                          key: key,
                          displayValue: t(value),
                        };
                      }
                    ) || []
                  }
                  getOptionLabel={(option) => option.displayValue}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={`${t("outcomes.create.outcomeForm.type")}*`}
                      variant="outlined"
                    />
                  )}
                  id="type"
                  className={classes.singleFieldStyle}
                  value={values.type}
                  clearOnEscape={true}
                  closeIcon={false}
                  onBlur={handleBlur}
                  onChange={(e, value) => {
                    if (value) {
                      props.setFieldValue("type", value.key);
                    }
                  }}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label>{t("outcomes.create.outcomeForm.degrees")}</label>
                  <MuiGrid
                    container
                    className={classes.singleFieldStyle}
                    spacing={1}
                  >
                    <MuiGrid item md={7}>
                      <MuiTextField
                        select
                        id="degree"
                        label={t("outcomes.create.outcomeForm.degree")}
                        value={values.degree}
                        onChange={handleChange("degree")}
                        variant="outlined"
                        fullWidth
                      >
                        {yearOptions.map((option) => (
                          <MuiMenuItem key={option} value={option}>
                            {option}
                          </MuiMenuItem>
                        ))}
                      </MuiTextField>
                    </MuiGrid>
                    <MuiGrid item md={4}>
                      <MuiTextField
                        select
                        id="year"
                        label={t("outcomes.create.outcomeForm.year")}
                        value={values.year}
                        onChange={handleChange("year")}
                        variant="outlined"
                        fullWidth
                      >
                        {yearOptions.map((option) => (
                          <MuiMenuItem key={option} value={option}>
                            {option}
                          </MuiMenuItem>
                        ))}
                      </MuiTextField>
                    </MuiGrid>
                    <MuiGrid item md={1}>
                      <MuiTooltip
                        title={t("globals.list.actionIcons.add.MuiTooltip")}
                      >
                        <MuiIconButton>
                          <Link className={classes.grayLink} aria-label="add">
                            <MuiAddCircleIcon />
                          </Link>
                        </MuiIconButton>
                      </MuiTooltip>
                    </MuiGrid>
                  </MuiGrid>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className={classes.singleFieldStyle}>
                    <MuiTextField
                      select
                      id="mentor"
                      label={t("outcomes.create.outcomeForm.mentor")}
                      value={values.mentor}
                      onChange={handleChange("mentor")}
                      variant="outlined"
                      fullWidth
                    >
                      {yearOptions.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </div>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MuiKeyboardDatePicker
                    inputVariant="outlined"
                    label={`${t("trainee.edit.traineeForm.trainingStartDate")}`}
                    name="appointmentStartDate"
                    inputProps={{ autoComplete: "off" }}
                    invalidDateMessage={`${t(
                      "trainee.edit.traineeForm.invalidDateFormat"
                    )}`}
                    clearable
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline-start-from"
                    className={`${classes.singleFieldStyle} ${classes.marginZero}`}
                    minDateMessage={`${t(
                      "trainee.edit.traineeForm.minDateMessage"
                    )}`}
                    value={values.appointmentStartDate}
                    onChange={(date) => {
                      props.setFieldValue("appointmentStartDate", date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MuiKeyboardDatePicker
                    inputVariant="outlined"
                    className={`${classes.singleFieldStyle} ${classes.marginZero}`}
                    label={`${t("trainee.edit.traineeForm.trainingEndDate")}`}
                    name="appointmentEndDate"
                    inputProps={{ autoComplete: "off" }}
                    invalidDateMessage={`${t(
                      "trainee.edit.traineeForm.invalidDateFormat"
                    )}`}
                    clearable
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline-end-from"
                    minDateMessage={`${t(
                      "trainee.edit.traineeForm.minDateMessage"
                    )}`}
                    minDate={values.appointmentStartDate || undefined}
                    value={values.appointmentEndDate}
                    onChange={(date) => {
                      props.setFieldValue("appointmentEndDate", date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label>
                    {t("outcomes.create.outcomeForm.summarySupport")}
                  </label>
                  <MuiGrid
                    container
                    spacing={1}
                    className={classes.singleFieldStyle}
                  >
                    <MuiGrid item md={4}>
                      <MuiTextField
                        id="trainingYear"
                        label={t("outcomes.create.outcomeForm.trainingYear")}
                        value={values.trainingYear || ""}
                        onChange={handleChange("trainingYear")}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid item md={7}>
                      <MuiTextField
                        id="grant"
                        label={t("outcomes.create.outcomeForm.grant")}
                        value={values.grant || ""}
                        onChange={handleChange("grant")}
                        onBlur={handleBlur}
                        variant="outlined"
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid item md={1}>
                      <MuiTooltip
                        title={t("globals.list.actionIcons.add.MuiTooltip")}
                      >
                        <MuiIconButton>
                          <Link className={classes.grayLink} aria-label="add">
                            <MuiAddCircleIcon />
                          </Link>
                        </MuiIconButton>
                      </MuiTooltip>
                    </MuiGrid>
                  </MuiGrid>
                </MuiPickersUtilsProvider>

                <div className={classes.singleFieldStyle}>
                  {EditableTable(tableData)}
                </div>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <label>
                    {t("outcomes.create.outcomeForm.resultingDegree")}
                  </label>
                  <MuiGrid
                    container
                    spacing={1}
                    className={classes.singleFieldStyle}
                  >
                    <MuiGrid item md={7}>
                      <MuiTextField
                        select
                        id="degree"
                        label={t("outcomes.create.outcomeForm.degree")}
                        value={values.degree}
                        onChange={handleChange("degree")}
                        variant="outlined"
                        fullWidth
                      >
                        {yearOptions.map((option) => (
                          <MuiMenuItem key={option} value={option}>
                            {option}
                          </MuiMenuItem>
                        ))}
                      </MuiTextField>
                    </MuiGrid>
                    <MuiGrid item md={4}>
                      <MuiTextField
                        select
                        id="year"
                        label={t("outcomes.create.outcomeForm.year")}
                        value={values.year}
                        onChange={handleChange("year")}
                        variant="outlined"
                        fullWidth
                      >
                        {yearOptions.map((option) => (
                          <MuiMenuItem key={option} value={option}>
                            {option}
                          </MuiMenuItem>
                        ))}
                      </MuiTextField>
                    </MuiGrid>
                    <MuiGrid item md={1}>
                      <MuiTooltip
                        title={t("globals.list.actionIcons.add.MuiTooltip")}
                      >
                        <MuiIconButton>
                          <Link className={classes.grayLink} aria-label="add">
                            <MuiAddCircleIcon />
                          </Link>
                        </MuiIconButton>
                      </MuiTooltip>
                    </MuiGrid>
                  </MuiGrid>
                </MuiPickersUtilsProvider>

                <MuiTextField
                  id="researchTopic"
                  label={t("outcomes.create.outcomeForm.researchTopic")}
                  value={values.researchTopic || ""}
                  onChange={handleChange("researchTopic")}
                  onBlur={handleBlur}
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                  className={classes.singleFieldStyle}
                  autoComplete="off"
                />

                <MuiGrid container className={classes.summaryStyle} spacing={2}>
                  <MuiGrid item md={6}>
                    <label>
                      {t("outcomes.create.outcomeForm.initialSummary")}
                    </label>
                    <MuiTextField
                      id="position"
                      label={t("outcomes.create.outcomeForm.position")}
                      value={values.position || ""}
                      onChange={handleChange("position")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="department"
                      label={t("outcomes.create.outcomeForm.department")}
                      value={values.department || ""}
                      onChange={handleChange("department")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="institution"
                      label={t("outcomes.create.outcomeForm.institution")}
                      value={values.institution || ""}
                      onChange={handleChange("institution")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="activity"
                      label={t("outcomes.create.outcomeForm.activity")}
                      value={values.activity || ""}
                      onChange={handleChange("activity")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                  </MuiGrid>
                  <MuiGrid item md={6}>
                    <label>
                      {t("outcomes.create.outcomeForm.currentSummary")}
                    </label>
                    <MuiTextField
                      id="position"
                      label={t("outcomes.create.outcomeForm.position")}
                      value={values.position || ""}
                      onChange={handleChange("position")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="department"
                      label={t("outcomes.create.outcomeForm.department")}
                      value={values.department || ""}
                      onChange={handleChange("department")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="institution"
                      label={t("outcomes.create.outcomeForm.institution")}
                      value={values.institution || ""}
                      onChange={handleChange("institution")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                    <MuiTextField
                      id="activity"
                      label={t("outcomes.create.outcomeForm.activity")}
                      value={values.activity || ""}
                      onChange={handleChange("activity")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                  </MuiGrid>
                </MuiGrid>
                <label>{t("outcomes.create.outcomeForm.subsGrant")}</label>

                <MuiGrid container spacing={1}>
                  <MuiGrid item md={4}>
                    <MuiTextField
                      id="outcomeGrant"
                      label={t("outcomes.create.outcomeForm.grant")}
                      value={values.outcomeGrant || ""}
                      onChange={handleChange("outcomeGrant")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                  </MuiGrid>
                  <MuiGrid item md={5}>
                    <MuiTextField
                      id="role"
                      label={t("outcomes.create.outcomeForm.role")}
                      value={values.role || ""}
                      onChange={handleChange("role")}
                      onBlur={handleBlur}
                      variant="outlined"
                      inputProps={{ maxLength: 200 }}
                      fullWidth
                      autoComplete="off"
                    />
                  </MuiGrid>
                  <MuiGrid item md={2}>
                    <MuiTextField
                      select
                      id="outcomeYear"
                      label={t("outcomes.create.outcomeForm.year")}
                      value={values.outcomeYear}
                      onChange={handleChange("outcomeYear")}
                      variant="outlined"
                      fullWidth
                    >
                      {yearOptions.map((option) => (
                        <MuiMenuItem key={option} value={option}>
                          {option}
                        </MuiMenuItem>
                      ))}
                    </MuiTextField>
                  </MuiGrid>

                  <MuiGrid item md={1}>
                    <MuiTooltip
                      title={t("globals.list.actionIcons.add.MuiTooltip")}
                    >
                      <MuiIconButton>
                        <Link className={classes.grayLink} aria-label="add">
                          <MuiAddCircleIcon />
                        </Link>
                      </MuiIconButton>
                    </MuiTooltip>
                  </MuiGrid>
                </MuiGrid>

                <div className={classes.actionFlexStyled}>
                  <FormButton
                    onClick={onCancelHandler}
                    name={t("globals.form.actionButtons.cancel")}
                    variant="outlined"
                  />

                  <FormButton
                    name={t("globals.form.actionButtons.save")}
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !dirty || !isValid}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateOutcome;
