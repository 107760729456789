import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import i18n from "../i18n";

export const moreItemsOption = (nResults) => ({
  value: {},
  displayText: i18n.t("globals.autocomplete.moreItemsText", {
    nResults: nResults,
  }),
  isDummy: true,
});

export const searchTooShortOption = (nMinChars) => ({
  value: {},
  displayText: i18n.t("globals.autocomplete.searchTooShortText", {
    nMinChars: nMinChars,
  }),
  isDummy: true,
});

export const AutocompleteOption = ({
  option,
  inputValue,
  classes,
  children,
}) => {
  const parts = getMatchedParts(option, inputValue);
  const secondaryParts = children
    ? getMatchedChildrenParts(children, inputValue)
    : [];

  let className = "";
  if (classes) {
    className = option.isDummy
      ? classes.dummyAutocompleteOption
      : classes.autocompleteOption;
  }

  return (
    <div className={className}>
      <div>
        {parts.map((part, index) => {
          return (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          );
        })}
      </div>
      <div>
        {secondaryParts &&
          secondaryParts.map((part, index) => {
            return (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                  whiteSpace: "pre",
                }}
              >
                {part.text}
              </span>
            );
          })}
      </div>
    </div>
  );
};

const getMatchedChildrenParts = (children, inputValue) => {
  let childrenMatches;

  if (children) {
    childrenMatches = match(children, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });

    return parse(children, childrenMatches);
  }
};

const getMatchedParts = (option, inputValue) => {
  let matches;

  if (!!option.isDummy) {
    return [
      {
        highlight: false,
        text: option.displayText,
      },
    ];
  }

  if (!option.value.prefferedFullName) {
    matches = match(option.displayText, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });
    return parse(option.displayText, matches);
  }

  // [TGDS-3306] added highlighting on preferred name
  if (option.value.prefferedFullName) {
    matches = match(option.value.prefferedFullName, inputValue, {
      insideWords: true,
      findAllOccurrences: true,
    });
    return parse(option.value.prefferedFullName, matches);
  }
};
