import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Paper as MuiPaper,
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Typography as MuiTypography,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import { CloudDownload as MuiCloudDownloadIcon } from "@material-ui/icons";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
/** Services */
import {
  getOutputSubmissionsList,
  getOutputTable8A,
  getExportData,
} from "../../services/outputService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./OutputTable8.styles";
import { StyledTableCell } from "../UI/Table/Table.styles";
import { useCommonStyles } from "../shared/common.styles";

export const Table8A = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [table8Data, setTable8Data] = useState([]);
  const [loading, setLoading] = useState(false);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const selectedSubmission = getSelectedSubmission();

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
  }, [clearAlert, setAlert]);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
  };

  // Build Tab Data
  useEffect(() => {
    getOutputTable8A(
      selectedSubmission,
      setTable8Data,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [selectedSubmission, setAlert, clearAlert]);

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      "table_8A",
      "table-eight-a",
      selectedSubmission,
      setAlert,
      clearAlert
    );
  };

  return (
    <MuiGrid container direction="column">
      {alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <MuiGrid item classes={{ item: commonClasses.outputAutoCompleteWrapper }}>
        {/* Submission Select Box */}
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        item
        container
        justify="flex-end"
        className={classes.actionButton}
      >
        <MuiButton
          variant="outlined"
          onClick={handleExport}
          disabled={table8Data.length === 0}
        >
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>
      <MuiGrid item className={classes.noBold}>
        {/* Table 8 Output */}
        <MuiTableContainer className={classes.container} component={MuiPaper}>
          <MuiTable
            stickyHeader
            className={classes.table1}
            size="small"
            area-label="PREDOC"
          >
            <>
              <TableHeadPredoc />
              <TableBodyPredoc table8Data={table8Data} loading={loading} />
            </>
          </MuiTable>
          {loading && (
            <MuiBox sx={{ width: "100%" }}>
              <MuiLinearProgress />
            </MuiBox>
          )}
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};

const TableHeadPredoc = (props) => {
  const { t } = useTranslation();

  return (
    <MuiTableHead>
      <MuiTableRow>
        <StyledTableCell width="15%">
          {t("output.mainView.list.tableColumns.table8.predoctoral.trainee")}
        </StyledTableCell>
        <StyledTableCell width="15%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.facultyMember"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t("output.mainView.list.tableColumns.table8.predoctoral.startDate")}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.summaryOfSupport"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.terminalDegreeReceivedYear"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.topicOfResearchProject"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.initialPosition"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.currentPosition"
          )}
        </StyledTableCell>
        <StyledTableCell width="10%">
          {t(
            "output.mainView.list.tableColumns.table8.predoctoral.subsequentGrant"
          )}
        </StyledTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

const TableBodyPredoc = (props) => {
  const { table8Data, loading } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MuiTableBody>
      {loading ? (
        <MuiTableRow>
          <StyledTableCell colSpan="9">
            <MuiTypography
              variant="h6"
              color="textSecondary"
              align="center"
              paragraph={false}
            >
              {t("globals.list.messages.fetchingInfo")}
            </MuiTypography>
          </StyledTableCell>
        </MuiTableRow>
      ) : table8Data.length > 0 ? (
        table8Data.map((outcome) => (
          <MuiTableRow key={outcome.submissionOutcomeId}>
            <StyledTableCell width="15%">{outcome.traineeName}</StyledTableCell>
            <StyledTableCell width="15%">
              <OutcomeMultiValueSummary items={outcome.facultyMembers} />
            </StyledTableCell>
            <StyledTableCell width="10%">{outcome.startDate}</StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary items={outcome.trainingSupportYears} />
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary items={outcome.terminalDegrees} />
            </StyledTableCell>
            <StyledTableCell width="10%">
              {outcome.researchTopic}
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary
                items={outcome.initialPositionSummary}
              />
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary
                items={outcome.currentPositionSummary}
              />
            </StyledTableCell>
            <StyledTableCell width="10%">
              <OutcomeMultiValueSummary items={outcome.subsequentGrants} />
            </StyledTableCell>
          </MuiTableRow>
        ))
      ) : (
        <MuiTableRow className="noData">
          <MuiTableCell
            colSpan="9"
            align="center"
            classes={{
              body: classes.noDataTableCell,
            }}
          >
            {t("globals.list.messages.noData")}
          </MuiTableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

const OutcomeMultiValueSummary = (props) => {
  const classes = useStyles();

  const { items } = props;

  if (items) {
    return (
      <ul style={{ listStyleType: "none" }} className={classes.unorderedList}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    );
  } else {
    return "";
  }
};
