import {
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";

export const Spinner = (props) => (
  <MuiBackdrop open={props.visible} style={{ backgroundColor: "transparent" }}>
    <MuiCircularProgress />
  </MuiBackdrop>
);
