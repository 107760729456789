import { makeStyles } from "@material-ui/core/styles";

export const useTraineeStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  button: {
    height: "40px",
    alignSelf: "flex-end",
    marginLeft: "24px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "16px",
    "&:hover": {
      borderRadius: "5px",
      background: theme.palette.primary.dark,
      fontSize: "16px",
      color: "ffffff",
    },
  },
  inputPanelWrapper: {
    width: "100%",
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    color: theme.palette.grey[800],
  },
}));
