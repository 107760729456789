import { useTranslation } from "react-i18next";
import {
  TableHead as MuiTableHead,
  TableSortLabel as MuiTableSortLabel,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@material-ui/core";
import { useStyles } from "./GrantDetails.styles";

const GrantsDetailsTableHead = ({ order, orderBy, onRequestSort }) => {
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <MuiTableHead>
      <MuiTableRow>
        <MuiTableCell className={classes.scroll30} />
        <MuiTableCell
          align="left"
          key="spoNumber"
          className={classes.scroll90}
          sortDirection={orderBy === "spoNumber" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("spoNumber")}
            active={orderBy === "spoNumber"}
          >
            {t("grant.mainView.list.tableColumns.spoNumber")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="title"
          className={classes.scroll246}
          sortDirection={orderBy === "title" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "title" ? order : "asc"}
            onClick={createSortHandler("title")}
            active={orderBy === "title"}
          >
            {t("grant.mainView.list.tableColumns.title")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="awardNumber"
          className={classes.scroll90}
          sortDirection={orderBy === "awardNumber" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("grantNumber")}
            active={orderBy === "grantNumber"}
          >
            {t("grant.mainView.list.tableColumns.awardNumber")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="projectPeriod"
          sortDirection={orderBy === "projectPeriod" ? order : "asc"}
          className={classes.scroll80}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("startDate")}
            active={orderBy === "startDate"}
          >
            {t("grant.mainView.list.tableColumns.projectPeriod")}
          </MuiTableSortLabel>
        </MuiTableCell>

        <MuiTableCell
          align="left"
          key="preDocsPositions"
          sortDirection={orderBy === "preDocsPositions" ? order : "asc"}
          className={classes.scroll80}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("predocPositionsCount")}
            active={orderBy === "predocPositionsCount"}
          >
            {t("grant.mainView.list.tableColumns.preDocsPositions")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="postDocsPositions"
          sortDirection={orderBy === "postDocsPositions" ? order : "asc"}
          className={classes.scroll84}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("postdocPositionsCount")}
            active={orderBy === "postdocPositionsCount"}
          >
            {t("grant.mainView.list.tableColumns.postDocsPositions")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="shortTermPositions"
          sortDirection={orderBy === "shortTermPositions" ? order : "asc"}
          className={classes.scroll72}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("numberOfShortTermPositions")}
            active={orderBy === "numberOfShortTermPositions"}
          >
            {t("grant.mainView.list.tableColumns.shortTermPositions")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="budgetPeriodStartDate"
          sortDirection={orderBy === "budgetPeriodStartDate" ? order : "asc"}
          className={classes.scroll80}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("budgetPeriodStartDate")}
            active={orderBy === "budgetPeriodStartDate"}
          >
            {t("grant.mainView.list.tableColumns.currentBudgetPeriod")}
          </MuiTableSortLabel>
        </MuiTableCell>

        <MuiTableCell
          align="left"
          key="budgetPeriodDirectCost"
          sortDirection={orderBy === "budgetPeriodDirectCost" ? order : "asc"}
          className={classes.scroll90}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("budgetPeriodDirectCost")}
            active={orderBy === "budgetPeriodDirectCost"}
          >
            {t("grant.mainView.list.tableColumns.budgetPeriodDirectCost")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="budgetPeriodTotalCost"
          sortDirection={orderBy === "budgetPeriodTotalCost" ? order : "asc"}
          className={classes.scroll90}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("budgetPeriodTotalCost")}
            active={orderBy === "budgetPeriodTotalCost"}
          >
            {t("grant.mainView.list.tableColumns.budgetPeriodTotalCost")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="department"
          className={classes.scroll154}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("department")}
            active={orderBy === "department"}
          >
            {t("grant.mainView.list.tableColumns.department")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="grantYear" className={classes.scroll60}>
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("grantYear")}
            active={orderBy === "grantYear"}
          >
            {t("grant.mainView.list.tableColumns.grantYear")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          className={classes.scroll108}
          align="left"
          key="participatingFaculty"
          sortDirection={orderBy === "participatingFaculty" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={order || "asc"}
            onClick={createSortHandler("numberOfParticipatingFaculty")}
            active={orderBy === "numberOfParticipatingFaculty"}
          >
            {t("grant.mainView.list.tableColumns.participatingFaculty")}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="overLappingFaculty"
          sortDirection={orderBy === "overLappingFaculty" ? order : "asc"}
          className={classes.scroll103}
        >
          {t("grant.mainView.list.tableColumns.overLappingFaculty")}
        </MuiTableCell>
        <MuiTableCell key="actions" align={"left"} className={classes.scroll90}>
          {t("grant.mainView.list.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default GrantsDetailsTableHead;
