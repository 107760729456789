import { useTranslation } from "react-i18next";
import {
  Delete as MuiDeleteIcon,
  Edit as MuiEditIcon,
} from "@material-ui/icons";
import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Paper as MuiPaper,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import { useStyles } from "./CreateOutcome.styles";

const EditableTable = (tableData) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { head, rows } = tableData;

  return (
    <MuiTableContainer className={`${classes.marginZero}`} component={MuiPaper}>
      <MuiTable aria-label="customized table">
        <MuiTableHead className={classes.thStyle}>
          <MuiTableRow>
            {head.map((item) => {
              return <MuiTableCell align="left">{item}</MuiTableCell>;
            })}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {rows.map((row) => {
            return (
              <MuiTableRow>
                {row.map((item) => {
                  return (
                    <MuiTableCell align="left">
                      <MuiTypography>{item}</MuiTypography>
                    </MuiTableCell>
                  );
                })}

                <MuiTableCell
                  align="left"
                  className={classes.cellPadding}
                  width="20%"
                >
                  <span>
                    <span>
                      <MuiTooltip
                        title={t("globals.list.actionIcons.edit.tooltip")}
                      >
                        <MuiIconButton>
                          <MuiEditIcon />
                        </MuiIconButton>
                      </MuiTooltip>
                    </span>
                    <span>
                      <MuiTooltip
                        title={t("globals.list.actionIcons.remove.tooltip")}
                      >
                        <MuiIconButton>
                          <MuiDeleteIcon />
                        </MuiIconButton>
                      </MuiTooltip>
                    </span>
                  </span>
                </MuiTableCell>
              </MuiTableRow>
            );
          })}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

export default EditableTable;
