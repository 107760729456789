import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actionButton: {
    fontSize: "16px",
  },
  orcidId: {
    "&&": {
      paddingTop: "18px",
    },
  },
  routeLink: {
    "&&": {
      color: "gray",
      "&:visited": {
        color: "gray",
      },
    },
  },
  toolTip: {
    fontSize: "14px",
    maxWidth: "500px",
  },
  tooltipLink: {
    "&&": {
      fontWeight: "bold",
      color: theme.palette.common.white,
      "&:visited": {
        color: theme.palette.common.white,
      },
    },
  },
}));
