import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  Grid as MuiGrid,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import { CloudDownload as MuiCloudDownloadIcon } from "@material-ui/icons";
/** Custom Components */
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import CustomTabs from "../UI/Tabs/Tabs";
import CustomTable from "../UI/Table/Table";
import CustomDialog from "../UI/Dialog/Dialog";
/** Services */
import {
  getOutputSubmissionsList,
  getOutputTable1,
  getOutputTable1PopUp,
  getExportData,
} from "../../services/outputService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";
/** Styles */
import {
  TopInputPanel,
  OutputActionDivStyled,
  SubmissionsDivStyled,
  ActionButtonContainer,
  ActionButton,
} from "./OutputTable3.styles";
import { useStyles } from "./OutputTable1.styles";

/**
 * Filter Table Data.
 *
 * @param data
 *   Response data received from API.
 */
const filterTableData = (data, t) => {
  const response = {};

  // XXX - @vbarod remove this map operation once you've added the missing Dept Code
  data = data?.map((r) => {
    if (
      r["outputTableOneDto"]["departmentCode"] === "" &&
      r["outputTableOneDto"]["participatingDeptOrProgram"] !== "TOTAL"
    )
      r["outputTableOneDto"]["departmentCode"] = "UNKNOWN_DEPARTMENT";
    return r;
  });

  // Check if data returned from API Call.
  data?.forEach((record) => {
    const entry = record["outputTableOneDto"],
      departmentCode = entry["departmentCode"],
      departmentId = `departmentId - ${departmentCode}`, //API Changes
      totalDocsByHhs = entry["totalDoctoratesSupportByHhsAward"] ?? 0,
      totalFaculty = entry["totalFaculty"] ?? "-",
      totalDoctorates = entry["totalDoctorates"] ?? 0;

    return (response[departmentId] = {
      participatingDeptOrProgram: entry["participatingDeptOrProgram"] ?? "",
      totalFaculty: {
        value: totalFaculty,
        linkWhenZero: true,
        toolTipText:
          totalFaculty === "-"
            ? t("output.table1.tooltips.noFacultyRecords")
            : null,
      },
      participatingFaculty: entry["participatingFaculty"] ?? 0,
      totalDoctorates: {
        value: totalDoctorates,
        linkWhenZero: true,
        toolTipText:
          totalDoctorates === 0
            ? t("output.table1.tooltips.noTraineeRecords")
            : null,
      },
      totalDoctoratesSupportByHhsAward: {
        value: totalDocsByHhs,
        toolTipText:
          departmentCode && totalDocsByHhs === "-"
            ? t("output.table1.tooltips.dataComesFromAdmin")
            : null,
      },
      totalDoctoratesWithParticipatingFaculty:
        entry["totalDoctoratesWithParticipatingFaculty"] ?? 0,
      eligibleWithParticipatingFaculty:
        entry["eligibleWithParticipatingFaculty"] ?? 0,
      supportByTrainingGrantTge: entry["supportByTrainingGrantTge"] ?? 0,
      supportByTrainingGrantRninezero:
        entry["supportByTrainingGrantRninezero"] ?? 0,
    });
  });

  return response;
};

/**
 * Filter Table1 Pop up Data.
 *
 * @param data
 *   Response pop-up data received from API.
 */
const filterTable1PopUpData = (data) => {
  const response = {};
  data?.forEach((record) => {
    return (response[record["rowId"]] = {
      "#": record["rowId"] ?? "",
      name: record["fullName"] ?? "",
      sunetId: record["sunetId"] ?? "",
    });
  });
  return response;
};

const calculatePopUpDescription = (
  data,
  traineeType,
  outputTableOneEnumFilter,
  t
) => {
  if (outputTableOneEnumFilter === "totalFaculty")
    return calculateFacultyDescription(data, t);
  if (outputTableOneEnumFilter === "totalDoctorates")
    return calculateDoctorateDescription(data, traineeType, t);
  return "";
};

const calculateFacultyDescription = (data, t) => {
  if (!Array.isArray(data) || data.length === 0)
    return t("output.table1.modalDialog.totalFaculty.dataSourceDescDefault");

  let sources = [...new Set(data.map((r) => r.source))];
  if (sources.length === 1 && sources[0] === "ffac")
    return (
      <Trans i18nKey="dataSourceDescGst">
        {t("output.table1.modalDialog.totalFaculty.dataSourceDescPeopleSoft")}
        <a
          href={t(
            "output.table1.modalDialog.totalFaculty.dataSourceDescFarm.href"
          )}
          target="_blank"
          rel="noreferrer"
        >
          {t(
            "output.table1.modalDialog.totalFaculty.dataSourceDescFarm.hrefName"
          )}
          .
        </a>
      </Trans>
    );
  if (sources.length === 1 && sources[0] === "gst")
    return (
      <Trans i18nKey="dataSourceDescGst">
        {t(
          "output.table1.modalDialog.totalFaculty.dataSourceDescGst.description0"
        )}
        <a
          href={t(
            "output.table1.modalDialog.totalFaculty.dataSourceDescGst.href"
          )}
          target="_blank"
          rel="noreferrer"
        >
          {t(
            "output.table1.modalDialog.totalFaculty.dataSourceDescGst.hrefName"
          )}
          .
        </a>
        {t(
          "output.table1.modalDialog.totalFaculty.dataSourceDescGst.description1"
        )}
        <a
          href={t(
            "output.table1.modalDialog.totalFaculty.dataSourceDescFarm.href"
          )}
          target="_blank"
          rel="noreferrer"
        >
          {t(
            "output.table1.modalDialog.totalFaculty.dataSourceDescFarm.hrefName"
          )}
          .
        </a>
      </Trans>
    );
};

const calculateDoctorateDescription = (data, traineeType, t) => {
  if (
    traineeType === "PRE_DOC" &&
    (!Array.isArray(data) || data.length === 0)
  ) {
    return t("output.table1.modalDialog.totalPreDocs.dataSourceDescDefault");
  }
  if (traineeType === "PRE_DOC") {
    return t("output.table1.modalDialog.totalPreDocs.dataSourceDescPeopleSoft");
  }

  if (
    traineeType === "POST_DOC" &&
    (!Array.isArray(data) || data.length === 0)
  ) {
    return t("output.table1.modalDialog.totalPostDocs.dataSourceDescDefault");
  }
  if (traineeType === "POST_DOC") {
    return t("output.table1.modalDialog.totalPostDocs.dataSourceDescOpa");
  }
  return "";
};

export const Table1 = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [outputTableOneEnumFilter, setOutputTableOneEnumFilter] =
    useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [traineeType, setTraineeType] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [departmentName, setDepartmentName] = useState(null);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [popupData, setPopupData] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const selectedSubmission = getSelectedSubmission();

  // TODO!!!
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [dialogLoading, setDialogLoading] = useState(true);

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
  };

  /**
   * Open Modal Box or Perform any other linking functionality.
   *
   * @param departmentId
   *   Department ID.
   * @param outputTableOneEnumFilter
   *   Table enum filter.
   * @param traineeType
   *   POST-DOC|PRE-DOC.
   */

  const table1ModalOpenEventHandler = (
    departmentId,
    outputTableOneEnumFilter,
    traineeType,
    deptName
  ) => {
    const [, { participatingDeptOrProgram }] = deptName;
    setOpen(true);
    setOutputTableOneEnumFilter(outputTableOneEnumFilter);
    setDepartmentId(departmentId.split("-").pop().trim());
    setTraineeType(traineeType);
    setDepartmentName(participatingDeptOrProgram);
  };

  /**
   * Dialog Close Button Handler.
   */
  const handleCloseHandler = () => {
    setOpen(false);
  };

  /**
   * Tab Change Hanlder.
   *
   * @param event
   *   Selected Tab Event.
   * @param newValue
   *   Selected Tab Value.
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // PART I. PREDOCTORATES Header
  const predoctoratesLabel =
    "output.mainView.list.tableColumns.table1.predoctorates";
  const part1Header = {
    participatingDeptOrProgram: t(
      `${predoctoratesLabel}.participatingDeptOrProgram`
    ),
    totalFaculty: t(`${predoctoratesLabel}.totalFaculty`),
    participatingFaculty: t(`${predoctoratesLabel}.participatingFaculty`),
    totalDoctorates: t(`${predoctoratesLabel}.totalDoctorates`),
    totalDoctoratesSupportByHhsAward: t(
      `${predoctoratesLabel}.totalDoctoratesSupportByHhsAward`
    ),
    totalDoctoratesWithParticipatingFaculty: t(
      `${predoctoratesLabel}.totalDoctoratesWithParticipatingFaculty`
    ),
    eligibleWithParticipatingFaculty: t(
      `${predoctoratesLabel}.eligibleWithParticipatingFaculty`
    ),
    supportByTrainingGrantTge: t(
      `${predoctoratesLabel}.supportByTrainingGrantTge`
    ),
    supportByTrainingGrantRninezero: t(
      `${predoctoratesLabel}.supportByTrainingGrantRninezero`
    ),
  };
  // PART II. POSTDOCTORATES
  const postdoctoratesLabel =
    "output.mainView.list.tableColumns.table1.postdoctorates";
  const part2Header = {
    participatingDeptOrProgram: t(
      `${postdoctoratesLabel}.participatingDeptOrProgram`
    ),
    totalFaculty: t(`${postdoctoratesLabel}.totalFaculty`),
    participatingFaculty: t(`${postdoctoratesLabel}.participatingFaculty`),
    totalDoctorates: t(`${postdoctoratesLabel}.totalDoctorates`),
    totalDoctoratesSupportByHhsAward: t(
      `${postdoctoratesLabel}.totalDoctoratesSupportByHhsAward`
    ),
    totalDoctoratesWithParticipatingFaculty: t(
      `${postdoctoratesLabel}.totalDoctoratesWithParticipatingFaculty`
    ),
    eligibleWithParticipatingFaculty: t(
      `${postdoctoratesLabel}.eligibleWithParticipatingFaculty`
    ),
    supportByTrainingGrantTge: t(
      `${postdoctoratesLabel}.supportByTrainingGrantTge`
    ),
    supportByTrainingGrantRninezero: t(
      `${postdoctoratesLabel}.supportByTrainingGrantRninezero`
    ),
  };
  const [table1Data, setTable1Data] = useState([]);

  // Build Tab Data
  const tabType = tabValue ? "POST_DOC" : "PRE_DOC";

  useEffect(() => {
    getOutputTable1(
      selectedSubmission,
      tabType,
      setTable1Data,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [selectedSubmission, tabType]);

  const loadingHeader = { loading: t("globals.list.messages.fetchingInfo") };
  const tabBody = filterTableData(table1Data, t);
  const data = {
    "PART I. PREDOCTORATES": (
      <>
        <CustomTable
          sticky
          label="PRE_DOC"
          header={loading ? loadingHeader : part1Header}
          body={loading ? {} : tabBody}
          tableClass="table1"
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{
            totalFaculty: "value",
            totalDoctorates: "value",
            totalDoctoratesSupportByHhsAward: "value",
          }}
        />
        {loading && (
          <MuiBox sx={{ width: "100%" }}>
            <MuiLinearProgress />
          </MuiBox>
        )}
      </>
    ),
    "PART II. POSTDOCTORATES": (
      <>
        <CustomTable
          sticky
          label="POST_DOC"
          header={loading ? loadingHeader : part2Header}
          body={loading ? {} : tabBody}
          tableClass="table1"
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{
            totalFaculty: "value",
            totalDoctorates: "value",
            totalDoctoratesSupportByHhsAward: "value",
          }}
        />
        {loading && (
          <MuiBox sx={{ width: "100%" }}>
            <MuiLinearProgress />
          </MuiBox>
        )}
      </>
    ),
  };

  useEffect(() => {
    setPopupData([]);
    getOutputTable1PopUp(
      getSelectedSubmission(),
      departmentId,
      outputTableOneEnumFilter,
      traineeType,
      setPopupData,
      setAlert,
      clearAlert,
      setDialogLoading
    );
    // eslint-disable-next-line
  }, [
    getSelectedSubmission,
    departmentId,
    outputTableOneEnumFilter,
    traineeType,
    reload,
  ]);

  // Build Modal Box data
  let dialogBox = null;
  const modalBoxBody = filterTable1PopUpData(popupData);
  const modalDescription = calculatePopUpDescription(
    popupData,
    traineeType,
    outputTableOneEnumFilter,
    t
  );
  if (open) {
    const modalHeader = {
      "#": "#",
      name: t("output.modalDialog.list.tableColumns.name"),
      sunetId: t("output.modalDialog.list.tableColumns.sunetId"),
    };
    const type = traineeType === "PRE_DOC" ? "predoctorates" : "postdoctorates";
    const modalTitle =
      departmentName +
      " - " +
      t(
        "output.mainView.list.tableColumns.table1." +
          type +
          "." +
          outputTableOneEnumFilter
      );
    dialogBox = (
      <CustomDialog
        closeButtonText="CLOSE"
        title={modalTitle}
        description={modalDescription}
        open={open}
        handleClose={handleCloseHandler}
      >
        <CustomTable
          sticky
          label={modalTitle}
          header={dialogLoading ? {} : modalHeader}
          body={modalBoxBody}
          tableClass="modalBox"
          loading={dialogLoading}
        />
      </CustomDialog>
    );
  }

  /**
   * handle export function
   */
  const handleExport = () => {
    const tableName = `Table_1_${
      tabType === "PRE_DOC" ? "PREDOCTORATES" : "POSTDOCTORATES"
    }`;
    getExportData(
      tableName,
      "table-one",
      getSelectedSubmission(),
      setAlert,
      clearAlert,
      tabType
    );
  };

  return (
    <div className={classes.divStyle}>
      {alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <TopInputPanel>
        <OutputActionDivStyled>
          <SubmissionsDivStyled>
            <SubmissionSelect
              submissionList={submissionList}
              handleChangeSubmission={handleChangeSubmission}
            />
          </SubmissionsDivStyled>
        </OutputActionDivStyled>
      </TopInputPanel>
      <div className={classes.root}>
        {/* Export Button */}
        <ActionButtonContainer>
          <ActionButton
            variant="outlined"
            onClick={handleExport}
            disabled={!Object.values(tabBody).length}
          >
            <MuiCloudDownloadIcon />
            &nbsp;{t("faculty.export.exportButtonText")}
          </ActionButton>
        </ActionButtonContainer>
        {/* Table 1 Output */}
        {
          <CustomTabs
            data={data}
            handleChange={handleTabChange}
            value={tabValue}
          />
        }
        {/* Dialog Box Output */}
        {dialogBox}
      </div>
    </div>
  );
};
