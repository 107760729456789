import { useTranslation } from "react-i18next";
import {
  Typography as MuiTypography,
  Link as MuiLink,
  Grid as MuiGrid,
} from "@material-ui/core";
import { useStyles } from "./AccessDenied.styles";

export const AccessDenied = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <MuiGrid container justify="center">
      <MuiGrid item>
        <MuiGrid container justify="center" className={classes.container}>
          <MuiTypography variant={"h2"}>
            {t("accessDenied.title")}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid container justify="center">
          <MuiTypography variant={"body1"}>
            {t("accessDenied.accessDeniedMessage")}{" "}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item>
          <MuiTypography variant={"body1"}>
            {t("accessDenied.requireAccessMessage")}{" "}
            <MuiLink
              href={`mailto:${t("app.supportEmail")}`}
              underline="none"
              className={classes.root}
            >
              {t("app.supportEmail")}
            </MuiLink>
          </MuiTypography>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
};
