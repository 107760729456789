import { makeStyles } from "@material-ui/core/styles";

export const useCommonStyles = makeStyles({
  autocompleteWrapper: {
    width: "320px",
    "& .MuiFormControl-root": {
      width: "140%",
      marginTop: "10px",
      marginBottom: "5px",
    },
  },
  /** AutoComplete Submission dropdown for Output tab */
  outputAutoCompleteWrapper: {
    width: "30%",
    "& .MuiFormControl-root": {
      width: "100%",
      marginTop: "16px",
      marginBottom: "16px",
    },
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    '.MuiAutocomplete-option[aria-disabled="true"] &': {
      color: "#000", // grey text is not legible with the opacity from MUI Autocomplete
    },
  },
  autocompleteRoot: {
    "& .MuiAutocomplete-popupIndicatorOpen": {
      // Stop MUI from rotating the icon by rotating it back
      rotate: "180deg",
    },
  },
});
