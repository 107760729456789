import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink, useHistory, useParams } from "react-router-dom";
import {
  Grid as MuiGrid,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  TextField as MuiTextField,
} from "@material-ui/core";
import { NavigateNext as MuiNavigateNextIcon } from "@material-ui/icons";
import { Formik } from "formik";
import * as Yup from "yup";
/** Custom Components */
import { AccessDenied } from "../AccessDenied";
import { FormButton } from "../UI/Button/FormButton";
/** Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { getGrantsById, updateGrantAction } from "../../services/grantsService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./EditGrants.styles";

export const EditGrants = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { grantId } = params;
  const classes = useStyles();
  const [grantDetailsFetched, setGrantDetailsFetched] = useState(false);
  const [getSelectedSubmission] = useSelectedSubmission().value;

  const [grant, setGrant] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const history = useHistory();

  useEffect(() => {
    getGrantsById(
      getSelectedSubmission(),
      grantId,
      setGrant,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [getSelectedSubmission, grantId]);
  /**
   * condition when attribute (principalInvestigator)
   * is not coming in api response
   */
  if (
    grant._links &&
    grant._links.editGrantDetails &&
    !grant.principalInvestigator
  ) {
    grant.principalInvestigator = { name: "" };
  }
  const [grantData, setGrantData] = useState(null);

  const [formDataResponse, setFormDataResponse] = useState({
    startDate: new Date(),
    endDate: "",
    principalInvestigator: {},
    fundingSource: {},
    currentYearCost: "",
    grantRoles: [],
    isInstitutional: false,
    namesOfOverlappingFaculty: [],
  });

  const [saveGrantData, setSaveGrantData] = useState([]);
  const titleLabel = `${grant.title}`;
  useEffect(() => {
    updateGrantAction(
      getSelectedSubmission(),
      grantId,
      grantData,
      setSaveGrantData,
      props,
      t,
      titleLabel,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [getSelectedSubmission, grantId, grantData, t, props]);

  useEffect(() => {
    if (grant && grant._links) {
      setGrantDetailsFetched(true);
      setFormDataResponse(grant);
    }
  }, [grant, props, saveGrantData.data, t]);
  const GrantValidation = Yup.object().shape({
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required"),
  });

  /** 
  redirect on cancel button click
  */
  const onCancelHandler = () => {
    history.push("/grants");
  };

  const grantListLabel = "grant.edit.grantList";
  const grantActionLabel = "globals.form.actionButtons";
  return grant && grantDetailsFetched ? (
    grant._links && grant._links.editGrantDetails ? (
      <MuiGrid
        container
        wrap="nowrap"
        justify="flex-start"
        direction="column"
        spacing={2}
      >
        <MuiGrid item></MuiGrid>
        <MuiGrid item>
          <MuiBreadcrumbs
            separator={<MuiNavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <RouteLink to="/grants" className={classes.routeLink}>
              {t("grant.title")}
            </RouteLink>
            <MuiTypography color="textPrimary">
              {t("grant.edit.title")}
            </MuiTypography>
          </MuiBreadcrumbs>
        </MuiGrid>

        <MuiGrid item>
          <MuiTypography variant="h5">
            {grant.title ? `${grant.title}` : ""}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item></MuiGrid>
        <MuiGrid item>
          <Formik
            initialValues={formDataResponse}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              if (
                values.fundingSource &&
                Object.keys(values.fundingSource).length === 0 &&
                values.fundingSource.constructor === Object
              ) {
                delete values.fundingSource;
              }
              values.predocPositionsCount = values.predocPositionsCount
                ? values.predocPositionsCount
                : 0;

              values.numberOfOverlappingFaculty =
                values.numberOfOverlappingFaculty
                  ? values.numberOfOverlappingFaculty
                  : 0;

              values.numberOfParticipatingFaculty =
                values.numberOfParticipatingFaculty
                  ? values.numberOfParticipatingFaculty
                  : 0;

              values.numberOfShortTermPositions =
                values.numberOfShortTermPositions
                  ? values.numberOfShortTermPositions
                  : 0;

              values.postdocPositionsCount = values.postdocPositionsCount
                ? values.postdocPositionsCount
                : 0;

              setGrantData(values);
            }}
            validationSchema={GrantValidation}
          >
            {(props) => {
              const {
                values,
                isSubmitting,
                handleBlur,
                handleSubmit,
                isValid,
                dirty,
              } = props;
              return (
                <form onSubmit={handleSubmit} style={{ width: "500px" }}>
                  {alert.exists && (
                    <MuiGrid item>
                      <ASAlert />
                    </MuiGrid>
                  )}
                  <MuiGrid
                    container
                    item
                    xs={12}
                    direction="column"
                    justify="space-between"
                    spacing={2}
                    className={classes.grantsForm}
                  >
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="preDocsPositions"
                        name="predocPositionsCount"
                        label={t(`${grantListLabel}.preDocsPositions`)}
                        value={
                          isNaN(values.predocPositionsCount)
                            ? ""
                            : values.predocPositionsCount
                        }
                        maxValue={999}
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value < 1000) {
                            props.setFieldValue(
                              "predocPositionsCount",
                              parseInt(e.target.value)
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="postDocsPositions"
                        name="postdocPositionsCount"
                        label={t(`${grantListLabel}.postDocsPositions`)}
                        value={
                          isNaN(values.postdocPositionsCount)
                            ? ""
                            : values.postdocPositionsCount
                        }
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value < 1000) {
                            props.setFieldValue(
                              "postdocPositionsCount",
                              parseInt(e.target.value)
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>
                    <MuiGrid item xs={11}>
                      <MuiTextField
                        id="numberOfShortTermPositions"
                        name="numberOfShortTermPositions"
                        label={t(`${grantListLabel}.shortTermPositions`)}
                        value={
                          isNaN(values.numberOfShortTermPositions)
                            ? ""
                            : values.numberOfShortTermPositions
                        }
                        onChange={(e) => {
                          if (e.target.value >= 0 && e.target.value < 1000) {
                            props.setFieldValue(
                              "numberOfShortTermPositions",
                              parseInt(e.target.value)
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                      />
                    </MuiGrid>

                    <MuiGrid item xs={11}>
                      <MuiGrid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <MuiGrid item>
                          <FormButton
                            classes={{ root: classes.actionButton }}
                            onClick={onCancelHandler}
                            name={t(`${grantActionLabel}.cancel`)}
                            variant="outlined"
                          />
                        </MuiGrid>
                        <MuiGrid item>
                          <FormButton
                            classes={{ root: classes.actionButton }}
                            name={t(`${grantActionLabel}.save`)}
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting || !dirty || !isValid}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </form>
              );
            }}
          </Formik>
        </MuiGrid>
      </MuiGrid>
    ) : (
      <AccessDenied />
    )
  ) : null;
};
