import { useTranslation } from "react-i18next";
import {
  Typography as MuiTypography,
  Link as MuiLink,
} from "@material-ui/core";
import { Clear as MuiClearIcon } from "@material-ui/icons";
import { useStyles } from "./FilterPanel.styles";

export const ResetFilter = ({ resetFn }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.resetFilterContainer}>
      <MuiClearIcon fontSize="small" />
      <MuiLink
        component="button"
        underline="none"
        color="textPrimary"
        onClick={() => resetFn()}
      >
        <MuiTypography variant="body2">
          {t("submission.filterPanelItems.reset")}
        </MuiTypography>
      </MuiLink>
    </div>
  );
};
