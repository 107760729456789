import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  actionButton: {
    fontSize: "16px",
  },
  routeLink: {
    "&:visited": {
      color: "gray",
    },
  },
});
