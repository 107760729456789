import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tableContainerRoot: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  actionButton: {
    width: 30,
    padding: "5px 0px 0px 0px",
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
    },
  },
  actionContent: {
    display: "flex",
  },
  hideVisiblity: {
    visibility: "hidden",
  },
  deleteDialogBox: {
    "& .MuiDialogTitle-root": {
      paddingBottom: 0,
    },
    "& span.MuiTypography-root": {
      color: theme.palette.text.primary,
    },
    "& .MuiDialogActions-root": {
      paddingTop: 0,
      "& .MuiButton-root:first-child": {
        marginRight: 25,
      },
    },
  },
}));
