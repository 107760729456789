import { makeStyles } from "@material-ui/core/styles";

export const usePageStyles = makeStyles((theme) => ({
  content: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
    flexGrow: "1",
    position: "relative",
    width: "100%",
    height: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  rightSectionWrapper: {
    "& ": {
      overflow: "hidden",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "0 24px",
      zIndex: "0",
    },
  },
}));
