import { useState, createContext, useContext } from "react";

import { navbar } from "../config";

const FilterDrawerContext = createContext();

export const FilterDrawerProvider = ({ children }) => {
  const [filterPanelOpened, setFilterPanelOpened] = useState(
    window.outerWidth > navbar.width
  );

  return (
    <FilterDrawerContext.Provider
      value={{
        filterPanelOpened,
        setFilterPanelOpened,
      }}
    >
      {children}
    </FilterDrawerContext.Provider>
  );
};

export const useFilterDrawerContext = () => {
  return useContext(FilterDrawerContext);
};
