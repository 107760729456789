import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 3,
    backgroundColor: theme.palette.background.paper,
    "& .MuiButton-root": {
      marginRight: 0,
      marginBottom: 15,
    },
    "& .table1": {
      minWidth: 650,
      "& thead tr th:first-child": {
        minWidth: 280,
      },
      "& thead tr th:not(:first-child)": {
        textAlign: "right",
      },
      "& tbody tr:last-child td:first-child": {
        fontWeight: theme.typography.fontWeightBold,
      },
      "& tbody td:not(:first-child) ": {
        textAlign: "right",
      },
    },
  },
  divStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiTableContainer-root": {
      boxShadow: "-1px 2px 3px 2px #c4c4c466",
      border: "1px solid #e0e0e0",
      overflowX: "inherit",
    },
  },
}));
