import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from "@material-ui/core";
import {
  ChevronLeft as MuiChevronLeftIcon,
  ChevronRight as MuiChevronRightIcon,
} from "@material-ui/icons";
import { navbar } from "../../../config";
import { useFilterDrawerContext } from "../../../services/filterDrawerContext";
import { useUsers } from "../../../services/usersService";
import { FilterPanelStyle, DrawerStyle } from "./FilterPanel.styles";

const FilterPanelDef = ({
  location,
  changeFilterData,
  submissionUpdate,
  history,
  children,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();

  const { filterPanelOpened, setFilterPanelOpened } = useFilterDrawerContext();
  const [openedByUser, setOpenedByUser] = useState(filterPanelOpened);

  const [mobileView, setMobileView] = useState(
    window.outerWidth <= navbar.width
  );

  const [filterData, setFilterData] = useState({});

  function handleDrawerToggle() {
    setOpenedByUser(!filterPanelOpened);
    setFilterPanelOpened(!filterPanelOpened);
  }

  useEffect(() => {
    function handleResize() {
      let isMobile = window.outerWidth <= navbar.width;

      if (filterPanelOpened && isMobile) {
        setFilterPanelOpened(false);
      } else if (!filterPanelOpened && !isMobile) {
        setFilterPanelOpened(openedByUser);
      }

      setMobileView(isMobile);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [filterPanelOpened, openedByUser, mobileView, setFilterPanelOpened]);

  return currentUser && currentUser.permissions ? (
    <FilterPanelStyle open={filterPanelOpened}>
      <MuiIconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        className={filterPanelOpened ? "hide" : "show"}
      >
        <MuiChevronRightIcon fontSize="small" />
      </MuiIconButton>

      <DrawerStyle
        variant={mobileView ? "temporary" : "persistent"}
        open={filterPanelOpened}
        anchor={"left"}
        className={!filterPanelOpened ? "drawer-root-close" : ""}
        mobile={mobileView ? 1 : 0}
      >
        <div className="filterOptions">
          <div className="PaperToolbar">
            <MuiTypography variant="h6" className="filterTitle">
              {t("submission.filterPanelItems.title")}
            </MuiTypography>
            <MuiIconButton onClick={handleDrawerToggle}>
              <MuiChevronLeftIcon fontSize="small" />
            </MuiIconButton>
          </div>

          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              location,
              history,
              changeFilterData,
              filterData,
              setFilterData,
              submissionUpdate,
            });
          })}
        </div>
      </DrawerStyle>
    </FilterPanelStyle>
  ) : (
    ""
  );
};

export const FilterPanel = withRouter(FilterPanelDef);
