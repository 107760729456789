import { useTranslation } from "react-i18next";
import { Grid as MuiGrid } from "@material-ui/core";
import { RoutesMapping } from "../Routes/Routes";
import { NavbarWrapper } from "../components/Wrapper/NavbarWrapper";
import { SelectedSubmissionProvider } from "../services/selectedSubmissionService";
import { PagingSortingProvider } from "../services/pagingSortingContext";
import { FilterDrawerProvider } from "../services/filterDrawerContext";
import { useUsers } from "../services/usersService";
import {
  useAlertContext,
  ASHeader,
  ASMain,
  ASFooter,
  ASAlert,
} from "@stanford-tds/as-components";

// AppPage is the top-level component that can be customized in your single-page application.
const AppPage = () => {
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { alert } = useAlertContext();

  return (
    <>
      <ASHeader
        sitenameTitle={t("app.title")}
        sitenameSubtitle={t("app.subtitle")}
        displayName={currentUser.displayName}
      />
      <NavbarWrapper />
      <ASMain>
        <SelectedSubmissionProvider>
          <FilterDrawerProvider>
            <PagingSortingProvider>
              {currentUser.isLoaded && <RoutesMapping />}
              {!currentUser.isLoaded && alert.exists && (
                <MuiGrid item>
                  <ASAlert />
                </MuiGrid>
              )}
            </PagingSortingProvider>
          </FilterDrawerProvider>
        </SelectedSubmissionProvider>
      </ASMain>
      <ASFooter
        currentYear={t("app.year")}
        buildVersion={`${process.env.REACT_APP_VERSION}`}
      />
    </>
  );
};

export default AppPage;
