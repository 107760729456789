import { useEffect, useState, createContext, useContext } from "react";
import { usePagingSortingContext } from "./pagingSortingContext";
import { useBrowserStorage } from "./StorageService/StorageHelper";
import { STORAGE_NAMES, STORAGE_TYPE } from "../constants";

export const ManageSubmissionsContext = createContext();

// **** Initialize Manage Submission Values ****
const initialSubmissionState = ({ filterValues, pageSort }) => {
  pageSort = pageSort || {};
  const { filters, pagination, sort } = filterValues?.manageSubmissions ?? {};

  return {
    manageSubmissions: {
      sort: {
        orderBy: sort?.orderBy || "",
        order: sort?.order || "",
        ...pageSort.sort,
      },
      pagination: {
        page: pagination?.page || "",
        pageSize: pagination?.pageSize || "",
        ...pageSort.pagination,
      },
      delete: 0,
      filters: {
        name: filters?.name || {},
        year: filters?.year || "",
        submissionAdmin: filters?.submissionAdmin || "",
        observer: filters?.observer || "",
      },
    },
  };
};

export const ManageSubmissionsProvider = ({ children }) => {
  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.LOCAL);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS,
    Object
  );

  const { submissionPagingSortingStored, setSubmissionPagingSortingStored } =
    usePagingSortingContext().value;

  const [submissionPreference, setSubmissionPreferenceInternal] = useState(
    initialSubmissionState({
      filterValues,
      pageSort: submissionPagingSortingStored,
    })
  );

  const setSubmissionPreference = (preferences) => {
    preferences = preferences || {};

    const locPageSort = {
      pagination: preferences.manageSubmissions?.pagination || {},
      sort: preferences.manageSubmissions?.sort || {},
    };
    setSubmissionPagingSortingStored(locPageSort);
    setSubmissionPreferenceInternal(preferences);
    saveItem(STORAGE_NAMES.TGDS_MANAGE_SUBMISSION_FILTERS, preferences);
  };

  useEffect(() => {
    setSubmissionPagingSortingStored({
      pagination: submissionPreference.manageSubmissions?.pagination || {},
      sort: submissionPreference.manageSubmissions?.sort || {},
    });
  }, [setSubmissionPagingSortingStored, submissionPreference]);

  return (
    <ManageSubmissionsContext.Provider
      value={{ submissionPreference, setSubmissionPreference }}
    >
      {children}
    </ManageSubmissionsContext.Provider>
  );
};

export const useManageSubmissionsContext = () => {
  return useContext(ManageSubmissionsContext);
};
