import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
  Email as MuiEmailIcon,
} from "@material-ui/icons";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
/** Custom Components */
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import { OutcomesDetails } from "./OutcomesDetails/OutcomesDetails";
import { ShowSnackBar } from "../../Utils/ActionTrigger";
import { SendInvitation } from "./SendInvitation";
import { DeleteOutcomes } from "./DeleteOutcomes";
import { useSetLoading } from "../../Utils/loadingState";
import { AutocompleteOption } from "../../Utils/Autocomplete";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  traineeTypeDropDown,
} from "../../constants";
/** Services */
import { getTraineeList } from "../../services/traineeService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { getSubmission } from "../../services/submissionService";
import {
  useOutcomesSelectedContext,
  SelectAllStates,
} from "../../services/outcomesSelectedContext";
import {
  setOutcomeSubmissions,
  postAddTraineeToOutcome,
  getOutcomeExportData,
} from "../../services/outcomesService";
import { useOutcomesContext } from "../../services/outcomesContext";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useOutcomesStyles } from "./Outcomes.styles";
import { useCommonStyles } from "../shared/common.styles";

export const Outcomes = (props) => {
  const [submissionList, setSubmissionList] = useState([]);
  const [summary, setSummary] = useState({});
  const [snackbar, setSnackBar] = useState({ show: false, message: "" });

  const { t } = useTranslation();
  const classes = useOutcomesStyles();
  const commonClasses = useCommonStyles();
  const [refreshOutcomesID, setRefreshedOutcomeID] = useState(0);

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [, setError] = useState(false);

  const [hasAddOutcomeAccess, setHasAddOutcomeAccess] = useState(false);
  const [openDeleteOutcomesDialog, setOpenDeleteOutcomesDialog] =
    useState(false);
  const [OutcomesDelete, toggleDeleteOutcomes] = useState(false);
  const {
    outcomesPreferences,
    setOutcomesPreferences,
    updatePreferencesForSubmission,
  } = useOutcomesContext();

  const { resetAllSelections } = useOutcomesSelectedContext();
  const [trainees, setTrainees] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();

  const { outcomesSelected } = useOutcomesSelectedContext();
  const { ALL_SELECTED, NONE_SELECTED } = SelectAllStates;
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [addOptionsloading, setAddOptionsloading] = useState(false);
  const [key, setKey] = useState(`add-${new Date().toString()}`);

  const [openSendInvitationDialog, setOpenSendInvitationDialog] =
    useState(false);

  const [invitationsSent, toggleInvitationsSent] = useState(false);

  let timeoutID; // ID for API debounce timeout

  // **** Set Submission Value for Outcomes Submission DropDown ****
  useEffect(
    () => {
      setOutcomeSubmissions(setSubmissionList, setAlert, clearAlert);
    },
    // eslint-disable-next-line
    []
  );

  //New getSubmission implementation
  const id = getSelectedSubmission();
  useEffect(() => {
    resetAllSelections();
    if (id)
      getSubmission(
        id,
        setHasAddOutcomeAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Outcome",
        getSelectedSubmissionName
      );
    // eslint-disable-next-line
  }, [id, setHasAddOutcomeAccess]);

  const handleChangeAutoComplete = (event) => {
    const traineeVal = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(() => {
      if (traineeVal.length >= AUTOCOMPLETE_MIN_INPUT) {
        getTraineeList(
          traineeVal,
          15,
          setTrainees,
          setAlert,
          clearAlert,
          setAddOptionsloading,
          id,
          "outcomes"
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  const handleExport = () => {
    getOutcomeExportData(
      getSelectedSubmission(),
      outcomesPreferences,
      summary.totalCount,
      setAlert,
      clearAlert
    );
  };

  /* Update Submission and Re-render the Page,
   * Also reload the Filters on the basis of Updated Submission Id
   */
  const handleChangeSubmission = () => {
    props.handleSubmissionUpdate();
    const submission = getSelectedSubmission();
    updatePreferencesForSubmission(submission);
  };

  const handleTraineeSelection = (e, value) => {
    setSnackBar({ show: false, message: "" });
    if (value) {
      setError(false);
      postAddTraineeToOutcome(
        setSnackBar,
        getSelectedSubmission(),
        value.value,
        setRefreshedOutcomeID,
        setOutcomesPreferences,
        outcomesPreferences,
        setAlert,
        clearAlert,
        setLoadingItems,
        setKey
      );
    }
  };

  const handleOpenSendInvitationDialog = (e) => {
    clearAlert();
    setOpenSendInvitationDialog(true);
  };
  const handleOpenDeleteDialog = (e) => {
    clearAlert();
    setOpenDeleteOutcomesDialog(true);
  };

  const displayText = (option) => {
    const traineeStartYear = new Date(
      option.value?.appointmentStartDate
    ).getFullYear();
    const traineeEndYear = new Date(
      option.value?.appointmentEndDate
    ).getFullYear();

    const displaytextParts = [];
    displaytextParts.push(t(traineeTypeDropDown[option.value.traineeType]));
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    if (traineeStartYear && traineeEndYear) {
      return `${displaytextParts.join(
        ", "
      )} (${traineeStartYear}-${traineeEndYear})`;
    }

    return displaytextParts.join(", ");
  };

  return (
    <>
      {openSendInvitationDialog && (
        <SendInvitation
          openSendInvitationDialog={openSendInvitationDialog}
          setOpenSendInvitationDialog={setOpenSendInvitationDialog}
          toggleInvitationsSent={toggleInvitationsSent}
          outcomesPreferences={outcomesPreferences}
          setOutcomesPreferences={setOutcomesPreferences}
        />
      )}
      {openDeleteOutcomesDialog && (
        <DeleteOutcomes
          openDeleteOutcomesDialog={openDeleteOutcomesDialog}
          setOpenDeleteOutcomesDialog={setOpenDeleteOutcomesDialog}
          toggleDeleteOutcomes={toggleDeleteOutcomes}
          totalCount={summary.totalActiveCount}
        />
      )}
      <MuiGrid container justify="flex-start" direction="column">
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        {alert.exists && (
          <MuiGrid item>
            <ASAlert />
          </MuiGrid>
        )}

        <MuiGrid item classes={{ item: commonClasses.autocompleteWrapper }}>
          <SubmissionSelect
            submissionList={submissionList}
            handleChangeSubmission={handleChangeSubmission}
          />
        </MuiGrid>
        <MuiGrid item classes={{ item: classes.inputPanelWrapper }}>
          <MuiGrid container direction="row" justify="space-between">
            <MuiGrid item className={commonClasses.autocompleteWrapper}>
              <MuiAutocomplete
                key={key}
                blurOnSelect={true}
                clearOnBlur={true}
                openOnFocus={false}
                value={null}
                id="add-graduated-trainee-combo"
                disabled={!hasAddOutcomeAccess || loading}
                loading={addOptionsloading}
                options={trainees.count ? trainees.values : []}
                filterOptions={(option, state) => option}
                getOptionDisabled={(option) => option.isDummy}
                disabledItemsFocusable={true}
                getOptionLabel={(option) => option.value.fullName}
                noOptionsText={t("globals.autocomplete.helperText")}
                defaultValue={null}
                onChange={(e, value) => {
                  handleTraineeSelection(e, value);
                }}
                onClose={() => {
                  setTrainees([]);
                }}
                popupIcon={<MuiSearchIcon />}
                classes={{ root: commonClasses.autocompleteRoot }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("outcomes.mainView.addGraduatedTrainee")}
                    variant="outlined"
                    onChange={handleChangeAutoComplete}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addOptionsloading || loading ? (
                            <MuiCircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(option, { inputValue }) => (
                  <AutocompleteOption
                    classes={classes}
                    option={{ ...option }}
                    inputValue={inputValue}
                  >
                    {displayText(option)}
                  </AutocompleteOption>
                )}
              />
            </MuiGrid>
            <MuiGrid item classes={{ item: classes.buttonWrapper }}>
              <MuiButton
                variant="outlined"
                classes={{ root: classes.exportButton }}
                onClick={handleExport}
              >
                <MuiCloudDownloadIcon />
                &nbsp;{t("faculty.export.exportButtonText")}
              </MuiButton>
              {hasAddOutcomeAccess && (
                <MuiTooltip
                  title={t("outcomes.mainView.sendInformationRequest.tooltip")}
                >
                  <span>
                    <MuiButton
                      variant="contained"
                      className={classes.button}
                      disabled={
                        outcomesSelected.selectAllState !== ALL_SELECTED &&
                        Object.keys(
                          outcomesSelected?.individualSelections ?? []
                        ).length === 0
                      }
                      onClick={(e) => handleOpenSendInvitationDialog(e)}
                    >
                      <MuiEmailIcon />
                      &nbsp;&nbsp;
                      {t("outcomes.mainView.sendInformationRequest.label")}
                    </MuiButton>
                  </span>
                </MuiTooltip>
              )}
              <MuiButton
                variant="contained"
                className={classes.button}
                disabled={
                  (outcomesSelected.selectAllState === NONE_SELECTED &&
                    Object.keys(outcomesSelected?.individualSelections ?? [])
                      .length === 0) ||
                  (outcomesSelected.selectAllState !== NONE_SELECTED &&
                    Object.keys(outcomesSelected?.individualSelections ?? [])
                      .length === summary.totalActiveCount)
                }
                onClick={(e) => handleOpenDeleteDialog(e)}
              >
                <MuiEmailIcon />
                &nbsp;&nbsp;
                {t("outcomes.mainView.removeOutcomes.label")}
              </MuiButton>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item>
          <OutcomesDetails
            refreshOutcomes={refreshOutcomesID}
            filterData={props.filterData}
            history={props.history}
            setAlert={setAlert}
            clearAlert={clearAlert}
            invitationsSent={invitationsSent}
            OutcomesDelete={OutcomesDelete}
            hasAddOutcomeAccess={hasAddOutcomeAccess}
            setSummary={setSummary}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
