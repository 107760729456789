import { makeStyles } from "@material-ui/core/styles";

export const useOutcomesFilterStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginLeft: "5px",
  },
  formControlLabelRoot: {
    marginLeft: "0px",
    "&&": {
      "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
        color: theme.palette.grey[700],
      },
    },
  },
  checkBoxRoot: {
    border: theme.palette.grey[500],
    backgroundColor: theme.palette.common.white,
    "& .MuiSvgIcon-root": {
      fill: "gray",
      backgroundColor: theme.palette.common.white,
      border: theme.palette.grey[500],
    },
  },
  checkBoxWrapper: {
    marginBottom: "15px",
  },
  filterWrapper: {
    marginRight: "7px",
  },
  inputBaseRoot: {
    paddingRight: "5px",
    "& .MuiSvgIcon-root": {
      fill: "gray",
    },
  },
  inputBaseInput: {
    paddingLeft: "5px",
  },
  fieldTextStyle: {
    margin: "inherit",
    color: "#666666",
  },
  datePickerFormControl: {
    display: "flex",
    width: "100%",
    "&&": {
      "& .MuiFormControl-root": {
        marginTop: "5px",
        marginBottom: "5px",
      },
    },
  },
  dateSeparator: {
    margin: "14px 5px",
  },
}));
