import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actionButton: {
    fontSize: "16px",
  },
  orcidId: {
    "&&": {
      paddingTop: "18px",
    },
  },
  routeLink: {
    "&&": {
      color: "gray",
      "&:visited": {
        color: "gray",
      },
    },
  },
  traineeForm: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  formControlLabelRoot: {
    "& .MuiTypography-body1": {
      marginTop: "-10px",
      color: theme.palette.text.primary,
    },
  },
  checkBoxRoot: {
    paddingTop: "0px",
  },
  toolTip: {
    fontSize: "14px",
    maxWidth: "500px",
  },
  tooltipLink: {
    "&&": {
      fontWeight: "bold",
      color: theme.palette.common.white,
      "&:visited": {
        color: theme.palette.common.white,
      },
    },
  },
  mentorOptionTagStyle: {
    margin: "3px",
    maxWidth: "calc(100% - 6px)",
  },
}));
