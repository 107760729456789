import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from "@material-ui/core";
import i18n from "../../../i18n";
import { useStyles } from "./Dialog.styles";

/**
 * Generate custom Dialog box.
 *
 * @param {closeButtonText} props
 *   Close button text.
 * @param {title} props
 *   Dialog box title.
 * @param {scroll} props
 *   Dialog box scroll type, refer to Material UI Dialog API.
 * @param {open} props
 *   Prop to check if modal box is open or closed.
 * @param {handleClose} props
 *   Callback function to close the popup box.
 * @param {extraClass} props
 *   Extra class name to make dialog box changes.
 * @param {children} props
 *   Content for dialog box.
 * @param {showGeneralHelpSection} props
 *   shows General help section if True
 */
const CustomDialog = (props) => {
  const classes = useStyles();
  const scroll = props.scroll ? props.scroll : "paper";
  const dialogClasses = [classes.root];
  if (props.extraClass) {
    dialogClasses.push(props.extraClass);
  }
  return (
    <MuiDialog
      open={props.open}
      onClose={props.handleClose}
      scroll={scroll}
      className={dialogClasses.join(" ")}
    >
      <MuiDialogTitle
        className={classes.dialogTitle}
        disableTypography={!!props.description}
      >
        {!!props.description ? (
          <>
            <h2 className="MuiTypography-root MuiTypography-h6">
              {props.title}
            </h2>
            <p>{props.description}</p>
          </>
        ) : (
          props.title
        )}
      </MuiDialogTitle>
      <MuiDialogContent
        className={classes.dialogContent}
        dividers={scroll === "paper"}
      >
        {props.children}
      </MuiDialogContent>
      {props.showGeneralHelpSection && (
        <MuiDialogContent className={classes.dialogTitle}>
          <h2 className="MuiTypography-root MuiTypography-h6">
            {i18n.t("globals.metaDataInfo.generalHelpTitle")}
          </h2>
          <p>{i18n.t("globals.metaDataInfo.generalHelpDescription")}</p>
        </MuiDialogContent>
      )}
      <MuiDialogActions className={classes.dialogAction}>
        <MuiButton onClick={props.handleClose}>
          {props.closeButtonText}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

export default CustomDialog;
