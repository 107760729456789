import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// **** Material UI Components ****
import {
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
  Link as MuiButtonLink,
} from "@material-ui/core";
import {
  AddCircle as MuiAddCircleIcon,
  RemoveCircle as MuiRemoveIcon,
  Edit as MuiEditIcon,
} from "@material-ui/icons";
/** Styles */
import { useStyles } from "./AddRemoveActionIcons.styles";

// **** Component to Show Add, Remove and Edit Icons in Table Cell ****
const AddRemoveActionIcons = ({
  row,
  isRowActive,
  rowId,
  editPermission,
  removePermission,
  addPermission,
  toggleActive,
  handleConfirmation,
  editPageUrl,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const actionIconsLabel = "globals.list.actionIcons";

  // Button Icons
  const getAddDeleteButtonIcons = (
    titleText,
    buttonType,
    buttonIcon,
    actionFunction
  ) => (
    <MuiTooltip title={titleText}>
      <MuiIconButton
        className={classes.iconButtonsRoot}
        onClick={() => actionFunction(rowId, row, buttonType)}
      >
        <MuiButtonLink aria-label={buttonType}>{buttonIcon}</MuiButtonLink>
      </MuiIconButton>
    </MuiTooltip>
  );

  return (
    <span className={classes.actionContent}>
      <span>
        <MuiTooltip
          title={isRowActive ? t(`${actionIconsLabel}.edit.tooltip`) : ""}
        >
          <MuiIconButton className={classes.iconButtonsRoot}>
            {editPermission ? (
              isRowActive ? (
                <Link to={editPageUrl}>
                  <MuiEditIcon />
                </Link>
              ) : (
                <MuiEditIcon color="disabled" />
              )
            ) : (
              ""
            )}
          </MuiIconButton>
        </MuiTooltip>
      </span>
      <span>
        {row.isActive
          ? removePermission
            ? getAddDeleteButtonIcons(
                t(`${actionIconsLabel}.remove.tooltip`),
                "delete",
                <MuiRemoveIcon />,
                handleConfirmation
              )
            : ""
          : addPermission
          ? getAddDeleteButtonIcons(
              t(`${actionIconsLabel}.add.tooltip`),
              "add",
              <MuiAddCircleIcon />,
              toggleActive
            )
          : ""}
      </span>
    </span>
  );
};

export default AddRemoveActionIcons;
