import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { PageTitle } from "../PageTitle/PageTitle";
import { FilterPanel } from "../Layout/FilterPanel/FilterPanel";
import { usePageStyles } from "./Page.styles";
import { useStyles } from "../PageContent/PageContent.styles";

export const Page = (props) => {
  const [filterData, setFilterData] = useState({});
  const [submissionUpdate, setSubmissionUpdate] = useState(false);
  const classes = useStyles();
  const pageClasses = usePageStyles();
  const history = useHistory();

  /* Function to handle the Value change on Submission Dropdown, so as to reload the filter Panel */
  function handleSubmissionUpdate() {
    setSubmissionUpdate(true);
  }
  const changeFilterData = useCallback((data) => {
    setSubmissionUpdate(false);
    setFilterData(data);
  }, []);

  return (
    <div className={pageClasses.content}>
      {props.filter ? (
        <FilterPanel
          changeFilterData={changeFilterData}
          submissionUpdate={submissionUpdate}
        >
          {props.filter}
        </FilterPanel>
      ) : null}
      <div className={pageClasses.rightSectionWrapper}>
        {props.title ? (
          <PageTitle
            title={props.title}
            metaDataModalDescription={props.metaDataDescription}
          />
        ) : null}
        <div className={classes.pageContentStyle}>
          {props.main &&
            React.Children.map(props.main, (child) => {
              return React.cloneElement(child, {
                filterData: filterData,
                history: history,
                handleSubmissionUpdate: handleSubmissionUpdate,
              });
            })}
        </div>
      </div>
    </div>
  );
};
