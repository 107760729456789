import { useState } from "react";

/**
 * Simple helper method to determine if either storage variant is available
 * in the current browser.
 *
 * (borrowed from MDN:
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API)
 *
 * @param {string} type One of STORAGE_TYPE
 * @returns Whether or not Storage is available & not full.
 */
const storageAvailable = (type) => {
  let storage;

  try {
    storage = window[type];
    const x = "__storage_test__";

    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (error) {
    return (
      error instanceof DOMException &&
      // everything except Firefox
      (error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === "QuotaExceededError" ||
        // Firefox
        error.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

/**
 * A custom hook to provide a clean wrapper to the browsers Storage API.
 * Provide the kind of Storage you wish to interact with (local/session)
 * and the hook will return 3 custom methods to interact with your storage
 * choice.
 *
 *  * saveItem   - Adds an entry to storage
 *  * getItem    - Retrieves an item from stroage
 *  * removeItem - Removes an item in storage
 *
 * @param {string} type One of the two STORAGE_TYPEs available
 * @returns Functions to manipulate Storage API information.
 * @see jsdoc of each individual method returned by the hook for more information
 *   on how to use each function.
 */
export const useBrowserStorage = (type) => {
  const [storeType] = useState(type);

  if (!storageAvailable(storeType)) {
    throw Error(
      `Browser Storage API unavailable for {storeType}! Check your browser and browser settings!`
    );
  }

  /**
   * Store a value in the browser's Storage API.
   * The `value` can be anything easily coerced to a string.
   *   e.g. JSON/objects will be stringified.
   *
   * @param {string} name The key in storage to add/update
   * @param {any} value The value to store
   */
  const saveItem = (name, value) => {
    const storage = window[storeType];

    if (name && value) {
      if (typeof value === "string") {
        storage.setItem(name, value);
      } else if (typeof value === "object") {
        const stringValue = JSON.stringify(value);
        storage.setItem(name, stringValue);
      } else {
        try {
          const stringValue = "" + value;
          storage.setItem(name, stringValue);
        } catch (error) {
          console.error(
            `[ERROR] Unable to update ${storeType}! Received invalid value "${value}" to be stored.`,
            error
          );
        }
      }
    } else {
      console.error(
        `[ERROR] Invalid or null input for either name: "${name}" or value: "${value}"!`
      );
    }
  };

  /**
   * Retrieves a value from the browser's Storage API.
   * Will attempt to parse the value to the specified `valueType` before returning.
   *
   * @param {string} name The key in storage to retrieve the value of
   * @param {object} valueType The specific base type the value should be retrieved as (Default: String)
   * @returns The stored value
   */
  const getItem = (name, valueType = String) => {
    if (name) {
      const storage = window[storeType];
      const value = storage.getItem(name);

      try {
        if (valueType === String) {
          return value;
        } else if (valueType === Object) {
          return JSON.parse(value);
        } else {
          return valueType(value);
        }
      } catch (error) {
        console.error(
          `[ERROR] Unable to parse Storage value!\nError: ${error}`
        );
      }
    } else {
      console.error(`[ERROR] Invalid or null input for name: "${name}"!`);
    }
  };

  /**
   * Remove a value from the browser's Storage API.
   *
   * @param {string} name The key in storage to add/update
   */
  const removeItem = (name) => {
    if (name) {
      const storage = window[storeType];

      storage.removeItem(name);
    } else {
      console.error(`[ERROR] Invalid or null input for name: "${name}"!`);
    }
  };

  return { saveItem, getItem, removeItem };
};
