import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 700,
    },
    "& tbody tr:last-child td.noData": {
      fontWeight: theme.typography.fontWeightRegular,
      backgroundColor: theme.palette.background.paper,
    },
    "& tbody tr:last-child td": {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: theme.palette.grey[300],
    },
  },
  fixHeader: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },
  actionButton: {
    "& button": {
      height: "40px",
    },
  },
  noBold: {
    marginTop: 15,
    backgroundColor: theme.palette.background.paper,
    "& .table1": {
      minWidth: 650,
      "& thead tr th:first-child": {
        minWidth: 305,
      },
    },
    "& .MuiTypography-root": {
      whiteSpace: "pre-line",
    },
  },
}));
