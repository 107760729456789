import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  pageTitle: {
    "& ": {
      color: theme.palette.text.primary,
      margin: "24px 0 8px 0",
      display: "flex",
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

//Not used. Remove after testing.
export const Content = styled.div`
  && {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    transition: theme.transitions.create(
      "margin",
      {easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen}
    );
  }
`;
