import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actionContent: {
    display: "flex",

    "& .MuiSvgIcon-root": {
      "&.MuiSvgIcon-colorDisabled": {
        color: theme.palette.grey[300],
      },
    },
  },
}));
