import { MessagePanel } from "../components/UI/MessagePanel/MessagePanel";
import PositionedSnackbar from "../components/UI/SnackBar/SnackBar";

export const SendMessage = (...params) => {
  return params[0].history.push({
    pathname: params[3],
    state: {
      message: params[1],
      messageType: params[2],
    },
  });
};

export const DisplayMessage = (props) => {
  return typeof props.location.state != "undefined" ? (
    <MessagePanel
      message={props.location.state.message}
      messageType={props.location.state.messageType}
    />
  ) : (
    ""
  );
};

export const ShowSnackBar = (props) => {
  return <PositionedSnackbar message={props.message} autoHideDuration={3000} />;
};
