// Application settings for Autocomplete components that call APIs in real-time as the user types
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500; // Delay after user pauses typing before calling API (in milliseconds)
export const AUTOCOMPLETE_MIN_INPUT = 2; // Minimum number of characters entered by user before calling API

export const userRoles = {
  superAdmin: "SUPER_ADMIN",
  systemAdmin: "SYSTEM_ADMIN",
  submissionAdmin: "SUBMISSION_ADMIN",
  observer: "OBSERVER",
};
export const grantType = "T32";
export const submissionType = {
  create: "create",
  edit: "edit",
};

export const traineeTypeDropDown = {
  PRE_DOC: "trainee.mainView.filters.traineeTypeOptions.preDoc",
  POST_DOC: "trainee.mainView.filters.traineeTypeOptions.postDoc",
  SHORT_TERM: "trainee.mainView.filters.traineeTypeOptions.shortTerm",
};

export const applicationTypeDropDown = {
  PRE_DOC: "applicants.mainView.filters.applicantTypeOptions.preDoc",
  POST_DOC: "applicants.mainView.filters.applicantTypeOptions.postDoc",
  // BOTH: "applicants.mainView.filters.applicantTypeOptions.both",
};

export const outcomeTraineeTypeDropDown = {
  PRE_DOC: "trainee.mainView.filters.traineeTypeOptions.preDoc",
  POST_DOC: "trainee.mainView.filters.traineeTypeOptions.postDoc",
};

/* ENUM Values for Faculty Training Roles*/
export const FacultyTrainingRoles = {
  EXEC_COMMITTEE_MEMBER: "faculty.edit.trainingRole.executiveCommitteeMember",
  OTHER: "faculty.edit.trainingRole.other",
  OTHER_COMMITTEE_MEMBER: "faculty.edit.trainingRole.otherCommitteeMember",
  PD_PI: "faculty.edit.trainingRole.pdPI",
  PRECEPTOR: "faculty.edit.trainingRole.preceptor",
};

/* ENUM Values for Ethnicity*/
export const EthnicityValues = {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE:
    "trainee.edit.traineeForm.ethnicityValues.americanIndianOrAlaskaNative",
  ASIAN: "trainee.edit.traineeForm.ethnicityValues.asian",
  BLACK_OR_AFRICAN_AMERICAN:
    "trainee.edit.traineeForm.ethnicityValues.blackOrAfricanAmerican",
  HISPANIC_OR_LATINO:
    "trainee.edit.traineeForm.ethnicityValues.hispanicOrLatino",
  INTERNATIONAL_OR_NON_RESIDENT:
    "trainee.edit.traineeForm.ethnicityValues.internationalOrNonResident",
  MORE_THAN_ONE_RACE:
    "trainee.edit.traineeForm.ethnicityValues.moreThanOneRace",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER:
    "trainee.edit.traineeForm.ethnicityValues.nativeHawaiianOrOtherPacificIslander",
  OTHER: "trainee.edit.traineeForm.ethnicityValues.other",
  WHITE: "trainee.edit.traineeForm.ethnicityValues.white",
};

export const OutcomeTerminalDegreeTypeEnums = {
  MD: "outcomes.edit.outcomeForm.terminalDegreeValues.md",
  PHD: "outcomes.edit.outcomeForm.terminalDegreeValues.phd",
  IN_TRAINING: "outcomes.edit.outcomeForm.terminalDegreeValues.inTraining",
  NONE: "outcomes.edit.outcomeForm.terminalDegreeValues.none",
};

export const OutcomePreDocTrainingSupportTypeEnums = {
  NIH_HHS: "outcomes.edit.outcomeForm.trainingSupportTypeValues.nihHhs",
  RA: "outcomes.edit.outcomeForm.trainingSupportTypeValues.ra",
  TA: "outcomes.edit.outcomeForm.trainingSupportTypeValues.ta",
  F: "outcomes.edit.outcomeForm.trainingSupportTypeValues.f",
  TG: "outcomes.edit.outcomeForm.trainingSupportTypeValues.tg",
  S: "outcomes.edit.outcomeForm.trainingSupportTypeValues.s",
  OTHER: "outcomes.edit.outcomeForm.trainingSupportTypeValues.other",
};

export const OutcomePostDocTrainingSupportTypeEnums = {
  NIH_HHS: "outcomes.edit.outcomeForm.trainingSupportTypeValues.nihHhs",
  RG: "outcomes.edit.outcomeForm.trainingSupportTypeValues.rg",
  F: "outcomes.edit.outcomeForm.trainingSupportTypeValues.f",
  TG: "outcomes.edit.outcomeForm.trainingSupportTypeValues.tg",
  OTHER: "outcomes.edit.outcomeForm.trainingSupportTypeValues.other",
};

export const OutcomeTrainingSupportSourceEnums = {
  NSF: "outcomes.edit.outcomeForm.trainingSupportSourceValues.nsf",
  OTHER_FED: "outcomes.edit.outcomeForm.trainingSupportSourceValues.otherFed",
  UNIV: "outcomes.edit.outcomeForm.trainingSupportSourceValues.univ",
  FDN: "outcomes.edit.outcomeForm.trainingSupportSourceValues.fdn",
  NON_US: "outcomes.edit.outcomeForm.trainingSupportSourceValues.nonUs",
  OTHER: "outcomes.edit.outcomeForm.trainingSupportSourceValues.other",
  NONE: "outcomes.edit.outcomeForm.trainingSupportSourceValues.none",
};

export const TrainingSupportYearMaxCount = 99;

export const OutcomeResultingDegreeTypeEnums = {
  MD: "outcomes.edit.outcomeForm.resultingDegreeValues.md",
  PHD: "outcomes.edit.outcomeForm.resultingDegreeValues.phd",
  SCD: "outcomes.edit.outcomeForm.resultingDegreeValues.scd",
  DDS: "outcomes.edit.outcomeForm.resultingDegreeValues.dds",
  DMD: "outcomes.edit.outcomeForm.resultingDegreeValues.dmd",
  DC: "outcomes.edit.outcomeForm.resultingDegreeValues.dc",
  DO: "outcomes.edit.outcomeForm.resultingDegreeValues.do",
  DVM: "outcomes.edit.outcomeForm.resultingDegreeValues.dvm",
  OD: "outcomes.edit.outcomeForm.resultingDegreeValues.od",
  DPM: "outcomes.edit.outcomeForm.resultingDegreeValues.dpm",
  ENG_D: "outcomes.edit.outcomeForm.resultingDegreeValues.engD",
  DR_PH: "outcomes.edit.outcomeForm.resultingDegreeValues.drPh",
  DN_SC: "outcomes.edit.outcomeForm.resultingDegreeValues.dnSc",
  DPT: "outcomes.edit.outcomeForm.resultingDegreeValues.dpt",
  PHARM_D: "outcomes.edit.outcomeForm.resultingDegreeValues.pharmD",
  ND: "outcomes.edit.outcomeForm.resultingDegreeValues.nd",
  DSW: "outcomes.edit.outcomeForm.resultingDegreeValues.dsw",
  PSY_D: "outcomes.edit.outcomeForm.resultingDegreeValues.psyD",
  IN_TRAINING: "outcomes.edit.outcomeForm.resultingDegreeValues.inTraining",
  NONE: "outcomes.edit.outcomeForm.resultingDegreeValues.none",
  OTHER_D: "outcomes.edit.outcomeForm.resultingDegreeValues.other",
};

export const OutcomePositionActivityTypeEnums = {
  PRIMARILY_RESEARCH:
    "outcomes.edit.outcomeForm.positionActivityValues.primarilyResearch",
  PRIMARILY_TEACHING:
    "outcomes.edit.outcomeForm.positionActivityValues.primarilyTeaching",
  PRIMARILY_CLINICAL:
    "outcomes.edit.outcomeForm.positionActivityValues.primarilyClinical",
  RESEARCH_RELATED:
    "outcomes.edit.outcomeForm.positionActivityValues.researchRelated",
  FURTHER_TRAINING:
    "outcomes.edit.outcomeForm.positionActivityValues.furtherTraining",
  UNRELATED_TO_RESEARCH:
    "outcomes.edit.outcomeForm.positionActivityValues.unrelatedToResearch",
};

export const OutcomePositionWorkforceSectorTypeEnums = {
  ACADEMIA: "outcomes.edit.outcomeForm.positionWorkforceSectorValues.academia",
  GOVERMENT:
    "outcomes.edit.outcomeForm.positionWorkforceSectorValues.goverment",
  FOR_PROFIT:
    "outcomes.edit.outcomeForm.positionWorkforceSectorValues.forProfit",
  NONPROFIT:
    "outcomes.edit.outcomeForm.positionWorkforceSectorValues.nonprofit",
  OTHER: "outcomes.edit.outcomeForm.positionWorkforceSectorValues.other",
};

export const OutcomesInvitationStatusEnums = {
  INITIATED: {
    translationKey: "outcomes.mainView.list.invitationStatusValues.initiated",
    displayClass: "statusInfo",
  },
  SUBMITTED: {
    translationKey: "outcomes.mainView.list.invitationStatusValues.submitted",
    displayClass: "statusGood",
  },
  FAILED: {
    translationKey: "outcomes.mainView.list.invitationStatusValues.failed",
    displayClass: "statusBad",
  },
  SENT: {
    translationKey: "outcomes.mainView.list.invitationStatusValues.sent",
    displayClass: "statusInfo",
  },
  NOT_SENT: {
    translationKey: "outcomes.mainView.list.invitationStatusValues.notSent",
    displayClass: "statusInfo",
  },
  RE_SENT: {
    translationKey: "outcomes.mainView.list.invitationStatusValues.reSent",
    displayClass: "statusInfo",
  },
};

export const SubsequentGrantMaxCount = 5;

export const OutcomeSubsequentGrantRoleEnums = {
  PRINCIPAL_INVESTIGATOR:
    "outcomes.edit.outcomeForm.subsequentGrantRoleValues.principalInvestigator",
  CO_INVESTIGATOR:
    "outcomes.edit.outcomeForm.subsequentGrantRoleValues.coInvestigator",
  FACULTY_COLLABORATOR:
    "outcomes.edit.outcomeForm.subsequentGrantRoleValues.facultyCollaborator",
  STAFF_SCIENTIST:
    "outcomes.edit.outcomeForm.subsequentGrantRoleValues.staffScientist",
  OTHER_D: "outcomes.edit.outcomeForm.subsequentGrantRoleValues.other",
};

export const OutcomeSendInvitationStepEnums = {
  VERIFY_TRAINEE_LIST: {
    stepIndex: 0,
    stepLabel: "sendInvitation.stepLabels.verifyTraineeList",
  },
  PROVIDE_EMAIL_DETAILS: {
    stepIndex: 1,
    stepLabel: "sendInvitation.stepLabels.provideEmailDetails",
  },
  PREVIEW_AND_SEND: {
    stepIndex: 2,
    stepLabel: "sendInvitation.stepLabels.previewAndSend",
  },
};
export const TraineesRemovedStepEnums = {
  VERIFY_TRAINEE_LIST: {
    stepIndex: 0,
    stepLabel: "sendInvitation.stepLabels.verifyTraineeList",
  },
};
export const ApplicantsRemovedStepEnums = {
  VERIFY_APPLICANT_LIST: {
    stepIndex: 0,
    stepLabel: "removedApplicants.stepLabels.verifyApplicantList",
  },
};
export const FacultySyncStatusEnums = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
export const FACULTY_STATUS_SYNC_INTERVAL_MS = 30000;

export const DataSourceEnums = {
  GST: "dataSources.view.gst",
  OPA: "dataSources.view.opa",
  PEOPLESOFT: "dataSources.view.peopleSoft",
  RMG: "dataSources.view.rmg",
  TRAINEE_PORTAL: "dataSources.view.traineePortal",
};

/**
 * Browser Storage API names
 */
export const STORAGE_TYPE = {
  LOCAL: "localStorage",
  SESSION: "sessionStorage",
};

/**
 * Key names for specific Session/LocalStorage values
 */
export const STORAGE_NAMES = {
  TGDS_FACULTY_DASHBOARD_FILTERS: "TGDS_FACULTY_DASHBOARD_FILTERS",
  TGDS_GRANTS_DASHBOARD_FILTERS: "TGDS_GRANTS_DASHBOARD_FILTERS",
  TGDS_TRAINEES_DASHBOARD_FILTERS: "TGDS_TRAINEES_DASHBOARD_FILTERS",
  TGDS_OUTCOME_DASHBOARD_FILTERS: "TGDS_OUTCOME_DASHBOARD_FILTERS",
  TGDS_APPLICANT_DASHBOARD_FILTERS: "TGDS_APPLICANT_DASHBOARD_FILTERS",
  TGDS_MANAGE_SUBMISSION_FILTERS: "TGDS_MANAGE_SUBMISSION_FILTERS",
};

export const monthOptions = ["1", "5", "9"];
