import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  fixHeader: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    "& .MuiTableCell-root": {
      alignSelf: "left",
    },
  },
  actionButton: {
    "& .MuiButton-root": {
      height: "40px",
    },
  },
}));
