import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  Dialog as MuiDialog,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";

// this is ASConfirmation dialog
export const ASConfirmation = (props) => {
  const { onClose, open, title, message, okLabel, cancelLabel } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const defaultMaterialTheme = createTheme({
    palette: {
      primary: { main: "#009ABB" },
    },
  });
  return (
    <MuiThemeProvider theme={defaultMaterialTheme}>
      <MuiDialog open={open}>
        <MuiDialogTitle id="ASConfirmation-dialog-title">
          {title || ""}
        </MuiDialogTitle>
        <MuiDialogContent>{message || ""}</MuiDialogContent>
        <MuiDialogActions>
          <MuiButton onClick={handleCancel} color="primary">
            {cancelLabel ? cancelLabel : "Cancel"}
          </MuiButton>
          <MuiButton onClick={handleOk} color="primary">
            {okLabel ? okLabel : "OK"}
          </MuiButton>
        </MuiDialogActions>
      </MuiDialog>
    </MuiThemeProvider>
  );
};
