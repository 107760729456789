import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControl as MuiFormControl } from "@material-ui/core";
import { Autocomplete } from "../UI/Autocomplete/Autocomplete";
import {
  KeyboardDatePicker as MuiKeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format, isValid as isDateValid, parseISO } from "date-fns";
import { ResetFilter } from "../Layout/FilterPanel/ResetFilter";
import { STORAGE_TYPE, STORAGE_NAMES } from "../../constants";
/** Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { getGrantsFilterOptionsValue } from "../../services/grantsService";
import { useGrantsContext } from "../../services/grantsContext";
import { useBrowserStorage } from "../../services/StorageService/StorageHelper";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";
/** Styles */
import {
  DatePickerDivStyled,
  fieldTextStyle,
  SeparatorDatePickerDiv,
} from "./GrantFilter.styles";

const initialGrantFilterValues = {
  title: "",
  grantNumber: "",
  projectStartDateMin: "",
  projectStartDateMax: "",
  projectEndDateMin: "",
  projectEndDateMax: "",
  principal: "",
  grantRole: "",
};

export const GrantFilter = ({ changeFilterData, submissionUpdate }) => {
  const { t } = useTranslation();
  const { saveItem } = useBrowserStorage(STORAGE_TYPE.LOCAL);

  /** useHistory hook from "react-router-dom" */
  const history = useHistory();

  const { grantsPreference, setGrantsPreference } = useGrantsContext();

  let initialFilterValues = grantsPreference.grants.filters;

  // Controllable States - grantStartDateFrom
  const [projectStartDateMin, setProjectStartDateMin] = useState(
    initialFilterValues.projectStartDateMin
      ? parseISO(initialFilterValues.projectStartDateMin)
      : null
  );

  // Controllable States - grantStartDateTo
  const [projectStartDateMax, setProjectStartDateMax] = useState(
    initialFilterValues.projectStartDateMax
      ? parseISO(initialFilterValues.projectStartDateMax)
      : null
  );

  // Controllable States - grantEndDateFrom
  const [projectEndDateMin, setProjectEndDateMin] = useState(
    initialFilterValues.projectEndDateMin
      ? parseISO(initialFilterValues.projectEndDateMin)
      : null
  );

  // Controllable States - grantEndDateTo
  const [projectEndDateMax, setProjectEndDateMax] = useState(
    initialFilterValues.projectEndDateMax
      ? parseISO(initialFilterValues.projectEndDateMax)
      : null
  );

  const [filter, setFilter] = useState(new Map());
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [titleAwardlist, setTitleAwardList] = useState([]);
  const [projectPeriodList, setProjectPeriodList] = useState([]);
  const [maxDateStart, setMaxDateStart] = useState(null);
  const [minDateStart, setMinDateStart] = useState(null);
  const [maxDateEnd, setMaxDateEnd] = useState(null);
  const [minDateEnd, setMinDateEnd] = useState(null);
  const [getIsValidStartToDate, setIsValidStartToDate] = useState(false);
  const [getIsValidStartFromDate, setIsValidStartFromDate] = useState(false);
  const [getIsValidEndFromDate, setIsValidEndFromDate] = useState(false);
  const [getIsValidEndToDate, setIsValidEndToDate] = useState(false);
  const [grantFiltersSelectedValues, setGrantFiltersSelectedValues] = useState({
    ...initialFilterValues,
  });

  const { setAlert, clearAlert } = useAlertContext();
  const selectedSubmission = getSelectedSubmission();

  /** API call to getAll grantFilterOptions */
  useEffect(() => {
    getGrantsFilterOptionsValue(
      setTitleAwardList,
      setProjectPeriodList,
      selectedSubmission,
      history,
      setAlert,
      clearAlert,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [selectedSubmission, history]);

  //project Period
  if (Object.keys(projectPeriodList).length && !minDateEnd && !minDateStart) {
    setMinDateEnd(parseISO(projectPeriodList.projectEndDateMin));
    setMinDateStart(parseISO(projectPeriodList.projectStartDateMin));
    setMaxDateStart(parseISO(projectPeriodList.projectStartDateMax));
    setMaxDateEnd(parseISO(projectPeriodList.projectEndDateMax));
  }

  /*
   * Whenever URL parameters already contain the Query String params,
   * this helper function will return selected object,
   * to set the corresponding selected value from options in Filter Fields.
   */

  const getFieldDisplayText = (optionsArray, field, valueId) =>
    optionsArray &&
    optionsArray.filter(
      (value) =>
        grantFiltersSelectedValues[field] === (valueId ? value[valueId] : value)
    )[0];

  const title = titleAwardlist.map((data) => data.title);
  const award = titleAwardlist
    .map((data) => data.grantNumber)
    .sort((grantFirst, grantSecond) => grantFirst.localeCompare(grantSecond));

  // Title
  const selectedTitle = getFieldDisplayText(title, "title");

  // award
  const selectedGrantNumber = getFieldDisplayText(award, "grantNumber");

  const resetFn = () => {
    saveItem(
      STORAGE_NAMES.TGDS_GRANTS_DASHBOARD_FILTERS,
      initialGrantFilterValues
    );
    setGrantFiltersSelectedValues({ ...initialGrantFilterValues });
    setProjectStartDateMin(null);
    setProjectEndDateMin(null);
    setProjectStartDateMax(null);
    setProjectEndDateMax(null);
    setFilterPreference({ ...initialGrantFilterValues });
    setFilter(new Map());
  };

  // *** Helper Function to Update Context Object, on Field Value Update ***
  const setFilterPreference = (fieldUpdate) => {
    setGrantsPreference({
      ...grantsPreference,
      grants: {
        ...grantsPreference.grants,
        filters: {
          ...grantsPreference.grants.filters,
          ...fieldUpdate,
        },
        sort: {
          orderBy: "",
          order: "",
        },
        pagination: {
          page: "",
          pageSize: "",
        },
      },
    });
  };

  /**  On Dropdown Value change, update search results **/
  const handleFilterValueUpdate = (fieldName, fieldValue, updateFilterData) => {
    const updatedFilterValues = {
      ...grantFiltersSelectedValues,
      [fieldName]: fieldValue,
    };

    if (updateFilterData) {
      setFilterPreference(updatedFilterValues);
      setGrantFiltersSelectedValues(updatedFilterValues);
    }
  };

  /* On Updates to the Submission Id, refresh the Search Results and Filter Panel*/
  useEffect(() => {
    if (submissionUpdate) {
      resetFn();
    }

    // eslint-disable-next-line
  }, [submissionUpdate]);

  if (filter) {
    changeFilterData(filter);
  }
  const grantFilterLabel = "grant.mainView.filters";
  return (
    <>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="titleAutoComplete"
          key="title-autocomplete"
          clientSide={true}
          options={title || []}
          getOptionLabel={(option) => option}
          value={selectedTitle ? selectedTitle : ""}
          label={t(`${grantFilterLabel}.title`)}
          onChange={(e, value) => {
            handleFilterValueUpdate("title", value || "", true);
          }}
        />
      </MuiFormControl>
      <MuiFormControl variant="outlined">
        <Autocomplete
          id="awardNumberAutoComplete"
          key="award-number-autocomplete"
          clientSide={true}
          options={award || []}
          getOptionLabel={(option) => option}
          value={selectedGrantNumber ? selectedGrantNumber : ""}
          label={t(`${grantFilterLabel}.awardNumber`)}
          onChange={(e, value) => {
            handleFilterValueUpdate("grantNumber", value || "", true);
          }}
        />
      </MuiFormControl>
      <MuiFormControl>
        <label style={fieldTextStyle}>
          {t(`${grantFilterLabel}.projectStartDate`)}
        </label>
        <DatePickerDivStyled>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              maxDate={
                projectStartDateMax || maxDateStart || new Date("01/01/2100")
              }
              minDate={minDateStart || new Date("01/01/1970")}
              invalidDateMessage={t(`${grantFilterLabel}.invalidDateFormat`)}
              margin="normal"
              id="date-picker-inline-start-from"
              value={projectStartDateMin}
              onChange={(value) => {
                setProjectStartDateMin(value);
                setIsValidStartFromDate(false);
                handleFilterValueUpdate(
                  "projectStartDateMin",
                  (isDateValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={getIsValidStartFromDate || undefined}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <SeparatorDatePickerDiv>
              {t(`${grantFilterLabel}.dateSeparator`)}
            </SeparatorDatePickerDiv>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={
                projectStartDateMin || minDateStart || new Date("01/01/1970")
              }
              maxDate={maxDateEnd || maxDateStart || new Date("01/01/2100")}
              invalidDateMessage={t(`${grantFilterLabel}.invalidDateFormat`)}
              margin="normal"
              id="date-picker-inline-start-to"
              value={projectStartDateMax}
              onChange={(value) => {
                setProjectStartDateMax(value);
                setIsValidStartToDate(false);
                handleFilterValueUpdate(
                  "projectStartDateMax",
                  (isDateValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={getIsValidStartToDate || undefined}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </DatePickerDivStyled>
      </MuiFormControl>
      <MuiFormControl>
        <label style={fieldTextStyle}>
          {t(`${grantFilterLabel}.projectEndDate`)}
        </label>
        <DatePickerDivStyled>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              maxDate={
                projectEndDateMax || maxDateStart || new Date("01/01/2100")
              }
              minDate={minDateStart || new Date("01/01/1970")}
              invalidDateMessage={t(`${grantFilterLabel}.invalidDateFormat`)}
              margin="normal"
              id="date-picker-inline-end-from"
              value={projectEndDateMin}
              onChange={(value) => {
                setProjectEndDateMin(value);
                setIsValidEndFromDate(false);
                handleFilterValueUpdate(
                  "projectEndDateMin",
                  (isDateValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={getIsValidEndFromDate || undefined}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <SeparatorDatePickerDiv>
              {t(`${grantFilterLabel}.dateSeparator`)}
            </SeparatorDatePickerDiv>
            <MuiKeyboardDatePicker
              inputVariant="outlined"
              clearable
              format="MM/dd/yyyy"
              minDate={
                projectEndDateMin || minDateStart || new Date("01/01/1970")
              }
              maxDate={maxDateEnd || maxDateStart || new Date("01/01/2100")}
              invalidDateMessage={t(`${grantFilterLabel}.invalidDateFormat`)}
              margin="normal"
              id="date-picker-inline-end-to"
              value={projectEndDateMax}
              onChange={(value) => {
                setProjectEndDateMax(value);
                setIsValidEndToDate(false);
                handleFilterValueUpdate(
                  "projectEndDateMax",
                  (isDateValid(value)
                    ? format(new Date(value), "yyyy-MM-dd")
                    : "") || "",
                  true
                );
              }}
              onError={getIsValidEndToDate || undefined}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </DatePickerDivStyled>
      </MuiFormControl>
      <ResetFilter resetFn={resetFn} />
    </>
  );
};
