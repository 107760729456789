import { useState, createContext, useContext } from "react";

const SelectedSubmissionContext = createContext();

export const SelectedSubmissionProvider = ({ children }) => {
  const [metaData, setMetaData] = useState(false);
  const getSelectedSubmission = () => {
    return localStorage.getItem("selectedSubmissionId");
  };

  const setSelectedSubmission = (value) => {
    localStorage.setItem("selectedSubmissionId", value);
  };

  const getSelectedSubmissionYear = () => {
    return localStorage.getItem("selectedSubmissionYear");
  };

  const setSelectedSubmissionYear = (value) => {
    localStorage.setItem("selectedSubmissionYear", value);
  };

  const getSelectedSubmissionName = () => {
    return localStorage.getItem("selectedSubmissionName");
  };

  const setSelectedSubmissionName = (value) => {
    localStorage.setItem("selectedSubmissionName", value);
  };

  const getSelectedSubmissionMonth = () => {
    return localStorage.getItem("selectedSubmissionMonth");
  };

  const setSelectedSubmissionMonth = (value) => {
    localStorage.setItem("selectedSubmissionMonth", value);
  };

  return (
    <SelectedSubmissionContext.Provider
      value={{
        value: [getSelectedSubmission, setSelectedSubmission],
        metaData,
        setMetaData,
        year: [getSelectedSubmissionYear, setSelectedSubmissionYear],
        name: [getSelectedSubmissionName, setSelectedSubmissionName],
        month: [getSelectedSubmissionMonth, setSelectedSubmissionMonth],
      }}
    >
      {children}
    </SelectedSubmissionContext.Provider>
  );
};

export const useSelectedSubmission = () => {
  return useContext(SelectedSubmissionContext);
};
