import { useState } from "react";

const SUBMISSION = "submission";
const ITEMS = "items";

export const useSetLoading = () => {
  let loadingState = {};

  const [loading, setLoading] = useState(false);

  const setLoadingSubmission = (value) => {
    loadingState[SUBMISSION] = value;

    // setLoading kicks off a reload, if the value is actually different
    setLoading(!!Object.values(loadingState).filter((x) => x).length);
  };

  const setLoadingItems = (value) => {
    loadingState[ITEMS] = value;
    setLoading(!!Object.values(loadingState).filter((x) => x).length);
  };

  return [loading, setLoadingSubmission, setLoadingItems];
};
