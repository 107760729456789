import { TableCell, TableRow, Button } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  fixHeader: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },
  blueHeader: {
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
    "& .MuiTableCell-root": {
      alignSelf: "left",
    },
  },
  actionButton: {
    "& .MuiButton-root": {
      height: "40px",
    },
  },
  newLine: {
    whiteSpace: "pre-line",
  },
}));

export const TopOutputDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiTableContainer-root {
    box-shadow: -1px 2px 3px 2px #c4c4c466;
    border: 1px solid #e0e0e0;
    margin: 16px 0;
    overflow-x: hidden;
  }
`;

export const TopInputPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const OutputActionDivStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
`;

export const SubmissionsDivStyled = styled.div`
  .MuiFormControl-root {
    width: 140%;
    margin-bottom: 16px;
  }
`;
export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ActionButton = withStyles((theme) => ({
  root: {
    height: "40px",
    alignSelf: "flex-end",
  },
}))(Button);

export const StyledTableCell = withStyles((theme) => ({
  head: {
    verticalAlign: "top",
    backgroundColor: theme.palette.primary.light,
    fontWeight: 600,
    lineHeight: "18px",
    fontSize: "14px",
    color: theme.palette.text.primary,
    width: "10%",
  },
  body: {
    verticalAlign: "top",
    wordWrap: "break-word",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export const title = {
  width: "30%",
};

export const NewLine = {
  whiteSpace: "pre-line",
};

export const zIndexOverride = {
  zIndex: "0",
};
