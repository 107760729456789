import { makeStyles } from "@material-ui/core/styles";

export const useFacultyStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
  },
  button: {
    height: "40px",
    alignSelf: "flex-end",
    marginLeft: "24px",
  },
  inputPanelWrapper: {
    width: "100%",
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    color: theme.palette.grey[800],
  },
}));
