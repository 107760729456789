import { createContext, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { STORAGE_NAMES, STORAGE_TYPE } from "../constants";
import { useBrowserStorage } from "./StorageService/StorageHelper";
import { usePagingSortingContext } from "./pagingSortingContext";
import { useAlertContext } from "@stanford-tds/as-components";

export const ApplicantContext = createContext();

export const ApplicantProvider = ({ children }) => {
  const history = useHistory();
  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.LOCAL);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_APPLICANT_DASHBOARD_FILTERS,
    Object
  );
  const { applicantPagingSortingStored, setApplicantPagingSortingStored } =
    usePagingSortingContext().value;

  const [applicantsFilterPreferences, setApplicantsFilterPreferencesInternal] =
    useState(
      initialFilterState({
        location: history.location,
        pageSort: applicantPagingSortingStored,
        filterValues,
      })
    );

  const setApplicantsFilterPreferences = (preferences) => {
    preferences = preferences || {};

    const locPageSort = {
      pagination: preferences.applicantValues?.pagination || {},
      sort: preferences.applicantValues?.sort || {},
    };
    setApplicantPagingSortingStored(locPageSort);
    setApplicantsFilterPreferencesInternal(preferences);
    saveItem(STORAGE_NAMES.TGDS_APPLICANT_DASHBOARD_FILTERS, preferences);
  };

  useEffect(() => {
    setApplicantPagingSortingStored({
      pagination: applicantsFilterPreferences.applicantValues?.pagination || {},
      sort: applicantsFilterPreferences.applicantValues?.sort || {},
    });
  }, [setApplicantPagingSortingStored, applicantsFilterPreferences]);

  const updatePreferencesForSubmission = (selectedSubmission) => {
    const initialFilter = initialFilterState({
      filterValues: { applicantValues: { filters: { isActive: true } } },
    });
    setApplicantsFilterPreferences({
      ...initialFilter,
      applicantValues: {
        ...initialFilter.applicantValues,
        submission: selectedSubmission,
      },
    });
  };

  return (
    <ApplicantContext.Provider
      value={{
        applicantsFilterPreferences,
        setApplicantsFilterPreferences,
        updatePreferencesForSubmission,
      }}
    >
      {children}
    </ApplicantContext.Provider>
  );
};

export const initialFilterState = ({ location, pageSort, filterValues }) => {
  pageSort = pageSort || {};
  const { filters, pagination, sort } = filterValues?.applicantValues ?? {};

  return {
    applicantValues: {
      renderCount: 0,
      submission: 0,
      filters: {
        applicant: filters?.applicant || "",
        applicantName: filters?.applicantName || "",
        applicantUniversityId: filters?.applicantUniversityId || "",
        departmentName: filters?.departmentName || "",
        departmentCode: filters?.departmentCode || "",
        applicationYear: filters?.applicationYear || "",
        acceptedYear: filters?.acceptedYear || "",
        applicantType: filters?.applicantType || "",
        newEntrant: filters?.newEntrant
          ? /^true$/i.test(filters?.newEntrant)
          : "",
        grantEligible: filters?.grantEligible
          ? /^true$/i.test(filters?.grantEligible)
          : "",
        isActive: filters?.isActive ? true : false,
      },
      pagination: {
        pageSize: pagination?.pageSize || 10,
        page: pagination?.page || 0,
        ...pageSort.pagination,
      },
      sort: {
        order: sort?.order || "",
        orderBy: sort?.orderBy || "",
        ...pageSort.sort,
      },
    },
  };
};

export const useApplicantsContext = () => {
  return useContext(ApplicantContext);
};

export const useApplicantsFilterContext = () => {
  return useContext(ApplicantContext);
};

/**
 * `useApplcantsFilterData` is a custom hook that provides the filter options for the Applicants tab filter panel.
 * It also manages the loading state during the fetch operation.
 *
 * @hook
 * @param {number} selectedSubmission - The ID of the selected submission.
 * @returns {Object} The filter data and loading state.
 * `filterData` is an object containing arrays of filter options for applicant names, departments, application years, accepted years, and applicant types.
 * `loading` is a boolean indicating whether the fetch operation is ongoing.
 */
export const useApplcantsFilterData = (selectedSubmission) => {
  const { clearAlert, setAlert } = useAlertContext();
  const { applicantsFilterPreferences } = useApplicantsFilterContext();

  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    departments: [],
    applicationYears: [],
    acceptedYears: [],
    applicantTypes: [],
  });

  useEffect(() => {
    const cancelSource = axios.CancelToken.source();
    clearAlert();

    const fetchFilterData = async () => {
      try {
        clearAlert();
        setLoading(true);

        const response = await axios({
          url: `/submissions/${selectedSubmission}/applicants/filters`,
          cancelToken: cancelSource?.token,
        });

        if (response?.data) {
          setFilterData({
            departments:
              response.data?.applicantDepartmentsOrPrograms?.values || [],
            applicationYears: response.data?.applicationYears?.values || [],
            acceptedYears: response.data?.acceptanceYears?.values || [],
            applicantTypes: response.data?.applicantTypes?.values || [],
          });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          setAlert("error", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    if (selectedSubmission) fetchFilterData();

    return () => {
      cancelSource.cancel();
    };
    // eslint-disable-next-line
  }, [
    selectedSubmission,
    applicantsFilterPreferences.applicantValues.addApplicant,
  ]);

  return { filterData, loading };
};
