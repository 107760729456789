import { createTheme } from "@material-ui/core/styles";

const STANFORD_ACCENT_BLUE = "#009ABB";
const STANFORD_LINK_BLUE = "#007C92";
const STANFORD_LINE_GRAY = "#DDDDDD";
const STANFORD_LIGHT_GRAY = "#CCCCCC";
const STANDFORD_MEDIUM_GRAY = "#666666";
const STANFORD_GRAY = "#999999";
const STANFORD_TEXT_GRAY = "#333333";
const STANFORD_ERROR_RED = "#982727";
const WHITE = "#FFFFFF";
const STANFORD_BACKGROUND_BLUE = "#F0F4F5";
const FONT_FAMILY = "Source sans pro";

export const asMuiTheme = createTheme({
  typography: {
    fontFamily: FONT_FAMILY,
  },
  palette: {
    primary: {
      main: STANFORD_ACCENT_BLUE,
      light: STANFORD_BACKGROUND_BLUE,
      dark: STANFORD_LINK_BLUE,
    },
    grey: {
      300: STANFORD_LINE_GRAY,
      400: STANFORD_LIGHT_GRAY,
      500: STANFORD_GRAY,
      700: STANDFORD_MEDIUM_GRAY,
    },
    red: {
      300: STANFORD_ERROR_RED,
      400: STANFORD_ERROR_RED,
      500: STANFORD_ERROR_RED,
      700: STANFORD_ERROR_RED,
    },
    text: {
      primary: STANFORD_TEXT_GRAY,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        color: STANFORD_ACCENT_BLUE,
      },
    },
    /** Styling for Table component **/
    MuiTableContainer: {
      root: {
        boxShadow: "-1px 2px 3px 2px #c4c4c466",
        margin: "16px 0",
        overflowX: "hidden",
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: STANDFORD_MEDIUM_GRAY,
        color: WHITE,
      },
      head: {
        wordWrap: "break-word",
      },
      root: {
        padding: "16px 10px",
        fontSize: "14px",
        maxWidth: "150px",
        verticalAlign: "top",
        wordWrap: "break-word",
        "& a": {
          color: STANFORD_ACCENT_BLUE,
        },
        /** Styling for svgIcon */
        "& .MuiSvgIcon-root": {
          border: "none",
          width: "1em",
          height: "1em",
          color: STANFORD_TEXT_GRAY,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        "&$active": {
          color: WHITE,
          opacity: 1,
          "&& $icon": {
            opacity: 1,
            color: WHITE,
          },
        },
        "&:hover": {
          opacity: 1,
          color: WHITE,
          "&& $icon": {
            opacity: 1,
            color: WHITE,
          },
        },
      },
      icon: {
        alignSelf: "baseline",
        marginTop: "2px",
      },
    },
    /** Styling for Checkbox component **/
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: STANFORD_ACCENT_BLUE,
        },
      },
    },
    /** Styling for Button component **/
    MuiButton: {
      root: {
        fontSize: "15px",
      },
      outlinedPrimary: {
        color: STANFORD_ACCENT_BLUE,
        borderColor: STANFORD_ACCENT_BLUE,
      },
      textPrimary: {
        color: STANFORD_ACCENT_BLUE,
      },
    },
    /** Styling for Typography**/
    MuiTypography: {
      h1: {
        fontWeight: 500,
        fontSize: "25px",
        lineHeight: "28.014px",
      },
      h2: {
        fontSize: "21px",
        fontWeight: 600,
      },
      h3: {
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "30px",
      },
      h5: {
        fontSize: "1.5rem",
        color: STANFORD_TEXT_GRAY,
      },
      subtitle1: { fontSize: "15px", fontWeight: 600, lineHeight: "25px" },
      body1: {
        fontSize: "17px",
        fontWeight: 500,
        lineHeight: 1.9,
      },
      body2: {
        fontSize: "15px",
        fontWeight: 500,
        lineHeight: "25px",
      },
      caption: { fontSize: "13px", fontWeight: 500 },
    },
    /** Styling for MUI Link**/
    MuiLink: {
      root: {
        color: STANFORD_ACCENT_BLUE,
      },
    },
    /** Styling for MUI Dialog Title**/
    MuiDialogTitle: {
      root: {
        fontSize: "18px",
        fontWeight: 600,
      },
    },
    MuiSvgIcon: {
      root: {
        "&$colorPrimary": {
          color: STANFORD_ACCENT_BLUE,
        },
      },
    },
    /** Styling for MUI Icon Button**/
    MuiIconButton: {
      root: {
        color: STANFORD_TEXT_GRAY,
      },
    },
    /** Styling for MUI Tooltip**/
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
  },
});

export const theme = {
  app: {
    fontFamily: FONT_FAMILY,
  },
  main: {
    backgroundColor: STANFORD_BACKGROUND_BLUE,
  },
};
