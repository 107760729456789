import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "8px",
  },
  button: {
    borderRadius: "5px",
    backgroundColor: theme.palette.primary.main,
    fontSize: "16px",
    color: theme.palette.common.white,
    shadow: `"5px 5px 5px ${theme.palette.common.white}"`,
    textTransform: "none",
    "&:hover": {
      borderRadius: "5px",
      background: theme.palette.primary.dark,
      fontSize: "16px",
      color: "ffffff",
    },
  },
  inputPanelWrapper: {
    width: "100%",
  },
  autocompleteOption: {},
  dummyAutocompleteOption: {
    color: theme.palette.grey[800],
  },
}));
