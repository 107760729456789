import styled from "styled-components";

export const DatePickerDivStyled = styled.div`
  display: flex;
  .MuiInputBase-formControl {
    width: auto;
  }
  div {
    margin: auto;
  }
  .MuiInputBase-input {
    padding: 0px 3px;
  }
  .MuiInputBase-root {
    padding: 8px;
  }
  .MuiSvgIcon-root {
    fill: gray;
  }
  && {
    .MuiFormControl-root {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 100%;
    }
  }
`;

export const fieldTextStyle = {
  margin: "inherit",
  color: "#666666",
};

export const SeparatorDatePickerDiv = styled.span`
  margin: 14px 5px;
`;
