import axios from "axios";

/**
 * Gets a collection of Commitment Requests.
 *
 * @param {object} params
 * @param {function} onBeforeSend
 * @param {function} onError
 * @param {function} onSuccess
 * @param {object} cancelSource
 */
export const getTraineeDepartments = async (
  selectedSubmission,
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  //since the department drop down shows up only for PRE_DOC the flag is always set to true
  let includeDeptsWithAcademicPlanCodeOnly = true;
  try {
    onBeforeSend();

    const response = await axios({
      url: `/submissions/${selectedSubmission}/trainees/departments?includeDeptsWithAcademicPlanCodeOnly=${includeDeptsWithAcademicPlanCodeOnly}`,
      
      cancelToken: cancelSource?.token,
    });

    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};
