import { useState, createContext, useContext } from "react";

const TraineesSelectedContext = createContext();

export const SelectAllStates = {
  NONE_SELECTED: 0,
  SOME_SELECTED: 1,
  ALL_SELECTED: 2,
};

export const TraineesSelectedProvider = ({ children }) => {
  const [traineesSelected, setTraineesSelected] = useState({
    individualSelections: {},
    selectAllState: SelectAllStates.NONE_SELECTED,
  });

  const { ALL_SELECTED, SOME_SELECTED, NONE_SELECTED } = SelectAllStates;
  const handleSelectionChange = (event, row) => {
    const value = !!event.target.checked;
    const rowId = row._links.self.href;

    switch (true) {
      case traineesSelected.selectAllState === ALL_SELECTED && !value:
      case traineesSelected.selectAllState === SOME_SELECTED && !value:
        setTraineesSelected({
          ...traineesSelected,
          individualSelections: {
            ...traineesSelected.individualSelections,
            [rowId]: false,
          },
          selectAllState: SOME_SELECTED,
        });
        break;

      case traineesSelected.selectAllState === NONE_SELECTED && value:
        setTraineesSelected({
          ...traineesSelected,
          individualSelections: {
            ...traineesSelected.individualSelections,
            [rowId]: true,
          },
        });
        break;

      case traineesSelected.selectAllState === SOME_SELECTED && value:
      case traineesSelected.selectAllState === NONE_SELECTED && !value:
        const { [rowId]: dummy, ...individualSelections } =
          traineesSelected.individualSelections;

        setTraineesSelected({
          ...traineesSelected,
          individualSelections: { ...individualSelections },
        });
        break;

      case traineesSelected.selectAllState === ALL_SELECTED && value:
        // empty: should not happen
        break;

      default:
        break;
    }
  };

  const handleSelectAllChange = (event) => {
    setTraineesSelected({
      ...traineesSelected,
      individualSelections: {},
      selectAllState: !!event.target.checked ? ALL_SELECTED : NONE_SELECTED,
    });
  };
  const resetAllSelections = () => {
    setTraineesSelected({
      ...traineesSelected,
      individualSelections: {},
      selectAllState: NONE_SELECTED,
    });
  };

  const ignoreSelection = (row) => {
    const rowId = row._links.self.href;
    const { [rowId]: dummy, ...individualSelections } =
      traineesSelected.individualSelections;

    setTraineesSelected({
      ...traineesSelected,
      individualSelections: { ...individualSelections },
    });
  };

  return (
    <TraineesSelectedContext.Provider
      value={{
        traineesSelected,
        setTraineesSelected,
        handleSelectionChange,
        handleSelectAllChange,
        resetAllSelections,
        ignoreSelection,
      }}
    >
      {children}
    </TraineesSelectedContext.Provider>
  );
};

export const useTraineesSelectedContext = () =>
  useContext(TraineesSelectedContext);
