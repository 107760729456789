import { Button as MuiButton } from "@material-ui/core";
import { ButtonBox } from "./FormButton.styles";

export const FormButton = (props) => {
  return (
    <ButtonBox>
      <MuiButton
        fullWidth={true}
        color="primary"
        variant={props.variant}
        {...props}
      >
        {props.name}
      </MuiButton>
    </ButtonBox>
  );
};
