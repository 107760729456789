import axios from "axios";
import i18n from "../i18n";
import * as qs from "qs";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  FacultySyncStatusEnums,
  FACULTY_STATUS_SYNC_INTERVAL_MS,
} from "../constants";

import { moreItemsOption } from "../Utils/Autocomplete";

let timeoutId; // ID for API debounce timeout

/**
 * Returns Options values for the parameter passed, for the Selected Submission Id
 */
export const getEditFacultyAutocompleteOptions = async (
  parameter,
  size,
  inputValue,
  setFieldOptions,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const response = await axios({
      url: `${parameter}s/autocomplete`,
      params: { size: size, value: inputValue },
    });

    const optionsList =
      (response.data.values &&
        response.data.values.map((responseObj) => responseObj.value)) ||
      [];

    setFieldOptions(optionsList);
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};
export const getSubmissionsList = async (
  setSubmissionList,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: "/submissions",
      params: {
        vw: "brief",
        ps: 500,
      },
    };

    const response = await axios(request);
    if (response.data.count) {
      setSubmissionList(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

/**
 * API call to Export CSV
 */
export const getFacultyExportData = async (
  submission,
  facultyPreference,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const { filters, pagination, sort } = facultyPreference.facultyDemographics;
    const {
      facultyUniversityId,
      degreeCode,
      rankCode,
      trainingRole,
      departmentCode,
    } = filters;
    const { page, pageSize } = pagination;
    const { orderBy, order } = sort;

    let request = "";
    if (submission != null) {
      request = {
        url: `/submissions/${submission}/faculty`,
        params: {
          facultyUniversityIds: facultyUniversityId,
          degreeCode,
          rankCode,
          trainingRole,
          departmentCode,
          p: page + 1,
          ps: pageSize || 10000,
          s: `${orderBy ? `${orderBy}` : `fullName`}:${
            order ? `${order}` : `asc`
          }`,
        },
        responseType: "blob",
        headers: {
          Accept: "text/csv",
          "Content-type": "text/csv",
        },
      };
    }

    const response = await axios(request);

    if (response.data) {
      const currentDate = new Date();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const date = ("0" + currentDate.getDate()).slice(-2);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Export_Faculty_${currentDate.getFullYear()}${month}${date}.csv`
      );
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const getFacultyFiltersOptionValues = async (
  setDegreeList,
  setRankList,
  setProgramList,
  setTrainingRoleList,
  selectedSubmission,
  setAlert,
  clearAlert,
  getSelectedSubmissionName
) => {
  // **** Selected Submission Id ****

  try {
    //clearAlert();
    if (selectedSubmission) {
      const response = await axios({
        url: `/submissions/${selectedSubmission}/faculty/filters`,
      });

      const { degrees, facultyPrograms, ranks, trainingRoles } = response.data;

      // Helper Function to set Options in Filter Fieldss
      const getFieldValues = (trainingRoles) => {
        let valueArray = [];
        if (trainingRoles.count > 0) {
          valueArray = trainingRoles.values.map((filterVal) => filterVal.value);
        }

        return valueArray;
      };

      setDegreeList(degrees.values ?? []);
      setRankList(ranks.values ?? []);
      setProgramList(facultyPrograms.values ?? []);
      setTrainingRoleList(getFieldValues(trainingRoles));
    }
  } catch (error) {
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      setAlert("error", error.message);
    }
  }
};

/**
 * Returns List of Faculty for the input value entered
 *
 * @param {submissionId} submissionId - Input value for submissionId
 * @param {string} facultyVal - Input Value for Faculty
 * @param {string} setFacultyList - Setter function to set Faculty List
 */
export const loadSubmissionFacultyList = (
  submissionId,
  facultyVal,
  setFacultyList,
  setAlert,
  clearAlert,
  setNoOptionsText,
  t
) => {
  if (facultyVal.length < AUTOCOMPLETE_MIN_INPUT) {
    setFacultyList([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
    return;
  }

  clearTimeout(timeoutId); // Cancel current debounce timer

  timeoutId = setTimeout(async () => {
    try {
      clearAlert();

      const fetchSize = 50;
      const request = {
        method: "get",
        url: `/submissions/${submissionId}/faculty/autocomplete`,
        params: {
          value: facultyVal,
          size: fetchSize,
        },
      };

      const response = await axios(request);
      const facultyList = response.data.values;

      if (facultyList && facultyList.length >= fetchSize) {
        facultyList.push(moreItemsOption(fetchSize));
      }

      setFacultyList(facultyList || []);

      if (response.data.count === 0) {
        setNoOptionsText(t("faculty.autocomplete.noResultsText"));
      }
    } catch (error) {
      //Basic error handling added here as the functionality does not require to display case specific error messages.
      setAlert("error", error.message);
    }
  }, AUTOCOMPLETE_DEBOUNCE_DELAY);
};

/**
 * Returns List of Faculty for the input value entered
 *
 * @param {string} facultyVal - Input Value for Faculty
 * @param {string} setFacultyList - Setter function to set Faculty List
 */
export const loadFacultyList = (
  facultyVal,
  setFacultyList,
  setAlert,
  clearAlert,
  setNoOptionsText,
  t,
  submissionId
) => {
  if (facultyVal.length < AUTOCOMPLETE_MIN_INPUT) {
    setFacultyList([]);
    setNoOptionsText(t("globals.autocomplete.helperText"));
    return;
  }

  clearTimeout(timeoutId); // Cancel current debounce timer

  timeoutId = setTimeout(async () => {
    try {
      clearAlert();

      const fetchSize = 50;
      const request = {
        method: "get",
        url: "/faculty/autocomplete",
        params: {
          value: facultyVal,
          size: fetchSize,
          submissionId,
        },
        paramsSerializer: {
          serialize: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        },
      };

      const response = await axios(request);
      const facultyList = response.data.values;

      if (facultyList && facultyList.length >= fetchSize) {
        facultyList.push(moreItemsOption(fetchSize));
      }

      setFacultyList(facultyList || []);

      if (response.data.count === 0) {
        setNoOptionsText(t("faculty.autocomplete.noResultsText"));
      }
    } catch (error) {
      //Basic error handling added here as the functionality does not require to display case specific error messages.
      setAlert("error", error.message);
    }
  }, AUTOCOMPLETE_DEBOUNCE_DELAY);
};

const statusForUI = (facultyObj) =>
  facultyObj?.queueStatus === FacultySyncStatusEnums.ERROR &&
  facultyObj?.nextSyncAttemptTime
    ? ""
    : facultyObj?.queueStatus;

export const clearFacultyUpdates = (facultyToUpdate) => {
  if (facultyToUpdate) {
    facultyToUpdate.timeout && clearTimeout(facultyToUpdate.timeout);
    facultyToUpdate.faculty = {};
  }
};

const initFacultyUpdates = (
  facultyList,
  facultyToUpdate,
  setFacultyToUpdate,
  setAlert
) => {
  const facultyTmp = {};
  for (var i in facultyList) {
    if (!facultyList[i]) continue;

    facultyList[i].queueStatus = statusForUI(facultyList[i]);
    if (
      facultyList[i].queueStatus !== FacultySyncStatusEnums.SUCCESS &&
      facultyList[i].queueStatus !== FacultySyncStatusEnums.ERROR
    ) {
      facultyTmp[facultyList[i].universityId] = facultyList[i];
    }
  }

  facultyToUpdate.faculty = facultyTmp;

  let url = "";
  for (let i in facultyToUpdate.faculty) {
    let facultyObj = facultyToUpdate.faculty[i];
    url = facultyObj?._links?.self?.href;
    url = url && url.match("/submissions/[0-9]+/faculty");
    url = url && url[0];
    if (url) {
      break;
    }
  }

  fetchFacultyAsync(facultyToUpdate, setFacultyToUpdate, url, setAlert);
};

const fetchFacultyAsync = (
  facultyToUpdate,
  setFacultyToUpdate,
  url,
  setAlert
) => {
  const facultyUniversityIds = [];

  for (let uId in facultyToUpdate.faculty) {
    let facultyObj = facultyToUpdate.faculty[uId];
    if (
      facultyObj &&
      facultyObj.queueStatus !== FacultySyncStatusEnums.SUCCESS &&
      facultyObj.queueStatus !== FacultySyncStatusEnums.ERROR
    ) {
      facultyUniversityIds.push(facultyObj.universityId);
    }
  }

  if (url && facultyUniversityIds.length) {
    facultyToUpdate.timeout = setTimeout(async () => {
      try {
        let response = await axios({
          url: url,
          params: { facultyUniversityIds: facultyUniversityIds.join(",") },
        });

        const facultyListTmp = {};
        for (var i in response?.data?.values) {
          let facultyObjFetched = response?.data?.values[i];
          let facultyObj =
            facultyToUpdate.faculty[facultyObjFetched?.universityId];
          if (facultyObj) {
            facultyObj.queueStatus = statusForUI(facultyObjFetched);
            facultyListTmp[facultyObj?.universityId] = facultyObj;
          }
        }

        setFacultyToUpdate({
          count: 1 + (+facultyToUpdate.count || 0),
          faculty: facultyListTmp,
          ...facultyToUpdate,
        });
        fetchFacultyAsync(facultyToUpdate, setFacultyToUpdate, url, setAlert);
      } catch (error) {
        setAlert("error", error.message);

        //          facultyObj.queueStatus = FacultySyncStatusEnums.ERROR;
      }
    }, FACULTY_STATUS_SYNC_INTERVAL_MS);
  }
};

/**
 * Returns List of Faculty Demographics details,
 * for selected submission
 */
export const getFacultyDemographicsDetails = async (
  setData,
  facultyToUpdate,
  setFacultyToUpdate,
  setTotalCount,
  setIsLoading,
  selectedSubmission,
  facultyPreference,
  history,
  setAlert,
  clearAlert,
  setMetaData,
  getSelectedSubmissionName
) => {
  clearFacultyUpdates(facultyToUpdate);

  const { filters, pagination, sort } = facultyPreference.facultyDemographics;
  const { facultyName, degreeCode, rankCode, trainingRole, departmentCode } =
    filters;
  const { page, pageSize } = pagination;
  const { orderBy, order } = sort;

  try {
    //clearAlert();
    setIsLoading(true);

    let request = "";

    let response = "";
    if (selectedSubmission) {
      request = {
        url: `/submissions/${selectedSubmission}/faculty`,
        params: {
          facultyUniversityIds: facultyName?.value?.universityId,
          degreeCode: degreeCode?.code,
          rankCode: rankCode?.code,
          trainingRole,
          departmentCode: departmentCode?.code,
          p: +page + 1,
          ps: pageSize || 10,
          s: `${orderBy ? `${orderBy}` : `fullName`}:${
            order ? `${order}` : `asc`
          }`,
        },
      };

      response = await axios(request);

      const values = response.data.values ?? [];

      initFacultyUpdates(values, facultyToUpdate, setFacultyToUpdate, setAlert);
      setData(values);
      setMetaData(response.data.meta);
      setTotalCount(response.data.totalCount);
    } else {
      setData([]);
      setTotalCount(0);
      setMetaData(false);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setIsLoading(false);
  }
};

export const saveFacultySelection = async (
  setSnackBar,
  setFacultyPreference,
  facultyPreference,
  universityId,
  selectedSubmission,
  setAlert,
  clearAlert,
  setLoading
) => {
  try {
    setLoading(true);
    clearAlert();

    const request = {
      method: "post",
      url: `/submissions/${selectedSubmission}/faculty`,
      params: {
        universityId,
      },
    };

    const response = await axios(request);

    setAlert(
      "success",
      i18n.t("faculty.notification.addFaculty", {
        facultyName: `"${response.data.facultyFullName.trim()}"`,
      }),
      false
    );

    setFacultyPreference({
      ...facultyPreference,
      facultyDemographics: {
        ...facultyPreference.facultyDemographics,
        addFaculty: facultyPreference.facultyDemographics.addFaculty + 1,
      },
    });
  } catch (error) {
    if (error.code === "RESOURCE_EXISTS" && error.status === 409) {
      setAlert("error", i18n.t("faculty.notification.duplicateFaculty"));
    } else if (error.code === "RESOURCE_NOT_FOUND" && error.status === 404) {
      setAlert(
        "error",
        i18n.t("faculty.notification.facultyNotFound", {
          facultyName: universityId,
        })
      );
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * Delete Participating Faculty corresponding to Submission
 */
export const deleteParticipatingFaculty = async (
  deleteType,
  deleteUrl,
  setSnackBar,
  setFacultyPreference,
  facultyPreference,
  setAlert,
  clearAlert,
  setLoading
) => {
  let deleteFacultyResponse = "";
  try {
    setLoading(true);
    clearAlert();
    deleteFacultyResponse = await axios({
      method: "DELETE",
      url: `${deleteUrl}`,
    });

    if (deleteFacultyResponse.status === 204) {
      deleteType === "All"
        ? setSnackBar({
            show: true,
            message: "faculty.delete.deleteAllSuccessMessage",
          })
        : setSnackBar({
            show: true,
            message: "faculty.delete.deleteSuccessMessage",
          });

      setFacultyPreference({
        ...facultyPreference,
        facultyDemographics: {
          ...facultyPreference.facultyDemographics,
          delete: facultyPreference.facultyDemographics.delete + 1,
        },
      });
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * Returns Faculty values to be Edited, for the Selected Submission Id
 **/
export const getEditFacultyDetailsById = async (
  setFacultyEditFormValues,
  setFacultyDetailsFetchError,
  setLoading,
  facultyId,
  selectedSubmission,
  editFacultyInitialValues,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      url: `/submissions/${selectedSubmission}/faculty/${facultyId}`,
    });
    response.data.trainingRoles =
      (response.data.trainingRoles &&
        response.data.trainingRoles.map((val) => val.role)) ||
      [];
    setFacultyEditFormValues({ ...editFacultyInitialValues, ...response.data });
  } catch (error) {
    setFacultyDetailsFetchError("RESOURCE_NOT_FOUND");
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};
export const getFacultyPrimaryDepartmentById = async (
  setFacultyPrimaryDepartmentValues,
  facultyId,
  selectedSubmission,
  facultyPrimaryDepartmentValues
) => {
  try {
    const response = await axios({
      url: `/submissions/${selectedSubmission}/faculty/${facultyId}/departments`,
    });
    setFacultyPrimaryDepartmentValues({
      ...facultyPrimaryDepartmentValues,
      ...response.data,
    });

    return response.data;
  } catch (error) {}
};

/**
 * Returns Success/Error Response Data for the Update Faculty API call
 *
 * @param {string} submissionId - Selected Submission ID
 * @param {string} values  - Data to be Updated for the Faculty
 */
export const putFacultyDetailsByIdUpdate = async (
  setCommonsIdFieldError,
  submissionId,
  values,
  props,
  setAlert,
  clearAlert
) => {
  /* Do not mutate original Formik.values object,
   * Created a new object (with a new reference) to submit the form values
   *  This is needed, to keep the original Form values intact,
   *  as in case of any API Error on Form Submit,
   *  original Form state should be available,as it was before Submit.
   */

  try {
    clearAlert();
    let editFacultyFormValues = Object.assign({}, values);

    if (editFacultyFormValues.trainingRoles) {
      editFacultyFormValues.trainingRoles =
        editFacultyFormValues.trainingRoles.map((val) => ({
          displayText: val,
          role: val,
        }));
    }
    // ** Request Object **
    const request = {
      method: "PUT",
      url: `/submissions/${submissionId}/faculty`,
      data: { ...editFacultyFormValues },
    };

    const response = await axios(request);

    // **** Redirect to /faculty on Successful form Submit ****
    props.history.push({
      pathname: "/faculty",
    });
    setAlert(
      "success",
      i18n.t("faculty.notification.updateFaculty", {
        facultyName: `"${response.data.facultyFullName.trim()}"`,
      }),
      true
    );
  } catch (error) {
    if (error.status === 409) {
      setCommonsIdFieldError({ value: values.commonsId, isError: true });
    } else {
      setAlert("error", error.message);
    }
  }
};
