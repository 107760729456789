import { useState, createContext, useContext } from "react";

const PagingSortingContext = createContext();

export const PagingSortingProvider = ({ children }) => {
  const [facultyPagingSortingStored, setFacultyPagingSortingStored] = useState(
    {}
  );
  const [grantsPagingSortingStored, setGrantsPagingSortingStored] = useState(
    {}
  );
  const [traineePagingSortingStored, setTraineePagingSortingStored] = useState(
    {}
  );
  const [applicantPagingSortingStored, setApplicantPagingSortingStored] =
    useState({});
  const [outcomesPagingSortingStored, setOutcomesPagingSortingStored] =
    useState({});
  const [submissionPagingSortingStored, setSubmissionPagingSortingStored] =
    useState({});

  return (
    <PagingSortingContext.Provider
      value={{
        value: {
          facultyPagingSortingStored,
          setFacultyPagingSortingStored,
          grantsPagingSortingStored,
          setGrantsPagingSortingStored,
          traineePagingSortingStored,
          setTraineePagingSortingStored,
          applicantPagingSortingStored,
          setApplicantPagingSortingStored,
          outcomesPagingSortingStored,
          setOutcomesPagingSortingStored,
          submissionPagingSortingStored,
          setSubmissionPagingSortingStored,
        },
      }}
    >
      {children}
    </PagingSortingContext.Provider>
  );
};

export const usePagingSortingContext = () => {
  return useContext(PagingSortingContext);
};
