import { useState } from "react";
import {
  Check as MuiCheckIcon,
  ErrorOutline as MuiErrorOutlineIcon,
  Close as MuiCloseIcon,
} from "@material-ui/icons";
import {
  Collapse as MuiCollapse,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { StyledMessagePanel } from "./MessagePanel.styles";

const useStyles = makeStyles((theme) => ({
  messageBox: {
    fontSize: "16px",
    fontWeight: 400,
    margin: "16px 0px",
  },
  success: {
    color: "#009779",
    backgroundColor: "rgba(229, 244, 241, 0.5)",
  },
  error: {
    color: "#8c1515",
    backgroundColor: "rgba(243, 231, 231, 0.5)",
  },
}));

export const MessagePanel = (props) => {
  const classes = useStyles();
  const [messagePanelOpen, setMessagePanelOpen] = useState(true);

  return (
    <MuiCollapse in={messagePanelOpen}>
      <StyledMessagePanel>
        <MuiAlert
          action={
            <MuiIconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                props.setMessagePanelOpen
                  ? props.setMessagePanelOpen(false)
                  : setMessagePanelOpen(false);
              }}
            >
              <MuiCloseIcon fontSize="inherit" />
            </MuiIconButton>
          }
          severity={props.messageType}
          icon={getIcon(props.messageType)}
          className={[classes.messageBox, classes[props.messageType]].join(" ")}
        >
          <MuiTypography>{props.message}</MuiTypography>
        </MuiAlert>
      </StyledMessagePanel>
    </MuiCollapse>
  );
};

const getIcon = (messageType) => {
  switch (messageType) {
    case "success":
      return <MuiCheckIcon />;
    case "error":
      return <MuiErrorOutlineIcon />;
    default:
      break;
  }
};
