import { useLocation } from "react-router-dom";
import { Typography as MuiTypography } from "@material-ui/core";
import { useStyles } from "./PageTitle.styles";
import { MetaDataInformation } from "../MetaDataInformation/MetaDataInformation";

export const PageTitle = (props) => {
  const location = useLocation();
  const classes = useStyles();

  if (location.pathname.match(/admin\/submissions\/new/)) {
    return null;
  }
  if (location.pathname.match(/edit/)) {
    return null;
  }

  return (
    <MuiTypography variant="h1" className={classes.pageTitle}>
      {props.title}
      &nbsp;
      <MetaDataInformation
        variant="h5"
        modalDescription={props.metaDataModalDescription}
      >
        {location}
      </MetaDataInformation>
    </MuiTypography>
  );
};
