import { useEffect } from "react";

/**
 * Overrides the default behavior of the browser's back button.
 * @param callbackFn The callback to execute when the back button is clicked.
 */
export const useBackButtonCallback = (callbackFn) => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", callbackFn);
    return () => {
      window.removeEventListener("popstate", callbackFn);
    };
  }, [callbackFn]);
};
