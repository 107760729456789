import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ASConfirmation } from "../UI/ASConfirmation/ASConfirmation";
import { Typography as MuiTypography } from "@material-ui/core";
/**  Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { useTraineeContext } from "../../services/traineeContext";
import {
  useTraineesSelectedContext,
  SelectAllStates,
} from "../../services/traineesSelectedContext";
import { removeSelectedTrainees } from "../../services/traineeService";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";

export const RemoveTrainees = (props) => {
  const {
    openRemoveTraineesDialog,
    setOpenRemoveTraineesDialog,
    toggleTraineesRemoved,
    totalCount,
  } = props;

  const [setTraineeList] = useState([]);
  const { setAlert, clearAlert } = useAlertContext();

  const { setTraineePreferences, traineePreferences } = useTraineeContext();

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const submissionId = getSelectedSubmission();

  const { resetAllSelections, traineesSelected } = useTraineesSelectedContext();

  const { t } = useTranslation();
  const [countToDelete, setCountToDelete] = useState(0);

  useEffect(() => {
    if (traineesSelected.selectAllState === SelectAllStates.NONE_SELECTED) {
      setCountToDelete(
        Object.keys(traineesSelected.individualSelections).filter(
          (key) => traineesSelected.individualSelections[key] === true
        ).length
      );
    } else {
      setCountToDelete(
        totalCount -
          Object.keys(traineesSelected.individualSelections).filter(
            (key) => traineesSelected.individualSelections[key] === false
          ).length
      );
    }
  }, [totalCount, traineesSelected, setCountToDelete]);

  const handleCancel = () => {
    clearAlert();
    setOpenRemoveTraineesDialog(false);
  };

  return (
    <ASConfirmation
      open={openRemoveTraineesDialog}
      message={
        <>
          <MuiTypography variant="body1" style={{ fontWeight: 700 }}>
            {t("trainee.mainView.delete.confirmationBox.body", {
              count: countToDelete,
              plural: countToDelete !== 1 ? "s" : "",
            })}
          </MuiTypography>
          <MuiTypography variant="body1">
            {t("globals.list.messages.wantToContinue")}
          </MuiTypography>
        </>
      }
      title={t("trainee.mainView.delete.confirmationBox.title")}
      onClose={(choice) => {
        if (choice) {
          removeSelectedTrainees(
            submissionId,
            setOpenRemoveTraineesDialog,
            setAlert,
            clearAlert,
            resetAllSelections,
            toggleTraineesRemoved,
            setTraineeList,
            setTraineePreferences,
            traineePreferences,
            traineesSelected
          );
        }
        handleCancel();
      }}
      okLabel={t("trainee.mainView.delete.confirmationBox.okLabel")}
      cancelLabel={t("trainee.mainView.delete.confirmationBox.cancelLabel")}
    />
  );
};
