import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper as MuiPaper,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  Grid as MuiGrid,
  Button as MuiButton,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Link as MuiLink,
} from "@material-ui/core";
import { CloudDownload as CloudDownloadIcon } from "@material-ui/icons";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import CustomTable from "../UI/Table/Table";
import CustomDialog from "../UI/Dialog/Dialog";
import { FacultyTrainingRoles } from "../../constants";
/** Services */
import {
  getOutputTable2,
  getOutputTable2Popup,
  getExportData,
  getOutputSubmissionsList,
} from "../../services/outputService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./OutputTable2.styles";
import { useCommonStyles } from "../shared/common.styles";

const OutputTable2 = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [facultyFullName, setFacultyFullName] = useState(null);
  const [traineeCategoryForDialogTitle, setTraineeCategoryForDialogTitle] =
    useState(null);
  const [facultyUniversityId, setFacultyUniversityId] = useState(null);
  const [traineeType, setTraineeType] = useState(null);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const submissionId = getSelectedSubmission();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [outputData, setOutputData] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const [openTable2TraineesPopupDialog, setOpenTable2TraineesPopupDialog] =
    useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
  }, [clearAlert, setAlert]);

  /**
   * API call to get table 2 data
   */
  useEffect(() => {
    getOutputTable2(
      submissionId,
      setOutputData,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [submissionId, setOutputData, clearAlert, setAlert]);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
  };

  /**
   *
   * @param row
   *  row.
   * @param columnHeader
   *  columnHeader.
   */
  const table2ModalOpenEventHandler = (row, columnHeader, type) => {
    setOpenTable2TraineesPopupDialog(true);
    setFacultyFullName(row.facultyFullName);
    setTraineeCategoryForDialogTitle(columnHeader);
    setFacultyUniversityId(row.facultyUniversityId);
    setTraineeType(type);
  };

  const table2Label = "output.mainView.list.tableColumns";

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      `Table_2`,
      `table-two`,
      getSelectedSubmission(),
      setAlert,
      clearAlert
    );
  };

  return (
    <MuiGrid container direction="column">
      {!openTable2TraineesPopupDialog && alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <MuiGrid item classes={{ item: commonClasses.outputAutoCompleteWrapper }}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        item
        container
        justify="flex-end"
        className={classes.actionButton}
      >
        <MuiButton
          variant="outlined"
          onClick={handleExport}
          disabled={!outputData}
        >
          <CloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}{" "}
        </MuiButton>
      </MuiGrid>
      <MuiGrid item container>
        <MuiTableContainer
          component={MuiPaper}
          classes={{ root: classes.fixHeader }}
        >
          <MuiTable aria-label="customized table" stickyHeader>
            <MuiTableHead className={classes.blueHeader}>
              <MuiTableRow>
                <MuiTableCell align="left" key="name">
                  {t(`${table2Label}.name`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="degrees">
                  {t(`${table2Label}.degrees`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="rank">
                  {t(`${table2Label}.rank`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="department">
                  {t(`${table2Label}.department`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="intrest">
                  {t(`${table2Label}.intrest`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="training">
                  {t(`${table2Label}.training`)}
                </MuiTableCell>

                <MuiTableCell align="left" key="predocs">
                  {t(`${table2Label}.predocs`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="predocsGraduated">
                  {t(`${table2Label}.predocsGraduated`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="predocsContinued">
                  {t(`${table2Label}.predocsContinued`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="postdocs">
                  {t(`${table2Label}.postdocs`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="postdocsGraduated">
                  {t(`${table2Label}.postdocsGraduated`)}
                </MuiTableCell>
                <MuiTableCell align="left" key="postdocsContinued">
                  {t(`${table2Label}.postdocsContinued`)}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {!loading &&
                outputData &&
                outputData.length &&
                outputData.map((row, index) => {
                  const { outputTableTwoDto } = row;
                  return (
                    <MuiTableRow key={encodeURIComponent(index)}>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.facultyFullName}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.degrees
                          ? outputTableTwoDto.degrees
                              .map((degree) => degree["shortName"])
                              .join(", ")
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.rank
                          ? outputTableTwoDto.rank.name
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.departments
                          ? outputTableTwoDto.departments
                              .map((department) => department["name"])
                              .join(", ")
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.researchInterest
                          ? outputTableTwoDto.researchInterest
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {" "}
                        {outputTableTwoDto.trainingRoles
                          ? outputTableTwoDto.trainingRoles
                              .map((role) =>
                                t(FacultyTrainingRoles[role["role"]])
                              )
                              .join(", ")
                          : ""}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.preDoctoratesInTraining ? (
                          <MuiLink
                            component="button"
                            onClick={() =>
                              table2ModalOpenEventHandler(
                                outputTableTwoDto,
                                t(`${table2Label}.predocs`),
                                "PREDOC_IN_TRAINING"
                              )
                            }
                          >
                            {outputTableTwoDto.preDoctoratesInTraining}
                          </MuiLink>
                        ) : (
                          0
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.preDoctoratesGraduate ? (
                          <MuiLink
                            component="button"
                            onClick={() =>
                              table2ModalOpenEventHandler(
                                outputTableTwoDto,
                                t(`${table2Label}.predocsGraduated`),
                                "PREDOC_GRADUATE"
                              )
                            }
                          >
                            {outputTableTwoDto.preDoctoratesGraduate}
                          </MuiLink>
                        ) : (
                          0
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.preDoctoratesContinuedInResearchRelatedCareer ? (
                          <MuiLink
                            component="button"
                            onClick={() =>
                              table2ModalOpenEventHandler(
                                outputTableTwoDto,
                                t(`${table2Label}.predocsContinued`),
                                "PREDOC_CONTINUED_IN_RESEARCH_RELATED_CAREER"
                              )
                            }
                          >
                            {
                              outputTableTwoDto.preDoctoratesContinuedInResearchRelatedCareer
                            }
                          </MuiLink>
                        ) : (
                          0
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.postDoctoratesInTraining ? (
                          <MuiLink
                            component="button"
                            onClick={() =>
                              table2ModalOpenEventHandler(
                                outputTableTwoDto,
                                t(`${table2Label}.postdocs`),
                                "POSTDOC_IN_TRAINING"
                              )
                            }
                          >
                            {outputTableTwoDto.postDoctoratesInTraining}
                          </MuiLink>
                        ) : (
                          0
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.postDoctoratesCompletedTraining ? (
                          <MuiLink
                            component="button"
                            onClick={() =>
                              table2ModalOpenEventHandler(
                                outputTableTwoDto,
                                t(`${table2Label}.postdocsGraduated`),
                                "POSTDOC_GRADUATE"
                              )
                            }
                          >
                            {outputTableTwoDto.postDoctoratesCompletedTraining}
                          </MuiLink>
                        ) : (
                          0
                        )}
                      </MuiTableCell>
                      <MuiTableCell align="left">
                        {outputTableTwoDto.postDoctoratesContinuedInResearchRelatedCareer ? (
                          <MuiLink
                            component="button"
                            onClick={() =>
                              table2ModalOpenEventHandler(
                                outputTableTwoDto,
                                t(`${table2Label}.postdocsContinued`),
                                "POSTDOC_CONTINUED_IN_RESEARCH_RELATED_CAREER"
                              )
                            }
                          >
                            {
                              outputTableTwoDto.postDoctoratesContinuedInResearchRelatedCareer
                            }
                          </MuiLink>
                        ) : (
                          0
                        )}
                      </MuiTableCell>
                    </MuiTableRow>
                  );
                })}
              {!loading && (!outputData || outputData?.length === 0) && (
                <MuiTableRow className="noData">
                  <MuiTableCell
                    colSpan="12"
                    classes={{ body: classes.noDataTableCell }}
                  >
                    {t("output.mainView.list.noData")}
                  </MuiTableCell>
                </MuiTableRow>
              )}
              {loading && (
                <MuiTableRow>
                  <MuiTableCell colSpan="12">
                    <MuiTypography
                      variant="h6"
                      color="textSecondary"
                      align="center"
                      paragraph={false}
                    >
                      {loading
                        ? t("globals.list.messages.fetchingInfo")
                        : t("applicants.mainView.list.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
          {loading && (
            <MuiBox sx={{ width: "100%" }}>
              <MuiLinearProgress />
            </MuiBox>
          )}
        </MuiTableContainer>

        {/* Dialog Box Output */}
        {openTable2TraineesPopupDialog && (
          <Table2TraineesPopupDialog
            submissionId={submissionId}
            facultyFullName={facultyFullName}
            facultyUniversityId={facultyUniversityId}
            traineeType={traineeType}
            traineeCategoryForDialogTitle={traineeCategoryForDialogTitle}
            openTable2TraineesPopupDialog={openTable2TraineesPopupDialog}
            setOpenTable2TraineesPopupDialog={setOpenTable2TraineesPopupDialog}
          />
        )}
      </MuiGrid>
    </MuiGrid>
  );
};

const Table2TraineesPopupDialog = (props) => {
  const {
    submissionId,
    facultyFullName,
    facultyUniversityId,
    traineeType,
    traineeCategoryForDialogTitle,
    openTable2TraineesPopupDialog,
    setOpenTable2TraineesPopupDialog,
  } = props;

  const { t } = useTranslation();
  const [traineeData, setTraineeData] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const [dialogLoading, setDialogLoading] = useState(false);

  const modalHeader = {
    "#": "#",
    name: t(`output.modalDialog.list.tableColumns.name`),
    sunetId: t(`output.modalDialog.list.tableColumns.sunetId`),
  };
  const modalTitle = facultyFullName + " - " + traineeCategoryForDialogTitle;

  useEffect(() => {
    getOutputTable2Popup(
      submissionId,
      facultyUniversityId,
      traineeType,
      setTraineeData,
      setAlert,
      clearAlert,
      setDialogLoading
    );
  }, [submissionId, facultyUniversityId, traineeType, clearAlert, setAlert]);

  const handleCloseHandler = () => {
    clearAlert();
    setOpenTable2TraineesPopupDialog(false);
  };

  return (
    <CustomDialog
      closeButtonText="CLOSE"
      title={modalTitle}
      handleClose={handleCloseHandler}
      open={openTable2TraineesPopupDialog}
    >
      {alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <CustomTable
        sticky
        label={modalTitle}
        header={dialogLoading ? {} : modalHeader}
        body={traineeData}
        tableClass="modalBox"
        loading={dialogLoading}
      />
    </CustomDialog>
  );
};

export const Table2 = OutputTable2;
