import i18n from "../i18n";

import {
  OutcomePreDocTrainingSupportTypeEnums,
  OutcomePostDocTrainingSupportTypeEnums,
} from "../constants";

export const getTrainingTypeNameByTrainingTypeCode = (
  trainingTypeCode,
  nameVariation
) => {
  switch (true) {
    case Object.keys(OutcomePreDocTrainingSupportTypeEnums).includes(
      trainingTypeCode
    ):
      return i18n.t(
        `${OutcomePreDocTrainingSupportTypeEnums[trainingTypeCode]}.${nameVariation}`
      );
    case Object.keys(OutcomePostDocTrainingSupportTypeEnums).includes(
      trainingTypeCode
    ):
      return i18n.t(
        `${OutcomePostDocTrainingSupportTypeEnums[trainingTypeCode]}.${nameVariation}`
      );
    default:
      return "";
  }
};

export const normalizeText = (str, defaultValue) => {
  return (str && str.trim && str.trim()) || defaultValue;
};

export const buildYearRange = (yearsInPast, yearsInFuture) => {
  const emptyArray = new Array(yearsInPast + yearsInFuture + 1);
  const earliestYear = new Date().getFullYear() - yearsInPast;

  return Array.from(emptyArray, (_, index) => earliestYear + index);
};
