import { TableCell } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    "&.MuiTableContainer-root": {
      width: "100%",
      margin: 0,
      overflow: "visible",
    },
    "& .MuiTableCell-root": {
      padding: "6px 20px",
    },
    "& *": {
      fontSize: 14,
    },
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F0F4F5",
    color: "#333333",
    fontWeight: theme.typography.fontWeightBold,
    verticalAlign: "top",
  },
  body: {
    color: "#333333",
    "& button": {
      color: "#007C92",
    },
  },
}))(TableCell);
