import { makeStyles as MuiMakeStyles } from "@material-ui/core/styles";

export const useStyles = MuiMakeStyles((theme) => ({
  root: {
    backgroundColor: "#F4F4F4",
    "& .MuiTab-wrapper": {
      letterSpacing: 2,
    },
    "& .MuiTab-root": {
      fontWeight: theme.typography.fontWeightBold,
      color: "rgba(0, 0, 0,0.54)",
      fontSize: 14,
    },
    "& .Mui-selected": {
      color: "#007C92",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#007C92",
    },
  },
}));
