import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { ThemeProvider as ASMuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import AppPage from "./components/AppPage";
import { useAuth } from "./services/authService";
import { UsersProvider } from "./services/usersService";
import { ASAppPage, ASAlertProvider } from "@stanford-tds/as-components";
import { theme, asMuiTheme } from "./theme";

// src/App.js
// App component containing the standard components and configuration for all applications.
// Applications should modify the AppPage component in components/AppPage and should leave this file unmodified.
const App = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <div style={{ display: "grid" }}>
      {auth.isAuthenticated && (
        <>
          <Helmet>
            <title>{t("app.title")}</title>
            <meta name="description" content={t("app.description")} />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,600,700,200italic,300italic,400italic,700italic|Fjord+One"
            />
          </Helmet>
          <BrowserRouter>
            <ASMuiThemeProvider theme={asMuiTheme}>
              <ThemeProvider theme={theme}>
                <ASAlertProvider>
                  <UsersProvider>
                    <ASAppPage>
                      <AppPage />
                    </ASAppPage>
                  </UsersProvider>
                </ASAlertProvider>
              </ThemeProvider>
            </ASMuiThemeProvider>
          </BrowserRouter>
        </>
      )}
    </div>
  );
};

export default App;
