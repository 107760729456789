import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ASConfirmation } from "../UI/ASConfirmation/ASConfirmation";
import { Typography as MuiTypography } from "@material-ui/core";
/**  Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import {
  useApplicantsSelectedContext,
  SelectAllStates,
} from "../../services/applicantsSelectedContext";
import { useApplicantsFilterContext } from "../../services/applicantContext";
import { deleteSelectedApplicants } from "../../services/applicantService";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";

export const RemoveApplicants = (props) => {
  const {
    openRemoveApplicantsDialog,
    setOpenRemoveApplicantsDialog,
    toggleApplicantsRemoved,
    totalCount,
  } = props;

  const { setAlert, clearAlert } = useAlertContext();

  // **** Selected Submission Id ****
  const [getSelectedSubmission] = useSelectedSubmission().value;

  const { applicantsFilterPreferences, setApplicantsFilterPreferences } =
    useApplicantsFilterContext();
  const { resetAllSelections, applicantsSelected } =
    useApplicantsSelectedContext();

  const { t } = useTranslation();

  const handleCancel = () => {
    clearAlert();
    setOpenRemoveApplicantsDialog(false);
  };

  const submissionId = getSelectedSubmission();

  const [countToDelete, setCountToDelete] = useState(0);

  useEffect(() => {
    if (applicantsSelected.selectAllState === SelectAllStates.NONE_SELECTED) {
      setCountToDelete(
        Object.keys(applicantsSelected.individualSelections).filter(
          (key) => applicantsSelected.individualSelections[key] === true
        ).length
      );
    } else {
      setCountToDelete(
        totalCount -
          Object.keys(applicantsSelected.individualSelections).filter(
            (key) => applicantsSelected.individualSelections[key] === false
          ).length
      );
    }
  }, [totalCount, applicantsSelected, setCountToDelete]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (openRemoveApplicantsDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openRemoveApplicantsDialog]);

  return (
    <ASConfirmation
      open={openRemoveApplicantsDialog}
      message={
        <>
          <MuiTypography variant="body1" style={{ fontWeight: 700 }}>
            {t("applicants.mainView.delete.confirmationBox.body", {
              count: countToDelete,
              plural: countToDelete !== 1 ? "s" : "",
            })}
          </MuiTypography>
          <MuiTypography variant="body1">
            {t("globals.list.messages.wantToContinue")}
          </MuiTypography>
        </>
      }
      title={t("applicants.mainView.delete.confirmationBox.title")}
      onClose={(choice) => {
        if (choice) {
          deleteSelectedApplicants(
            submissionId,
            setOpenRemoveApplicantsDialog,
            setAlert,
            clearAlert,
            resetAllSelections,
            toggleApplicantsRemoved,
            setApplicantsFilterPreferences,
            applicantsFilterPreferences,
            applicantsSelected
          );
        }
        handleCancel();
      }}
      okLabel={t("applicants.mainView.delete.confirmationBox.okLabel")}
      cancelLabel={t("applicants.mainView.delete.confirmationBox.cancelLabel")}
    />
  );
};
