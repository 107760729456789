import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  Paper as MuiPaper,
  Typography as MuiTypography,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableBody as MuiTableBody,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
/** Custom Components */
import { ShowSnackBar } from "../../../Utils/ActionTrigger";
import { useSetLoading } from "../../../Utils/loadingState";
import TraineeDetailsTableHead from "./TraineeDetailsTableHeader";
import TraineeDetailsTableBody from "./TraineeDetailsTableBody";
import { ASConfirmation } from "../../UI/ASConfirmation/ASConfirmation";
/** Services */
import {
  getAllTraineeList,
  removeAddTraineeById,
} from "../../../services/traineeService";
import { useSelectedSubmission } from "../../../services/selectedSubmissionService";
import { useTraineesSelectedContext } from "../../../services/traineesSelectedContext";
import { useTraineeContext } from "../../../services/traineeContext";
/** Context and Component to show the error on UI */
import { useAlertContext } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./TraineeDetails.styles";

// ************ Display Trainee Details Table ****************
const TraineeDetailsDef = (props, ref) => {
  const { t } = useTranslation();
  const traineeMainViewMessages = "trainee.mainView";
  const classes = useStyles();
  const { handleSelectionChange, ignoreSelection } =
    useTraineesSelectedContext();
  const { traineePreferences, setTraineePreferences } = useTraineeContext();

  // ********** State Values ********
  const order = useMemo(
    () => traineePreferences.traineeValues.sort.order || "asc",
    [traineePreferences]
  );
  const orderBy = useMemo(
    () => traineePreferences.traineeValues.sort.orderBy || "trainee",
    [traineePreferences]
  );
  const page = useMemo(
    () => traineePreferences.traineeValues.pagination.page || 0,
    [traineePreferences]
  );
  const pageSize = useMemo(
    () => traineePreferences.traineeValues.pagination.pageSize || 10,
    [traineePreferences]
  );

  const [, setOffset] = useState(page * pageSize);
  const { setMetaData } = useSelectedSubmission();
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [actionType, setActionType] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [confirmation, setConfirmation] = useState({});
  const [traineeList, setTraineeList] = useState([]);
  const [, setError] = useState(null);
  const subId = getSelectedSubmission();
  const { history, summary, setSummary } = props;
  const [loading, setLoadingItems] = useSetLoading();
  const { setAlert, clearAlert } = useAlertContext();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setTraineePreferences({
      ...traineePreferences,
      traineeValues: {
        ...traineePreferences.traineeValues,
        sort: {
          order: isAsc,
          orderBy: property,
        },
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setOffset(page * pageSize);
    setTraineePreferences({
      ...traineePreferences,
      traineeValues: {
        ...traineePreferences.traineeValues,
        pagination: {
          ...traineePreferences.traineeValues.pagination,
          page: +newPage,
        },
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    let pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      summary.totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }
    setTraineePreferences({
      ...traineePreferences,
      traineeValues: {
        ...traineePreferences.traineeValues,
        pagination: {
          ...traineePreferences.traineeValues.pagination,
          page: pageValue,
          pageSize: parseInt(event.target.value, 10),
        },
      },
    });
  };

  const toggleTrainee = (traineeId, row, type) => {
    setSnackbar(false);
    setActionType(type);
    if (row.isActive) {
      ignoreSelection(row);
    } else {
      handleSelectionChange({ target: { checked: false } }, row);
    }
    removeAddTraineeById(
      getSelectedSubmission(),
      traineeId,
      row,
      setSnackbar,
      setAlert,
      clearAlert,
      setLoadingItems,
      traineePreferences,
      setTraineePreferences
    );
  };

  const handleRemove = (action) => {
    if (action === true) {
      toggleTrainee(
        confirmation.traineeId,
        confirmation.row,
        confirmation.type
      );
    }
    setConfirmation({
      ...confirmation,
      confirm: false,
    });
  };
  const handleConfirmation = (traineeId, row, type) => {
    setConfirmation({
      traineeId: traineeId,
      row: row,
      type: type,
      confirm: true,
    });
  };

  const stringifiedTraineePreferences = JSON.stringify(traineePreferences);

  useEffect(() => {
    getAllTraineeList(
      setTraineeList,
      setSummary,
      subId,
      setLoadingItems,
      setAlert,
      clearAlert,
      setError,
      traineePreferences,
      history,
      setMetaData,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    order,
    orderBy,
    subId,
    stringifiedTraineePreferences,
    history,
  ]);

  // **** Display Add/Remove Trainee Snackbar Message ****
  const showConfirmationSnackBar = () =>
    snackbar ? (
      <ShowSnackBar
        message={
          actionType === "delete"
            ? t(`${traineeMainViewMessages}.removeTraineeMessage`)
            : t(`${traineeMainViewMessages}.addTraineeMessage`)
        }
      />
    ) : null;

  // **** Display Remove Confimation Dialog ****
  const showDialogRemoveConfirmation = () =>
    confirmation.confirm ? (
      <ASConfirmation
        onClose={handleRemove}
        open={true}
        title={t(`${traineeMainViewMessages}.delete.confirmationBox.title`)}
        message={t(`${traineeMainViewMessages}.delete.confirmationBox.body`, {
          name: confirmation.row.trainee.fullName,
        })}
        okLabel={t(`${traineeMainViewMessages}.delete.confirmationBox.okLabel`)}
        cancelLabel={t(
          `${traineeMainViewMessages}.delete.confirmationBox.cancelLabel`
        )}
      />
    ) : null;

  return (
    <>
      {showConfirmationSnackBar()}
      {showDialogRemoveConfirmation()}

      <MuiTableContainer
        component={MuiPaper}
        classes={{ root: classes.tableContainerRoot }}
      >
        <MuiTable
          aria-label="customized table"
          stickyHeader
          style={{ tableLayout: "fixed" }}
        >
          <TraineeDetailsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            hasAddTraineeAccess={props.hasAddTraineeAccess}
          />
          {loading ? (
            <MuiTableBody>
              <MuiTableRow>
                <MuiTableCell colSpan="12">
                  <MuiTypography
                    variant="h6"
                    color="textSecondary"
                    align="center"
                    paragraph={false}
                  >
                    {t("globals.list.messages.fetchingInfo")}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          ) : traineeList.totalCount ? (
            <TraineeDetailsTableBody
              traineeList={traineeList}
              handleConfirmation={handleConfirmation}
              toggleTrainee={toggleTrainee}
            />
          ) : (
            <MuiTableBody>
              <MuiTableRow className="noData">
                <MuiTableCell
                  colSpan="12"
                  classes={{ body: classes.noDataTableCell }}
                >
                  {t("trainee.mainView.list.noData")}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableBody>
          )}
        </MuiTable>
        {loading && (
          <MuiBox sx={{ width: "100%" }}>
            <MuiLinearProgress />
          </MuiBox>
        )}
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={traineeList.totalCount || 0}
          rowsPerPage={pageSize}
          page={+page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </MuiTableContainer>
    </>
  );
};

export const TraineeDetails = TraineeDetailsDef;
