import styled from "styled-components";
import { Drawer } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  resetFilterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export const FilterPanelStyle = styled.span`
  button[aria-label="open drawer"] {
    margin-left: 20px;
    padding: 0;
    margin-top: 24px;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  && .MuiDrawer-root {
    width: 300px;
    flex-shrink: 0;

    .MuiPaper-root {
      width: 300px;
      z-index: 0;
      position: absolute;
      padding-right: 24px;
      padding-left: 24px;

      .MuiTypography-root {
        color: #333333;
        font-family: ${(props) =>
          props.theme.app != null ? props.theme.app.fontFamily : "inherit"};
      }
      .MuiTypography-h6 {
        font-size: 1.1rem;
      }

      .MuiIconButton-root {
        margin-left: 0px;
        color: #333;
      }
      .MuiAutocomplete-popupIndicator {
        margin-right: 0px;
        margin-top: 3px;
        color: rgba(0, 0, 0, 0.54);
        background: white;
      }
      .PaperToolbar {
        display: flex;
        align-items: center;
        min-height: 0;
        margin-top: 24px;
        justify-content: space-between;
      }
    }
    .MuiIconButton-root {
      margin-left: 24px;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.04);
    }
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1.6rem;
    }
  }
  .MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
    top: 1px;
    .MuiSvgIcon-fontSizeSmall {
      font-size: 1rem;
    }
  }
  .drawer-root-close {
    overflow-x: hidden;
    display: none;
  }
  .MuiSvgIcon-fontSizeSmall {
    font-size: 1.6rem;
  }
  .MuiIconButton-root {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .MuiDrawer-paper {
    z-index: 0;
  }
  .filterOptions {
    width: -webkit-fill-available;
    .MuiInputBase-root {
      height: 40px;
      width: 100%;
      border-radius: 6px;
      text-align: center;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, 10px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
    .MuiSelect-iconOutlined {
      right: 1px;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
      .MuiAutocomplete-input {
      padding: 5.5px 4px;
    }
  }
  .filterOptions .MuiInputBase-formControl {
    text-align: left;
  }
  .filterTitle {
    font-size: 1.2rem;
  }
  .filterOptions .MuiFormControl-root {
    display: block;
    margin: 16px 0px;
  }
  .filterOptions .MuiFormLabel-root {
    line-height: 1.5;
  }
`;

export const DrawerStyle = styled(Drawer)`
  ${(props) =>
    props.mobile
      ? `
    .MuiDrawer-paperAnchorLeft {
      width: 300px;
      padding-right: 24px;
      padding-left: 24px;
    }
    .MuiIconButton-root {
      flex:  1
      justify-content: flex-end;
    }
    .PaperToolbar {
      display: flex;
    }
    .MuiTypography-h6 {
      align-self: center;
      margin-left: 8px;
    }
    .filterOptions {
      width: -webkit-fill-available;
      .MuiInputBase-root {
        height: 40px;
        width: 100%;
        border-radius: 6px;
        text-align: center;
      }
      .MuiInputLabel-outlined {
        transform: translate(14px, 10px) scale(1);
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
      .MuiSelect-iconOutlined {
        right: 1px;
      }
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
        .MuiAutocomplete-input {
        padding: 5.5px 4px;
      }
    }
    `
      : ""}
`;
