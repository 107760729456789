import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Info as MuiInfoIcon } from "@material-ui/icons";
import { formatInTimeZone } from "date-fns-tz";
import CustomDialog from "../UI/Dialog/Dialog";
import CustomTable from "../UI/Table/Table";
import { DataSourceEnums } from "../../constants";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";

export const MetaDataInformation = (props) => {
  const location = props.children.pathname;
  const { metaData } = useSelectedSubmission();
  let dialogBox = null;
  const [open, setOpen] = useState(false);
  const handleCloseHandler = () => {
    setOpen(false);
  };
  const modalTitle = "Data Source(s)";
  const modalHeader = {
    dataSource: "Data Source",
    updated: "Updated",
  };
  const { t } = useTranslation();

  const filterTable1PopUpData = (data) => {
    const response = {};
    data?.forEach((record, index) => {
      response[index] = {
        dataSource: t(DataSourceEnums[record["name"]]) ?? "",
        updated: formatInTimeZone(
          new Date(record["lastSynced"].toLocaleString()),
          "America/Los_Angeles",
          "MM/dd/yyyy HH:mm a zzz"
        ),
      };
    });
    return response;
  };
  const modalBoxBody = filterTable1PopUpData(metaData.sources);
  dialogBox = (
    <CustomDialog
      closeButtonText="CLOSE"
      title={modalTitle}
      description={!!props.modalDescription && props.modalDescription}
      open={open}
      handleClose={handleCloseHandler}
      showGeneralHelpSection={true}
      extraClass="applicantDialogBox"
    >
      <CustomTable
        sticky
        label={modalTitle}
        header={modalHeader}
        body={modalBoxBody}
      />
    </CustomDialog>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      {metaData &&
        !(location.match("output") || location.match("submissions")) && (
          <div>
            <MuiInfoIcon
              fontSize="small"
              onClick={handleClickOpen}
              style={{
                cursor: "pointer",
              }}
            />
            {dialogBox}
          </div>
        )}
    </>
  );
};
