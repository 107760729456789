import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Button as MuiButton,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
} from "@material-ui/core";
import { CloudDownload as MuiCloudDownloadIcon } from "@material-ui/icons";
/** Custom Components */
import CustomTabs from "../UI/Tabs/Tabs";
import CustomTable from "../UI/Table/Table";
import CustomDialog from "../UI/Dialog/Dialog";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
/** Services */
import {
  getOutputSubmissionsList,
  getOutputTable6BPopUp,
  getExportData,
  getOutputTable6B,
} from "../../services/outputService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { ASAlert, useAlertContext } from "@stanford-tds/as-components";
/* Styles */
import { useStyles } from "./OutputTable6A.styles";
import { useCommonStyles } from "../shared/common.styles";

const MapEnumDegreeArr = {
  PhDs: "PhDs",
  MDs: "MDs",
  DUAL_DEGREE_HOLDERS: "Dual-Degree Holders",
  OTHER_DEGREE_HOLDERS: "Other Degree Holders",
  TOTAL: "Total",
  MEAN: "Mean",
};

const degreeEnumMap = (degree) => {
  let mappedDegreeValue = MapEnumDegreeArr[degree];

  return mappedDegreeValue;
};
/**
 * Filter Table1 Pop up Data.
 *
 * @param data
 *   Response pop-up data received from API.
 */
const filterTable1PopUpData = (data) => {
  const response = {};
  // Check if data returned from API Call.
  if (data && data.length) {
    data.map((record) => {
      return (response[record["traineeDto"]["rowId"]] = {
        "#": record["traineeDto"]["rowId"] ?? "",
        name: record["traineeDto"]["fullName"] ?? "",
        sunetId: record["traineeDto"]["sunetId"] ?? "",
      });
    });
  }
  return response;
};

export const Table6B = (props) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [outputTableSixBEnumFilter, setOutputTableSixBEnumFilter] =
    useState(null);
  const [degreeCode, setDegreeCode] = useState(null);
  const [getYear, setYear] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [popupData, setPopupData] = useState([]);
  const [table6BData, setTable6BData] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const [loading, setLoading] = useState(true);
  const [dialogLoading, setDialogLoading] = useState(false);
  const selectedSubmission = getSelectedSubmission();

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
  };

  /**
   * Open Modal Box or Perform any other linking functionality.
   */
  const table1ModalOpenEventHandler = (
    rowId,
    outputTableSixBEnumFilter,
    year,
    degreeCode
  ) => {
    const [, { degreeTypeForSixBCount }] = degreeCode;
    setOpen(true);
    setOutputTableSixBEnumFilter(outputTableSixBEnumFilter);
    setDegreeCode(degreeTypeForSixBCount);
    setYear(year);
  };

  /**
   * Dialog Close Button Handler.
   */
  const handleCloseHandler = () => {
    setOpen(false);
  };

  /**
   * Tab Change Hanlder.
   *
   * @param event
   *   Selected Tab Event.
   * @param newValue
   *   Selected Tab Value.
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  /**
   * Filter Table Data.
   *
   * @param data
   *   Response data received from API.
   * @param tabType
   *   table-six-a-characteristics | table-six-a-count
   */
  const filterTableData = (data, tabType) => {
    let result = {};
    if (tabType === "table-six-b-counts") {
      result = filterCountTableData(data);
    } else if (tabType === "table-six-b-characteristics") {
      result = filterCharacteristicsTableData(data);
    }

    return result;
  };

  const filterCountTableData = (data) => {
    const result = {};
    var lastYear = new Date().getFullYear() - 1;

    if (data.acadYear) {
      lastYear = data.acadYear;
    }
    // Loop through last 5 years records.
    for (let year = lastYear; year > lastYear - 5; year--) {
      const yearObject = {};
      if (data && data.content && data.content[year]) {
        data.content[year].map((yearData, index) => {
          return (yearObject[index] = {
            degreeTypeForSixBCount: degreeEnumMap(yearData.degreeType) ?? "",
            totalApplicantPool: yearData.totalApplicantPool ?? 0,
            applicantsEligibleForSupport:
              yearData.applicantsEligibleForSupport ?? 0,
            newEntrantsToProgram: yearData.newEntrantsToProgram ?? 0,
            newEntrantsEligibleForSupport:
              yearData.newEntrantsEligibleForSupport ?? 0,
            newEntrantsAppointed: yearData.newEntrantsAppointed ?? null,
          });
        });
      } else {
        // Assign Empty opbject if no data found for selected year.
        yearObject[0] = {
          degreeTypeForSixBCount: "TOTAL",
          totalApplicantPool: 0,
          applicantsEligibleForSupport: 0,
          newEntrantsToProgram: 0,
          newEntrantsEligibleForSupport: 0,
          newEntrantsAppointed: null,
        };
      }
      // preventing automatic sort of Object numeric property by adding " ".
      result[" " + year] = yearObject;
    }

    // Add the summary data
    const summaryObject = {};
    if (data.content && data.content["summary"]) {
      data.content["summary"].map((summaryData, index) => {
        return (summaryObject[index] = {
          degreeTypeForSixBCount: degreeEnumMap(summaryData.degreeType) ?? "",
          totalApplicantPool: summaryData.totalApplicantPool ?? 0,
          applicantsEligibleForSupport:
            summaryData.applicantsEligibleForSupport ?? 0,
          newEntrantsToProgram: summaryData.newEntrantsToProgram ?? 0,
          newEntrantsEligibleForSupport:
            summaryData.newEntrantsEligibleForSupport ?? 0,
          newEntrantsAppointed: summaryData.newEntrantsAppointed ?? null,
        });
      });
    } else {
      // Assign Empty object if no data found for selected year.
      summaryObject[0] = {
        degreeTypeForSixBCount: "MEAN",
        totalApplicantPool: 0,
        applicantsEligibleForSupport: 0,
        newEntrantsToProgram: 0,
        newEntrantsEligibleForSupport: 0,
        newEntrantsAppointed: null,
      };
    }

    result["summary"] = summaryObject;

    return result;
  };

  /**
   * Filter Characteristics Table Data.
   *
   * @param data
   *   Response data received from API.
   */
  const filterCharacteristicsTableData = (data) => {
    const result = {};

    var lastYear = new Date().getFullYear() - 1;

    if (data.acadYear) {
      lastYear = data.acadYear;
    }
    // Loop through last 5 years records.
    for (let year = lastYear; year > lastYear - 5; year--) {
      const yearObject = {};
      if (data.content && data.content[year]) {
        data.content[year].map((yearData, index) => {
          const characteristicsObject = {
            department: {
              code: yearData.tableSixBCharacteristicType ?? "",
              name: yearData.tableSixBCharacteristicType
                ? t(
                    `${tableColumnLabel}.characteristics.${yearData.tableSixBCharacteristicType}`
                  )
                : "",
            },
            totalApplicantPool: null,
            applicantsEligibleForSupport: null,
            newEntrantsToProgram: null,
            newEntrantsEligibleForSupport: null,
            newEntrantsAppointed: null,
          };

          switch (yearData.tableSixBCharacteristicType) {
            case "MEAN_NUMBER_OF_PUBLICATIONS":
              characteristicsObject["totalApplicantPool"] = `${
                yearData.meanTotalApplicantPool ?? 0
              } (${yearData.minTotalApplicantPool ?? 0}-${
                yearData.maxTotalApplicantPool ?? 0
              })`;

              characteristicsObject["applicantsEligibleForSupport"] = `${
                yearData.meanApplicantsEligibleForSupport ?? 0
              } (${yearData.minApplicantsEligibleForSupport ?? 0}-${
                yearData.maxApplicantsEligibleForSupport ?? 0
              })`;

              characteristicsObject["newEntrantsToProgram"] = `${
                yearData.meanNewEntrantsToProgram ?? 0
              } (${yearData.minNewEntrantsToProgram ?? 0}-${
                yearData.maxNewEntrantsToProgram ?? 0
              })`;

              characteristicsObject["newEntrantsEligibleForSupport"] = `${
                yearData.meanNewEntrantsEligibleForSupport ?? 0
              } (${yearData.minNewEntrantsEligibleForSupport ?? 0}-${
                yearData.maxNewEntrantsEligibleForSupport ?? 0
              })`;

              characteristicsObject["newEntrantsAppointed"] = ``;
              break;
            case "MEAN_NUMBER_OF_FIRST_AUTHOR_PUBLICATIONS":
              characteristicsObject["totalApplicantPool"] = `${
                yearData.meanTotalApplicantPool ?? 0
              } (${yearData.minTotalApplicantPool ?? 0}-${
                yearData.maxTotalApplicantPool ?? 0
              })`;

              characteristicsObject["applicantsEligibleForSupport"] = `${
                yearData.meanApplicantsEligibleForSupport ?? 0
              } (${yearData.minApplicantsEligibleForSupport ?? 0}-${
                yearData.maxApplicantsEligibleForSupport ?? 0
              })`;

              characteristicsObject["newEntrantsToProgram"] = `${
                yearData.meanNewEntrantsToProgram ?? 0
              } (${yearData.minNewEntrantsToProgram ?? 0}-${
                yearData.maxNewEntrantsToProgram ?? 0
              })`;

              characteristicsObject["newEntrantsEligibleForSupport"] = `${
                yearData.meanNewEntrantsEligibleForSupport ?? 0
              } (${yearData.minNewEntrantsEligibleForSupport ?? 0}-${
                yearData.maxNewEntrantsEligibleForSupport ?? 0
              })`;

              characteristicsObject["newEntrantsAppointed"] = ``;
              break;
            case "PRIOR_INSTITUTIONS":
              characteristicsObject["newEntrantsToProgram"] = (
                <MuiTypography component="span">
                  {yearData.priorInstitutionsNewEntrantsToProgram
                    ? yearData.priorInstitutionsNewEntrantsToProgram
                        .split("|")
                        .join("\n")
                    : ""}
                </MuiTypography>
              );

              characteristicsObject["newEntrantsEligibleForSupport"] = (
                <MuiTypography component="span">
                  {yearData.priorInstitutionsNewEntrantsEligibleForSupport
                    ? yearData.priorInstitutionsNewEntrantsEligibleForSupport
                        .split("|")
                        .join("\n")
                    : ""}
                </MuiTypography>
              );

              characteristicsObject["newEntrantsAppointed"] = ``;
              break;
            case "PERCENT_WITH_A_DISABILITY":
              characteristicsObject["newEntrantsToProgram"] = `${
                yearData.percentWithDisabilityNewEntrantsToProgram ?? 0
              }%`;

              characteristicsObject["newEntrantsEligibleForSupport"] = `${
                yearData.percentWithDisabilityNewEntrantsEligibleForSupport ?? 0
              }%`;

              characteristicsObject["newEntrantsAppointed"] = ``;
              break;
            case "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS":
              characteristicsObject["newEntrantsToProgram"] = `${
                yearData.percentWithUnderrepresentedNewEntrantsToProgram ?? 0
              }%`;

              characteristicsObject["newEntrantsEligibleForSupport"] = `${
                yearData.percentWithUnderrepresentedNewEntrantsEligibleForSupport ??
                0
              }%`;
              characteristicsObject["newEntrantsAppointed"] = ``;
              break;
            default:
              break;
          }

          return (yearObject[index] = characteristicsObject);
        });

        // preventing automatic sort of Object numeric property by adding " ".
        result[" " + year] = yearObject;
      }
    }

    // Add the summary data
    const summaryObject = {};
    if (data.content && data.content["summary"]) {
      data.content["summary"].map((summaryData, index) => {
        const characteristicsObject = {
          department: {
            code: summaryData.tableSixBCharacteristicType ?? "",
            name: summaryData.tableSixBCharacteristicType
              ? t(
                  `${tableColumnLabel}.characteristics.summary.${summaryData.tableSixBCharacteristicType}`
                )
              : "",
          },
          totalApplicantPool: null,
          applicantsEligibleForSupport: null,
          newEntrantsToProgram: null,
          newEntrantsEligibleForSupport: null,
          newEntrantsAppointed: null,
        };

        switch (summaryData.tableSixBCharacteristicType) {
          case "MEAN_NUMBER_OF_PUBLICATIONS":
            characteristicsObject["totalApplicantPool"] = `${
              summaryData.meanTotalApplicantPool ?? 0
            }`;
            characteristicsObject["applicantsEligibleForSupport"] = `${
              summaryData.meanApplicantsEligibleForSupport ?? 0
            }`;
            characteristicsObject["newEntrantsToProgram"] = `${
              summaryData.meanNewEntrantsToProgram ?? 0
            }`;
            characteristicsObject["newEntrantsEligibleForSupport"] = `${
              summaryData.meanNewEntrantsEligibleForSupport ?? 0
            }`;
            break;
          case "MEAN_NUMBER_OF_FIRST_AUTHOR_PUBLICATIONS":
            characteristicsObject["totalApplicantPool"] = `${
              summaryData.meanTotalApplicantPool ?? 0
            }`;
            characteristicsObject["applicantsEligibleForSupport"] = `${
              summaryData.meanApplicantsEligibleForSupport ?? 0
            }`;
            characteristicsObject["newEntrantsToProgram"] = `${
              summaryData.meanNewEntrantsToProgram ?? 0
            }`;
            characteristicsObject["newEntrantsEligibleForSupport"] = `${
              summaryData.meanNewEntrantsEligibleForSupport ?? 0
            }`;
            break;
          case "PERCENT_FROM_UNDERREPRESENTED_RACIAL_AND_ETHNIC_GROUPS":
            characteristicsObject["newEntrantsToProgram"] = `${
              summaryData.percentWithUnderrepresentedNewEntrantsToProgram ?? 0
            }%`;
            characteristicsObject["newEntrantsEligibleForSupport"] = `${
              summaryData.percentWithUnderrepresentedNewEntrantsEligibleForSupport ??
              0
            }%`;
            break;
          default:
            break;
        }
        return (summaryObject[index] = characteristicsObject);
      });

      result["summary"] = summaryObject;
    }

    return result;
  };

  // PART I. COUNTS Header
  let part1Header = {};
  const tableColumnLabel = "output.mainView.list.tableColumns.table6B";
  const tableHeader = (index, year, tabType) => {
    let firstHeader;
    if (index === "1") {
      firstHeader = t(`${tableColumnLabel}.counts.mostRecentlyCompletedYear`, {
        recentCompletionYear: `${year}`,
      });
    } else if (index === "summary") {
      if (tabType === "table-six-b-counts") {
        firstHeader = t(`${tableColumnLabel}.counts.summaryTable`);
      } else if (tabType === "table-six-b-characteristics") {
        firstHeader = t(`${tableColumnLabel}.characteristics.summaryTable`);
      }
    } else {
      firstHeader = t(`${tableColumnLabel}.counts.previousYear`, {
        previousCompletionYear: `${year}`,
      });
    }
    part1Header = {
      mostRecentlyCompletedYear: firstHeader,
      totalApplicantPool: t(`${tableColumnLabel}.counts.totalApplicantPool`),
      applicantsEligibleForSupport: t(
        `${tableColumnLabel}.counts.applicantsEligibleForSupport`
      ),
      newEntrantsToTheProgram: t(
        `${tableColumnLabel}.counts.newEntrantsToProgram`
      ),
      newEntrantsEligibleForSupport: t(
        `${tableColumnLabel}.counts.newEntrantsEligibleForSupport`
      ),
      newEntrantsAppointed: t(
        `${tableColumnLabel}.counts.newEntrantsAppointed`
      ),
    };
  };

  // Build Tab Data
  const tabType = tabValue
    ? "table-six-b-characteristics"
    : "table-six-b-counts";

  useEffect(() => {
    getOutputTable6B(
      selectedSubmission,
      tabType,
      setTable6BData,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [selectedSubmission, tabType]);

  const tabBody = filterTableData(table6BData, tabType);
  let combineTableData = null;
  if (loading) {
    combineTableData = (
      <>
        <CustomTable
          key={0}
          header={{ loading: t("globals.list.messages.fetchingInfo") }}
          body={{}}
          tableClass="table1"
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{ department: "name" }}
        />
        {loading && (
          <MuiBox sx={{ width: "100%" }}>
            <MuiLinearProgress />
          </MuiBox>
        )}
      </>
    );
  }
  if (!loading && tabBody && table6BData) {
    combineTableData = Object.entries(tabBody).map((response, index) => {
      const key = response[0].trim();
      let label = null;
      if (!isNaN(key)) {
        const dataYear = Number(response[0].trim());
        const dataPrevYear = Number(response[0].trim()) - 1;

        label = dataYear;
        if (index === 0) {
          tableHeader("1", dataPrevYear + "-" + dataYear, tabType);
        } else {
          tableHeader("2", dataPrevYear + "-" + dataYear, tabType);
        }
      } else {
        label = "summary";
        tableHeader("summary", "", tabType);
      }

      return (
        <CustomTable
          key={index}
          sticky
          label={label}
          header={part1Header}
          body={response[1]}
          tableClass="table1"
          isElementLinkable={table1ModalOpenEventHandler}
          objectFilter={{ department: "name" }}
        />
      );
    });
  }
  const data = {
    "PART I. COUNTS": combineTableData,
    "PART II. CHARACTERISTICS": combineTableData,
  };

  /**
   * API call for popup
   */
  useEffect(() => {
    getOutputTable6BPopUp(
      getSelectedSubmission(),
      getYear,
      outputTableSixBEnumFilter,
      degreeCode,
      setPopupData,
      setAlert,
      clearAlert,
      setDialogLoading
    );
    // eslint-disable-next-line
  }, [getSelectedSubmission, getYear, degreeCode, outputTableSixBEnumFilter]);

  // Build Modal Box data
  let dialogBox = null;
  const modalBoxBody = filterTable1PopUpData(popupData);
  if (open) {
    const modalHeader = {
      "#": "#",
      name: t("output.modalDialog.list.tableColumns.name"),
      sunetId: t("output.modalDialog.list.tableColumns.sunetId"),
    };
    const modalTitle =
      degreeCode +
      " - " +
      t(`${tableColumnLabel}.counts.` + outputTableSixBEnumFilter);
    dialogBox = (
      <CustomDialog
        closeButtonText="CLOSE"
        title={modalTitle}
        open={open}
        handleClose={handleCloseHandler}
      >
        <CustomTable
          sticky
          label={modalTitle}
          header={dialogLoading ? {} : modalHeader}
          body={modalBoxBody}
          tableClass="modalBox"
          loading={dialogLoading}
        />
      </CustomDialog>
    );
  }
  /**
   * handle export function
   */
  const handleExport = () => {
    const tableName = `Table_6B_${
      tabType === "table-six-b-counts" ? "Count" : "Characteristics"
    }`;
    getExportData(
      tableName,
      tabType,
      getSelectedSubmission(),
      setAlert,
      clearAlert
    );
  };

  return (
    <MuiGrid container direction="column">
      {alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <MuiGrid item classes={{ item: commonClasses.outputAutoCompleteWrapper }}>
        {/* Submission Select Box */}
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        item
        container
        justify="flex-end"
        className={classes.actionButton}
      >
        {/* Export Button */}
        <MuiButton variant="outlined" onClick={handleExport}>
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>

      <MuiGrid item className={classes.container}>
        {/* Table 6 Output */}
        <CustomTabs
          data={data}
          handleChange={handleTabChange}
          value={tabValue}
        />
        {/* Dialog Box Output */}
        {dialogBox}
      </MuiGrid>
    </MuiGrid>
  );
};
