import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  pageContentStyle: {
    "& ": {
      color: theme.palette.text.primary,
      marginBottom: "24px",
      display: "flex",
      height: "100%",
    },
  },
}));
