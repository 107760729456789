import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Paper as MuiPaper,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Grid as MuiGrid,
  Button as MuiButton,
  Box as MuiBox,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
} from "@material-ui/core";
import { CloudDownload as MuiCloudDownloadIcon } from "@material-ui/icons";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
/** Services */
import {
  getOutputTable3Data,
  getExportData,
  getOutputSubmissionsList,
} from "../../services/outputService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./OutputTable3.styles";
import { useCommonStyles } from "../shared/common.styles";

export const Table3 = (props) => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(true);
  const [submissionList, setSubmissionList] = useState([]);
  const [data, setData] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const selectedSubmission = getSelectedSubmission();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const outputTable3Columns = "output.mainView.list.tableColumns";
  const { alert, setAlert, clearAlert } = useAlertContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOutputSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOutputTable3Data(
      selectedSubmission,
      setData,
      setAlert,
      clearAlert,
      setLoading,
      getSelectedSubmissionName
    );
    // eslint-disable-next-line
  }, [setData, selectedSubmission]);

  const handleChangeSubmission = () => {
    setReload(!reload);
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
  };

  /**
   * handle export function
   */
  const handleExport = () => {
    getExportData(
      `Table_3`,
      `table-three`,
      getSelectedSubmission(),
      setAlert,
      clearAlert
    );
  };

  return (
    <MuiGrid container direction="column">
      {alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <MuiGrid item classes={{ item: commonClasses.outputAutoCompleteWrapper }}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        item
        container
        justify="flex-end"
        className={classes.actionButton}
      >
        <MuiButton
          variant="outlined"
          onClick={handleExport}
          disabled={!data.length}
        >
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}{" "}
        </MuiButton>
      </MuiGrid>
      <MuiGrid item container>
        <MuiTableContainer
          component={MuiPaper}
          classes={{ root: classes.fixHeader }}
        >
          <MuiTable aria-label="customized table" stickyHeader>
            <MuiTableHead className={classes.blueHeader}>
              <MuiTableRow>
                <MuiTableCell key="grantTitle" width="30%">
                  {t(`${outputTable3Columns}.grantTitle`)}
                </MuiTableCell>
                <MuiTableCell key="awardNumber">
                  {t(`${outputTable3Columns}.awardNumber`)}
                </MuiTableCell>
                <MuiTableCell key="projectPeriod">
                  {t(`${outputTable3Columns}.projectPeriod`)}
                </MuiTableCell>
                <MuiTableCell key="pd/pi">
                  {t(`${outputTable3Columns}.pd/pi`)}
                </MuiTableCell>
                <MuiTableCell key="preDoctoral">
                  {t(`${outputTable3Columns}.preDoctoral`)}
                </MuiTableCell>
                <MuiTableCell key="postDoctoral">
                  {t(`${outputTable3Columns}.postDoctoral`)}
                </MuiTableCell>

                <MuiTableCell key="shortTerm">
                  {t(`${outputTable3Columns}.shortTerm`)}
                </MuiTableCell>
                <MuiTableCell key="participatingFaculty">
                  {t(`${outputTable3Columns}.participatingFaculty`)}
                </MuiTableCell>
                <MuiTableCell key="overLappingFaculty">
                  {t(`${outputTable3Columns}.overLappingFaculty`)}
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {!loading &&
                data &&
                data.length &&
                data.map((row, index) => (
                  <MuiTableRow>
                    <MuiTableCell>{row.tableThreeDto.title ?? ""}</MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.grantNumber ?? ""}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.projectPeriod ?? ""}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.principalInvestigators
                        ? row.tableThreeDto.principalInvestigators
                            .map((investigatorName) => investigatorName)
                            .join("\n")
                        : ""}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.predocPositionsCount ?? 0}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.postdocPositionsCount ?? 0}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.numberOfShortTermPositions ?? 0}
                    </MuiTableCell>
                    <MuiTableCell>
                      {row.tableThreeDto.numberOfParticipatingFaculty
                        ? row.tableThreeDto.numberOfParticipatingFaculty
                        : ""}
                    </MuiTableCell>
                    <MuiTableCell className={classes.newLine}>
                      {row.tableThreeDto.namesOfOverlappingFaculty
                        ? row.tableThreeDto.namesOfOverlappingFaculty
                            .map((facultyName) => facultyName)
                            .join("\n")
                        : ""}
                    </MuiTableCell>
                  </MuiTableRow>
                ))}
              {!loading && (!data || data?.length === 0) && (
                <MuiTableRow className="noData">
                  <MuiTableCell
                    colSpan="12"
                    classes={{ body: classes.noDataTableCell }}
                  >
                    {t("output.mainView.list.noData")}
                  </MuiTableCell>
                </MuiTableRow>
              )}
              {loading && (
                <MuiTableRow>
                  <MuiTableCell colSpan="9">
                    <MuiTypography
                      variant="h6"
                      color="textSecondary"
                      align="center"
                      paragraph={false}
                    >
                      {loading
                        ? t("globals.list.messages.fetchingInfo")
                        : t("applicants.mainView.list.noData")}
                    </MuiTypography>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
          {loading && (
            <MuiBox sx={{ width: "100%" }}>
              <MuiLinearProgress />
            </MuiBox>
          )}
        </MuiTableContainer>
      </MuiGrid>
    </MuiGrid>
  );
};
