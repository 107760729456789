import { useEffect, useState, createContext, useContext } from "react";
import axios from "axios";
import { useMultipleAPI } from "./multipleApiInterface";

const AutoCompleteContext = createContext();

export const AutoCompleteProvider = ({ children }) => {
  let submission = null;
  const matches = document.cookie
    .split(";")
    .filter((s) => s.includes("submission"))[0];
  if (matches) {
    submission = matches.substring(matches.indexOf("=") + 1, matches.length);
  }
  const [refresh, setReloadData] = useState(false);
  const [filter, setFilter] = useState([]);
  //API Changes
  let filterKeys = [
    "faculty-name",
    "degree",
    "rank",
    "program",
    "training-role",
  ];
  let urlArray = filterKeys.map((filterName) => {
    if (filterName === "program") {
      filterName = "department";
    }
    return `/submissions/${submission}/faculty/${filterName}s`;
  });
  const filterData = useMultipleAPI(
    urlArray,
    ["get", "get", "get", "get", "get"],
    {},
    filterKeys,
    refresh
  );

  const refreshData = () => {
    setReloadData(!refresh);
  };

  useEffect(() => {
    if (filterData.data) {
      setFilter(filterData.data);
    }
  }, [filterData.data]);

  return (
    <AutoCompleteContext.Provider value={{ filter, refreshData }}>
      {children}
    </AutoCompleteContext.Provider>
  );
};

export const useAutoComplete = () => {
  return useContext(AutoCompleteContext);
};

/**
 * Generic method to query any of the Autocomplete APIs
 * @param {object} requestParams
 * @param {function} onBeforeSend A pre-request callback function that can be used to initialize state before the request.
 * @param {function} onError A function to be called if the request fails.
 * @param {function} onSuccess A function to be called if the request succeeds.
 * @param {object} cancelSource axios cancel token
 */
export const getAutocompleteForEditTrainee = async (
  { parameter, size, targetValue, includeOrgsWithAcademicPlanCodeOnly },
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  try {
    onBeforeSend();

    const response = await axios(
      `${parameter}/autocomplete?size=${size}&value=${targetValue}&includeOrgsWithAcademicPlanCodeOnly=${includeOrgsWithAcademicPlanCodeOnly}`,
      { cancelToken: cancelSource?.token }
    );

    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};
