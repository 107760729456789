import { makeStyles } from "@material-ui/core/styles";

export const useCommonStyles = makeStyles({
  autocompleteOption: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dummyAutocompleteOption: {
    '.MuiAutocomplete-option[aria-disabled="true"] &': {
      color: "#000", // grey text is not legible with the opacity from MUI Autocomplete
    },
  },
});
